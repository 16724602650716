import React, {FC} from 'react';
import {RangePicker, RangePickerProps} from "../../../../../../components/v2/calendar/rangePicker/rangePicker";

interface Props {
    value?: RangePickerProps['value'];
    onChange?: RangePickerProps['onChange'];
    disabled?: boolean;
}

export const Picker: FC<Props> = (props) => {

    return (
        <RangePicker
            disabled={props.disabled}
            value={props.value}
            // startDateLabel={this.props.actualizeDateMode && isRentOrSubrentOperation(this.props.targetStateCode || this.props.typeCode) && <><Icon component={IconCrosshairsSolid} style={{color: '#34bfa3', fontSize: 21, position: 'relative', top: 2, marginRight: 5}}/> <span style={{fontWeight: 500, fontSize: 16}}>Сейчас</span></>}
            // endDateLabel={this.props.actualizeDateMode && isReturnOrSubrentReturnOperation(this.props.targetStateCode || this.props.typeCode) && <><Icon component={IconCrosshairsSolid} style={{color: '#34bfa3', fontSize: 21, position: 'relative', top: 2, marginRight: 5}}/> <span style={{fontWeight: 500, fontSize: 16}}>Сейчас</span></>}
            // allowClear={false}
            // className={(datesError ? 'rr-input-error' : '')}
            // value={rangePickerDefaultValue as any}
            showTime
            // disabled={disabled}
            onChange={props.onChange}
            style={{width: 420}}
            // shiftCount={this.props.shiftCount}
            // requiredTimeIndentProps={{
            //     minutes: this.props.requiredTimeIndentBetweenElementsInMinutes,
            //     hasOwnIndent: false
            // }}
            // recalculateShiftCountBlockProps={!this.props.autoRecalculateShiftsCountMode ? {
            //     shiftLengthInMin: this.props.defaultShiftLengthInMinutes,
            //     hasOwnShiftLength: false,
            //     shiftLengthHint: undefined,
            //     fromShiftCount: this.props.shiftCount
            // } : undefined}
        />
    );
};
