import React, {FC} from 'react';
import {LocalizationEnum} from '../../../../../../../../localization';
import {
    AttachmentDocumentTypeCodeEnum,
    AttachmentParentEntityTypeCodeEnum,
    ContractValidationTypeCodeEnum,
    DocumentTypeCodeEnum,
    FileTypeCodeEnum,
    ProjectInfoRead
} from '../../../../../../../../server';
import {Icon} from 'antd';
import moment from 'moment';
import {ExportContractPopover} from '../../../../../../../../components/exportPopovers/downloadDocument/instances/ExportContractPopover/ExportContractPopover';
import {DownloadDocumentButton} from '../../../../../../../../components/buttons/downloadDocumentButton/downloadDocumentButton';
import {DownloadDocumentUtils} from '../../../../../../../../core/utils/downloadDocumentUtils/downloadDocumentUtils';
import {showNotification} from '../../../../../../../../components/notification/showNotification';
import {ContractDocumentOnDownload} from '../../../../../../../../components/exportPopovers/downloadDocument/instances/ExportContractPopover/utils/data';
import {AttachmentsUploader} from "../../../../../../../../components/attachmentsBlock/attachmentsUploader";
import {IconDownloadSolid2} from "../../../../../../../../components/icons";
import {AddButton} from "../../../../../../../../components/buttons/addButton/addButton";
import {useAppDispatch, useAppSelector} from "../../../../../../../../store/hooks";
import {contractValidationTypeSelector} from "../../../../../../../../shared/reducers/businessAccountPreferences.reducer";
import {loadEntity} from "../../../../reducers/project.reducer";
import {useLocalize} from "../../../../../../../../core/hooks/useLocalize";
import {MAX_ATTACHMENTS_LIMIT_FOR_ENTITY} from "../../../../../../../../config/constants";
import {DataDisplayBlock, DataDisplayItemType, DataDisplayState} from "../../../../../../../../components/dataDisplay";
import {getCurrentServerTime} from "../../../../../../../../shared/reducers/system.reducer";

interface ProjectPageDescriptionContractBlockProps {
    data: ProjectInfoRead;
}

export const ProjectPageDescriptionContractBlock: FC<ProjectPageDescriptionContractBlockProps> = ({data}) => {

    const L = useLocalize();
    const dispatch = useAppDispatch();
    const contractValidationType = useAppSelector(contractValidationTypeSelector);

    const generateContractCb: ContractDocumentOnDownload = async ({fileType, templateId, isCommonTemplate, legalDetailId}) => {
        const projectName = data.shortName || data.fullName;
        const fileName = `Договор${projectName ? ' ' + projectName : ''}`;
        await DownloadDocumentUtils.contractGenerateAndDownload(
            {
                businessAccountId: data.businessAccountId,
                projectId: data.id,
                documentTemplateId: templateId || 0,
                common: Boolean(isCommonTemplate),
                legalDetailId,
            },
            fileName,
            fileType as FileTypeCodeEnum.WORD | FileTypeCodeEnum.PDFTYPE
        ).catch((e) => {
            showNotification('error', 'Ошибка генерации файла');
        });
    };

    const contractCount = data.attachments?.records.filter((attachment) => attachment.documentType === AttachmentDocumentTypeCodeEnum.CONTRACT).length || 0;
    const contractFileRequired = contractValidationType && [ContractValidationTypeCodeEnum.ONLYFILE, ContractValidationTypeCodeEnum.ALLFIELDS].includes(contractValidationType);
    const contractAllFieldsRequired = contractValidationType === ContractValidationTypeCodeEnum.ALLFIELDS;

    const contractNumber = data.projectContract?.contractNumber;
    const contractDate = data.projectContract?.contractDate ? moment(data.projectContract.contractDate).format('DD MMMM YYYY') : undefined;
    const contractExpirationDate = data.projectContract?.contractExpirationDate ? moment(data.projectContract?.contractExpirationDate).format('DD MMMM YYYY') : undefined;
    let contractExpirationDateProblem:DataDisplayState | undefined;

    if(!data.projectContract?.contractExpirationDate){
        if(contractAllFieldsRequired) contractExpirationDateProblem = DataDisplayState.Problem;
    }else{
        const currentServerTime = moment(getCurrentServerTime());
        if(moment(data.projectContract.contractExpirationDate).valueOf() < currentServerTime.valueOf()){
            if(contractAllFieldsRequired) contractExpirationDateProblem = DataDisplayState.Problem;
            else contractExpirationDateProblem = DataDisplayState.Warning;
        }
    }

    return (
        <DataDisplayBlock
            style={{
                marginRight: -8,
                marginLeft: -8,
                paddingRight: 32,
                paddingLeft: 32,
                paddingTop: 32,
                paddingBottom: 32
            }}
            paddings={false}
            data={[
                [
                    {
                        label: 'Номер договора',
                        type: DataDisplayItemType.String,
                        value: contractNumber || (contractAllFieldsRequired ? 'Не указано' : undefined),
                        state: !contractNumber && contractAllFieldsRequired ? DataDisplayState.Problem : undefined
                    },
                    {
                        label: 'Дата договора',
                        type: DataDisplayItemType.String,
                        value: contractDate || (contractAllFieldsRequired ? 'Не указано' : undefined),
                        state: !contractDate && contractAllFieldsRequired ? DataDisplayState.Problem : undefined
                    },
                ],
                [
                    {
                        label: 'Окончание действия',
                        type: DataDisplayItemType.String,
                        value: contractExpirationDate || (contractAllFieldsRequired ? 'Не указано' : undefined),
                        state: contractExpirationDateProblem
                    },
                    {
                        label: 'Файлы договора',
                        type: DataDisplayItemType.String,
                        value: contractCount > 0 ? `${contractCount} ${L(LocalizationEnum.ASPECT__GLOBAL__FILE, {value: contractCount})}` : 'Нет',
                        state: contractCount === 0 && contractFileRequired ? DataDisplayState.Problem : undefined,
                        emptyValueText: 'Нет'
                    },
                ],
                [
                    {
                        type: DataDisplayItemType.Custom,
                        value: (
                            <ExportContractPopover onDownload={generateContractCb}>
                                <DownloadDocumentButton documentType={DocumentTypeCodeEnum.CONTRACT}/>
                            </ExportContractPopover>
                        ),
                    },
                    {
                        type: DataDisplayItemType.Custom,
                        value: (
                            <AttachmentsUploader
                                parentEntityTypeCode={AttachmentParentEntityTypeCodeEnum.PROJECT}
                                parentEntityId={data.id}
                                onComplete={(files) => {
                                    if (files.filter((file) => file.status === 'error').length === 0) {
                                        showNotification('success', 'Договор загружен');
                                    } else {
                                        showNotification('error', 'Договор не загружен');
                                    }
                                    dispatch(loadEntity(data.businessAccountId, data.id));
                                }}
                                attachmentsCount={data.attachments?.records.length || 0}
                                multiple={false}
                                accept=".pdf, .docx"
                                documentType={AttachmentDocumentTypeCodeEnum.CONTRACT}
                            >
                                <AddButton
                                    disabled={data.attachments?.records && data.attachments.records.length >= MAX_ATTACHMENTS_LIMIT_FOR_ENTITY}
                                    icon={<Icon component={IconDownloadSolid2}/>} style={{marginRight: 16}}
                                    label={'Загрузить файл договора'}/>
                            </AttachmentsUploader>
                        ),
                    },
                ],
            ]}
        />
    );
};
