import {
    addElementItemByNomenclature,
    addElementItemFromElements,
    addInstanceToElement,
    addKitsInfo,
    InstancePropertiesEntityRecordCustom,
    OperationElement,
    OperationFormState,
    removeInstanceFromElement,
    updateAnyTimetables
} from "../reducers/operationForm.reducer";
import {getStore, getStoreState} from "../../../../../index";
import {
    InstanceInfoRead,
    InstanceTrackingTypeCodeEnum,
    KitInfoRead,
    NomenclatureEntityTypeCodeEnum,
    NomenclatureRecord,
    TimetableTypeCodeEnum
} from "../../../../server/api";
import {getShiftCountFromDates, isDefined} from "../../../../shared/util/utils";
import {serverApi} from "../../../../server";
import {showNotification} from "../../../../components/notification/showNotification";
import moment from "moment";
import {
    canAddNewProductsToOperation,
    createNewOperationElement,
    createNewOperationElementFromKitMemember,
    findTimeTable,
    getKeepLeftoverByElementStateCode
} from "./utils";
import {RentElementsGridItem} from "../../../../types";
import sortBy from "lodash/sortBy";
import React from "react";
import {ScanErrorType, showScanErrorNotification} from "../../../../shared/reducers/scanner.reducer";
import {findTimeTableById, NomenclatureOnInterval, rentElementToNomenclatureBaseObj, loadNomenclaturesOnIntervalsIfNeed} from "./nomenclaturesUtils";

export type BaseNomenclatureData = {
    productId?: number;
    variantId?: number;
    kitId?: number;
    timetableVersion?: number;

    instanceTrackingTypeCode?: InstanceTrackingTypeCodeEnum;
    businessVersion?: number;
};

export class OperationFormUtils {

    static flatElementsList = (elements:RentElementsGridItem[]):RentElementsGridItem[] => {
        const res:RentElementsGridItem[] = [];
        elements.forEach(el=>{
            res.push(el);
            if(el.subRows){
                el.subRows.forEach((subRow)=>{
                    res.push(subRow);
                })
            }
        });
        return res;
    };

    static createNewKitElement = (productRecord:NomenclatureRecord, operationFormState:OperationFormState):OperationElement|undefined => {
        const defaultRentPeriodStartDate = operationFormState.rentPeriodStartDate;
        const defaultRentPeriodEndDate = operationFormState.rentPeriodEndDate;
        const startDateFromList = operationFormState.products.params.startDate;
        const endDateFromList = operationFormState.products.params.endDate;
        const defaultShiftCount = operationFormState.shiftCount;
        const defaultDiscount = operationFormState.discount;
        const projectTemplate = operationFormState.projectTemplate;
        //const timeTables = operationFormState.timeTables;
        //
        if (defaultRentPeriodStartDate && defaultRentPeriodEndDate) {
            let rentPeriodStartDateMoment = moment(startDateFromList);
            let rentPeriodEndDateMoment = moment(endDateFromList);
            let shiftCount = defaultShiftCount;
            let rentPeriodChanged = false;
            let shiftCountChanged = false;
            if (
                rentPeriodStartDateMoment.valueOf() !== defaultRentPeriodStartDate.getTime() &&
                rentPeriodEndDateMoment.valueOf() !== defaultRentPeriodEndDate.getTime()
            )
                rentPeriodChanged = true;
            if (defaultShiftCount !== shiftCount) shiftCountChanged = true;

            let rentPeriodStartDate = rentPeriodStartDateMoment.toDate();
            let rentPeriodEndDate = rentPeriodEndDateMoment.toDate();

            //let kitsList:KitInfoRead[] = [];//operationFormState.kits.entities;
            //let kit: KitInfoRead | undefined;
            //let kits: any[] = [];

            if (projectTemplate) shiftCount = defaultShiftCount;

            // kit = kitsList?.find((kit: KitInfoRead) => {
            //     return kit.id === productRecord.kitId;
            // });

            // if (kit) {
            //     kit.members.forEach((member) => {
            //         let instanceTracked = member.instanceTrackingTypeCode === InstanceTrackingTypeCodeEnum.VARIANTINSTANCETRACKED || member.instanceTrackingTypeCode === InstanceTrackingTypeCodeEnum.INSTANCETRACKED;
            //
            //         let timeTable = findTimeTable(timeTables, {
            //             productId: member.productId,
            //             variantId: member.variantId
            //         });
            //         let shiftLengthInMinutes = timeTable ? timeTable.shiftLengthInMinutes : undefined;
            //         let hasOwnShiftLength = timeTable ? timeTable.hasOwnShiftLength : undefined;
            //
            //         let requiredTimeIndentBetweenElementsInMinutes = timeTable ? timeTable.requiredTimeIndentBetweenElementsInMinutes : undefined;
            //         let productHasOwnRequiredTimeIndentBetweenElements = timeTable ? timeTable.productHasOwnRequiredTimeIndentBetweenElements : undefined;
            //
            //         kits.push(
            //             createNewOperationElementFromKitMemember({
            //                 //productRecord: productRecord,
            //                 kitRecord: member,
            //                 rentPeriodStartDate: rentPeriodStartDate,
            //                 rentPeriodEndDate: rentPeriodEndDate,
            //                 shiftCount: shiftCount,
            //                 discount: member.discount,
            //                 instanceCount: member.instanceCount,
            //                 anonymousInstanceCount: instanceTracked ? member.instanceCount : undefined,
            //                 rentPeriodChanged,
            //                 shiftCountChanged,
            //                 discountChanged: false,
            //                 shiftLengthInMinutes: shiftLengthInMinutes,
            //                 hasOwnShiftLength,
            //                 requiredTimeIndentBetweenElementsInMinutes,
            //                 productHasOwnRequiredTimeIndentBetweenElements
            //             })
            //         );
            //     });
            // }

            return createNewOperationElement({
                productRecord: productRecord as InstancePropertiesEntityRecordCustom,
                rentPeriodStartDate: rentPeriodStartDate,
                rentPeriodEndDate: rentPeriodEndDate,
                shiftCount: shiftCount,
                discount: defaultDiscount,
                instanceCount: 1,
                rentPeriodChanged,
                shiftCountChanged,
                discountChanged: false,
                anonymousInstanceCount: undefined,
            });
        }
    };

    static createNewProductElement = (productRecord:NomenclatureRecord, operationFormState:OperationFormState):OperationElement|undefined => {
        const defaultRentPeriodStartDate = operationFormState.rentPeriodStartDate;
        const defaultRentPeriodEndDate = operationFormState.rentPeriodEndDate;
        const startDateFromList = operationFormState.products.params.startDate;
        const endDateFromList = operationFormState.products.params.endDate;
        const defaultShiftCount = operationFormState.shiftCount;
        const defaultDiscount = operationFormState.discount;
        const projectTemplate = operationFormState.projectTemplate;
        //
        if (defaultRentPeriodStartDate && defaultRentPeriodEndDate) {
            let rentPeriodStartDateMoment = moment(startDateFromList);
            let rentPeriodEndDateMoment = moment(endDateFromList);
            let shiftCount = defaultShiftCount;
            let rentPeriodChanged = false;
            let shiftCountChanged = false;
            if (
                rentPeriodStartDateMoment.valueOf() !== defaultRentPeriodStartDate.getTime() &&
                rentPeriodEndDateMoment.valueOf() !== defaultRentPeriodEndDate.getTime()
            )
                rentPeriodChanged = true;
            if (defaultShiftCount !== shiftCount) shiftCountChanged = true;

            let rentPeriodStartDate = rentPeriodStartDateMoment.toDate();
            let rentPeriodEndDate = rentPeriodEndDateMoment.toDate();

            if (projectTemplate) {
                shiftCount = defaultShiftCount;
            }

            if(!isDefined(shiftCount)){
                //shiftCountChanged = true;
                let shiftLengthInMin = productRecord.shiftLengthInMinutes;
                shiftCount = getShiftCountFromDates(moment(rentPeriodStartDate), moment(rentPeriodEndDate), shiftLengthInMin, operationFormState.shiftCountRoundingType);
            }

            return createNewOperationElement({
                productRecord: productRecord as InstancePropertiesEntityRecordCustom,
                rentPeriodStartDate: rentPeriodStartDate,
                rentPeriodEndDate: rentPeriodEndDate,
                shiftCount: shiftCount,
                discount: defaultDiscount,
                instanceCount: 1,
                rentPeriodChanged,
                shiftCountChanged,
                discountChanged: false,
                anonymousInstanceCount: productRecord.instanceTrackingTypeCode == InstanceTrackingTypeCodeEnum.INSTANCETRACKED || productRecord.instanceTrackingTypeCode == InstanceTrackingTypeCodeEnum.VARIANTINSTANCETRACKED ? 0 : undefined
            });
        }
    };

    static loadNomenclatureById = async (data: BaseNomenclatureData) => {
        const state = getStore().getState();
        let rentPeriodStartDate = state.operationForm.rentPeriodStartDate || new Date();
        let rentPeriodEndDate = state.operationForm.rentPeriodEndDate || new Date();

        let filters:string[] = [];
        if(data.productId) filters.push(`productId;IN;${data.productId}`);
        if(data.variantId) filters.push(`variantId;IN;${data.variantId}`);
        if(data.kitId) filters.push(`kitId;IN;${data.kitId}`);

        const response = await serverApi.listNomenclatureOnInterval(
            state.system.businessAccountId,
            rentPeriodStartDate.getTime() - 365 * 1 * 24 * 60 * 60 * 1000,
            rentPeriodEndDate.getTime() + 365 * 2 * 24 * 60 * 60 * 1000,
            {
                possibleDelayedRentElementIds: undefined,
                listFilters: filters
            },
            1,
            undefined,
            undefined,
            undefined,
            undefined,
            [TimetableTypeCodeEnum.ORDERAVAILABLE, TimetableTypeCodeEnum.AVAILABLE, TimetableTypeCodeEnum.STOCK, TimetableTypeCodeEnum.ORDER]
        );
        if(response.data.records){
            return response.data.records[0];
        }else{
            return undefined;
        }
    };

    static loadNomenclaturesById = async (data: BaseNomenclatureData[], sortBy:string|undefined = undefined, sortOrder:'ASC'|'DESC'|undefined = undefined) => {
        const state = getStore().getState();
        let rentPeriodStartDate = state.operationForm.rentPeriodStartDate || new Date();
        let rentPeriodEndDate = state.operationForm.rentPeriodEndDate || new Date();

        let filters:string[] = [];
        let prodIds:number[] = [];
        let varIds:number[] = [];
        let kitIds:number[] = [];

        data.forEach(item=>{
            if(item.productId && !item.variantId){
                if(!prodIds.includes(item.productId)){
                    prodIds.push(item.productId);
                }
            }
            if(/*item.productId && */item.variantId){
                // if(!prodIds.includes(item.productId)){
                //     prodIds.push(item.productId);
                // }
                if(!varIds.includes(item.variantId)){
                    varIds.push(item.variantId);
                }
            }
            if(item.kitId){
                if(!kitIds.includes(item.kitId)){
                    kitIds.push(item.kitId);
                }
            }
        });

        if(prodIds.length) filters.push(`productId;IN;${prodIds.join(';')}`);

        if(varIds.length){
            if(prodIds.length) filters.push(`variantId;IN;-1;${varIds.join(';')}`);
            else filters.push(`variantId;IN;${varIds.join(';')}`);
        }
        if(kitIds.length) filters.push(`kitId;IN;${kitIds.join(';')}`);

        const response = await serverApi.listNomenclatureOnInterval(
            state.system.businessAccountId,
            rentPeriodStartDate.getTime() - 365 * 1 * 24 * 60 * 60 * 1000,
            rentPeriodEndDate.getTime() + 365 * 2 * 24 * 60 * 60 * 1000,
            {
                possibleDelayedRentElementIds: undefined,
                listFilters: filters
            },
            undefined,
            undefined,
            sortBy,
            sortOrder,
            undefined,
            [TimetableTypeCodeEnum.ORDERAVAILABLE, TimetableTypeCodeEnum.AVAILABLE, TimetableTypeCodeEnum.STOCK, TimetableTypeCodeEnum.ORDER]
        );
        if(response.data.records){
            return response.data.records;
        }else{
            return undefined;
        }
    };

    static findInstanceInElements = (instanceEntity: InstanceInfoRead, operationFormState:OperationFormState, withDates: boolean = true): OperationElement | undefined => {
        const elements = operationFormState.elements.entities;
        let rentPeriodStartDate = operationFormState.rentPeriodStartDate || new Date();
        let rentPeriodEndDate = operationFormState.rentPeriodEndDate || new Date();

        let element = elements.find((item: OperationElement) => {
            return (
                !isDefined(item.kitId) &&
                //item.id < 0 &&
                //!item.parentId &&
                item.productId == instanceEntity.productId &&
                item.variantId == instanceEntity.variantId &&
                item.isCancelled !== true &&
                //item.discount === instanceEntity.discount &&
                //item.shiftCount === instanceEntity.shiftCount &&
                ((!withDates) || (withDates && item.rentPeriodStartDate.toString() === rentPeriodStartDate.toString() && item.rentPeriodEndDate.toString() === rentPeriodEndDate.toString())) &&
                item.instanceIds && item.instanceIds.includes(instanceEntity.id)
            );
        });
        return element;
    };

    /**
     * Добавление из вкладки "Создание новых"
     * При нажатии на кнопку >
     * Из грида берем запись nomenclature
     * @param sss
     */
    /*static addItemFromProducts = async (nomenclature: NomenclatureRecord|BaseNomenclatureData|undefined) => {
        if(!nomenclature){
            showNotification('error', 'Ошибка: номенклатура не передана');
        } else {
            const {timetableVersion} = nomenclature;
            const records:any[] = [];

            if(!('stackMapList' in nomenclature)){
                let _nomenclature:NomenclatureRecord[]|undefined;
                try {
                    _nomenclature = await OperationFormUtils.getNomenclaturesById([nomenclature]);
                }catch(e){
                    console.error(e);
                }

                if(_nomenclature && _nomenclature[0]){
                    nomenclature = _nomenclature[0];
                }else {
                    showNotification('error', 'Ошибка');
                }
            }

            records.push(nomenclature);

            if(nomenclature && 'stackMapList' in nomenclature){
                let kitInfo:KitInfoRead|undefined;
                if(nomenclature.kitId){
                    kitInfo = await OperationFormUtils.getKitInfoById(nomenclature.kitId);

                     if(kitInfo){
                         let toLoad:BaseNomenclatureData[] = [];
                         // Есть данные для набора
                         kitInfo.members.forEach((kitMemember) => {
                             toLoad.push({
                                 productId: kitMemember.productId,
                                 variantId: kitMemember.variantId,
                             });
                         });

                         let p = toLoad.filter(n=>isDefined(n.productId) && !isDefined(n.variantId));
                         let v = toLoad.filter(n=>isDefined(n.variantId)).map(n=>({variantId: n.variantId}));

                         let p_nomenclatures = await OperationFormUtils.getNomenclaturesById(p, true);
                         let v_nomenclatures = await OperationFormUtils.getNomenclaturesById(v, true);
                         if(p_nomenclatures) records.push(p_nomenclatures);
                         if(v_nomenclatures) records.push(v_nomenclatures);
                     }
                 }
                 getStore().dispatch(addElementItemByNomenclature(nomenclature, records, kitInfo?.members));
            }
        }
    };*/

    static addItemFromProducts = async (nomenclature: NomenclatureRecord|BaseNomenclatureData|undefined) => {
        if(!nomenclature){
            throw new Error('Ошибка: номенклатура не передана');
        } else {
            const state = getStore().getState();
            const from = moment(state.operationForm.rentPeriodStartDate).valueOf();
            const until = moment(state.operationForm.rentPeriodEndDate).valueOf();

            const nomenclatures:NomenclatureOnInterval[] = [
                {...rentElementToNomenclatureBaseObj(nomenclature), from, until}
            ];

            let kitInfo:KitInfoRead|undefined;
            if(nomenclature.kitId){
                kitInfo = await OperationFormUtils.getKitInfoById(nomenclature.kitId);
                if(kitInfo){
                    kitInfo.members.forEach((kitMemember) => {
                        let type = kitMemember.variantId ? NomenclatureEntityTypeCodeEnum.VARIANT : NomenclatureEntityTypeCodeEnum.PRODUCT;
                        let id = kitMemember.variantId || kitMemember.productId;
                        nomenclatures.push({
                            type, id, from, until
                        });
                    });
                }
            }
            const data = await loadNomenclaturesOnIntervalsIfNeed(nomenclatures);
            getStore().dispatch(updateAnyTimetables(data.timetables, data.elementsDelayedReturnDates));
            const timeTable = findTimeTableById(nomenclatures[0].id, nomenclatures[0].type, getStore().getState().operationForm.timeTables);
            if(timeTable?.nomenclature){
                getStore().dispatch(addElementItemByNomenclature(timeTable.nomenclature as NomenclatureRecord, [], kitInfo?.members));
            }else{
                throw new Error('Ошибка: номенклатура не найдена');
            }
        }
    };

    static addItemFromOperationElementTab = async (element: OperationElement) => {
        let elements = getStoreState().operationForm.elements.entities;
        let _element = elements.find((el) => el.id === element.id);

        //let elements = [...state.elements.entities];
        let newElement: OperationElement | undefined = _element ? {..._element} : undefined;
        if (newElement) {
            newElement.instanceCount += 1;
            if (newElement.id < 0) newElement.instanceCountOriginal += 1;
            if (
                newElement.instanceTrackingTypeCode === InstanceTrackingTypeCodeEnum.VARIANTINSTANCETRACKED ||
                newElement.instanceTrackingTypeCode === InstanceTrackingTypeCodeEnum.INSTANCETRACKED
            ) {
                if (newElement.id < 0) {
                    // Новое
                    newElement.anonymousInstanceCount += 1;
                } else {
                    // Существующее
                    // В начале добавляются анонимные, потом экз-ры, потом анонимные
                    if (newElement.anonymousInstanceCount < (newElement.anonymousInstanceCountOriginal || 0)) {
                        newElement.anonymousInstanceCount += 1;
                    } else {
                        let notAddedInstanceId = newElement.instanceIdsOriginal?.find((id) => !newElement?.instanceIds?.includes(id));
                        if (notAddedInstanceId) {
                            newElement.instanceIds = newElement.instanceIds
                                ? [...newElement.instanceIds, notAddedInstanceId]
                                : [notAddedInstanceId];
                        } else {
                            newElement.anonymousInstanceCount += 1;
                        }
                    }
                }
                if (newElement.id < 0) newElement.anonymousInstanceCountOriginal = newElement.anonymousInstanceCount;
            }

            //
            const nomenclatures:NomenclatureOnInterval[] = [];
            if (newElement.instanceIds) {
                const from = newElement.rentPeriodStartDate.getTime();
                const until = newElement.rentPeriodEndDate.getTime();
                newElement.instanceIds.forEach((id) => {
                    nomenclatures.push({ id, type: NomenclatureEntityTypeCodeEnum.INSTANCE, from, until });
                });
                const data = await loadNomenclaturesOnIntervalsIfNeed(nomenclatures);
                getStore().dispatch(updateAnyTimetables(data.timetables, data.elementsDelayedReturnDates));
            }
            getStore().dispatch(addElementItemFromElements(newElement));
        }

        //if(_element) getStore().dispatch(addElementItemFromElements(_element));
    };

    /**
     * Вызывается только при клике на кнопку "<" в "Создание новых"
     * Передаем номенклатуру для которой удаляем
     * @nomenclature NomenclatureRecord
     */
    static removeItemFromProducts = async (nomenclature: NomenclatureRecord) => {
        let elements = getStoreState().operationForm.elements.entities;
        let filteredElements = elements.filter((el)=>{
            return (
                el.productId == nomenclature.productId &&
                el.variantId == nomenclature.variantId &&
                el.kitId == nomenclature.kitId &&
                el.instanceTrackingTypeCode == nomenclature.instanceTrackingTypeCode
            );
        });
        filteredElements = sortBy(filteredElements, 'instanceCount').reverse();
        filteredElements = sortBy(filteredElements, 'id');
        const element = filteredElements[0];
        if(element){
            try{
                getStore().dispatch(removeInstanceFromElement(element));
            }catch(err){
                showNotification('error', 'Ошибка');
            }
        }
    };

    /**
     * Вызывается только при клике на кнопку "<" в "Из проекта"
     * @param item RentElementsGridItem
     */
    static removeItemFromEquipment = async (item:RentElementsGridItem) => {
        let elements = getStoreState().operationForm.elements.entities;
        let element = elements.find((el) => el.id === item.id);
        if(element){
            try{
                getStore().dispatch(removeInstanceFromElement(element));
            }catch(err){
                console.log(err);
                showNotification('error', 'Ошибка');
            }
        }
    };

    /**
     * Вызывается только при клике на кнопку "<" в "Добавлено"
     * @param element
     */
    static removeItemFromElements = async (element: OperationElement) => {
        let elements = getStoreState().operationForm.elements.entities;
        let _element = elements.find((el) => el.id === element.id);
        if(_element){
            try{
                getStore().dispatch(removeInstanceFromElement(_element));
            }catch(err){
                showNotification('error', 'Ошибка');
            }
        }

    };

    // Получить данные для набора из памяти, и если нет, то загрузить
    static getKitInfoById = async (kitId:number):Promise<KitInfoRead|undefined> => {
        const state = getStore().getState();
        let kit = state.operationForm.kitsInfo.find(k=>k.id===kitId);
        if(kit){
            return kit;
        }else{
            let kit = await serverApi.getKitsByIdsBulk(state.system.businessAccountId, [kitId]);
            if(kit && kit.data && kit.data.length > 0){
                getStore().dispatch(addKitsInfo([kit.data[0]]));
                return kit.data[0];
            }
        }
    };

    // Получить данные для номенклатур из памяти, и если нет, то загрузить
    // static getNomenclaturesById = async (data:BaseNomenclatureData[], forceLoading:boolean = false, sortBy:string|undefined = undefined, sortOrder:'ASC'|'DESC'|undefined = undefined):Promise<NomenclatureRecord[]|undefined> => {
    //     const state = getStore().getState();
    //     const itemsToLoad:BaseNomenclatureData[] = [];
    //     const itemsToReturn:NomenclatureRecord[] = [];
    //
    //     if(data){
    //         data.forEach(item=>{
    //             if(!forceLoading){
    //                 let nomenclature;
    //                 if(isDefined(item.kitId)){
    //                     // Набор
    //                     nomenclature = state.operationForm.nomenclatures.find(n => n.kitId === item.kitId);
    //                 }else if(isDefined(item.productId) && !isDefined(item.variantId)){
    //                     // Продукт
    //                     nomenclature = state.operationForm.nomenclatures.find(n => n.productId === item.productId);
    //                 }else if(/*isDefined(item.productId) && */isDefined(item.variantId)){
    //                     // Вариант
    //                     nomenclature = state.operationForm.nomenclatures.find(n => /*n.productId === item.productId &&*/ n.variantId === item.variantId );
    //                 }
    //                 if(nomenclature){
    //                     if(nomenclature && (!isDefined(item.businessVersion) ||(isDefined(item.businessVersion) && nomenclature.businessVersion === item.businessVersion)) && ((!isDefined(item.timetableVersion)) || (isDefined(item.timetableVersion) && nomenclature.timetableVersion === item.timetableVersion))) itemsToReturn.push(nomenclature);
    //                     else itemsToLoad.push(item);
    //                 }else{
    //                     itemsToLoad.push(item);
    //                 }
    //             }else{
    //                 itemsToLoad.push(item);
    //             }
    //         });
    //     }
    //
    //     if(itemsToLoad.length){
    //         let nomenclatures = await OperationFormUtils.loadNomenclaturesById(itemsToLoad, sortBy, sortOrder);
    //         if(nomenclatures?.length){
    //             itemsToReturn.push(...nomenclatures);
    //             getStore().dispatch(addNomenclatures(nomenclatures));
    //         }
    //     }
    //     return itemsToReturn;
    // };

    static createOperationElementFromElement = (elementRecord:RentElementsGridItem, nomenclature:NomenclatureRecord):OperationElement => {

        // Свое задаем
        /*
        rentPeriodStartDate
        rentPeriodEndDate
        shiftCount
        discount
        instanceCount
        instanceCountOriginal
        effectivePrice

        discountChanged
        rentPeriodChanged
        shiftCountChanged

        instanceIds

        anonymousInstanceCount
        anonymousInstanceCountOriginal
        * */

        let el: OperationElement = {
            numberInActivityFrame: elementRecord.numberInActivityFrame,
            id: elementRecord.id,
            businessVersion: elementRecord.businessVersion,
            productId: elementRecord.productId,

            productShortName: elementRecord.productShortName,
            productMainImage: elementRecord.image,// ? {...this.state.productRecord.mainImage} : undefined,

            stateCode: elementRecord.stateCode,
            rentPeriodStartDate: new Date(elementRecord.rentTerms.rentPeriodStartDate),
            rentPeriodEndDate: new Date(elementRecord.rentTerms.rentPeriodEndDate),
            shiftCount: elementRecord.rentTerms.shiftCount,
            discount: elementRecord.rentTerms.discount,
            calendarShiftCount: 0,
            // Доступно 100/1000
            instanceCount: elementRecord.instanceCount,
            instanceCountOriginal: elementRecord.instanceCount,
            finalTotalPrice: 0,

            effectivePrice: elementRecord.effectivePricingScheme ? elementRecord.basePriceAtTheTimeOfCreation : elementRecord.effectivePrice,
            pricingSchemeId: elementRecord.effectivePricingScheme,

            //stockInstanceCountX: 0,//this.state.productRecord.stockInstanceCount,
            availableInstanceCount: 0,//this.state.productRecord.availableInstanceCount,

            discountChanged: true,
            rentPeriodChanged: true,
            shiftCountChanged: true,
            disabled: false,

            instanceIds: elementRecord.instanceIds?.map(id=>id),
            instanceIdsOriginal: elementRecord.instanceIds?.map(id=>id),
            variantId: elementRecord.variantId,
            variantName: elementRecord.variantName,
            instanceTrackingTypeCode: elementRecord.instanceTrackingTypeCode,

            rentPeriodStartDateOriginal: new Date(elementRecord.rentTerms.rentPeriodStartDate),
            rentPeriodEndDateOriginal: new Date(elementRecord.rentTerms.rentPeriodEndDate),
            mainImage: nomenclature ? nomenclature.mainImage : undefined,//this.state.productRecord.mainImage ? {...this.state.productRecord.mainImage} : undefined,
            kitId: elementRecord.kitId,
            parentId: undefined,
            warnings: [],
            problems: [],
            partOfKit: false,

            anonymousInstanceCount: elementRecord.instanceTrackingTypeCode == InstanceTrackingTypeCodeEnum.INSTANCETRACKED || elementRecord.instanceTrackingTypeCode == InstanceTrackingTypeCodeEnum.VARIANTINSTANCETRACKED ? elementRecord.anonymousInstanceCount || 0 : 0,
            anonymousInstanceCountOriginal: elementRecord.instanceTrackingTypeCode == InstanceTrackingTypeCodeEnum.INSTANCETRACKED || elementRecord.instanceTrackingTypeCode == InstanceTrackingTypeCodeEnum.VARIANTINSTANCETRACKED ? elementRecord.anonymousInstanceCount || 0 : 0,

            leftoverInstanceCount: 0,
            leftoverAnonymousInstanceCount: 0,
            keepLeftover: getKeepLeftoverByElementStateCode(elementRecord.stateCode),
            projectId: elementRecord.projectId,
            templateId: elementRecord.templateId,
            renterId: elementRecord.renterId,

//
            variantIdOriginal: elementRecord.variantId,

            shiftLengthInMinutes: nomenclature.shiftLengthInMinutes,
            hasOwnShiftLength: nomenclature.productHasOwnShiftLength,

            problemsAndWarnings: undefined, //???
            requiredTimeIndentBetweenElementsInMinutes: nomenclature.requiredTimeIndentBetweenElementsInMinutes,
            productHasOwnRequiredTimeIndentBetweenElements: nomenclature.productHasOwnRequiredTimeIndentBetweenElements,
            externalCode: elementRecord.externalCode,

            canBeCancelled: undefined,
            isCancelled: undefined,
            availableTransitionCodes: elementRecord.availableTransitionCodes
        };
        return el;
    };

    static addInstanceFromELementsTab = async (instance: InstanceInfoRead, onSuccess:()=>void) => {
        const state = getStore().getState();

        let sortFieldName = state.operationForm.elements.sortBy || '';
        let sortAsc = state.operationForm.elements.sortOrder === 'ASC';

        let newEntities: OperationElement[] = [];
        state.operationForm.elements.entities.forEach((entity0) => {
            let entity = {...entity0};
            if (!entity.kitId && !entity.parentId) newEntities.push(entity);
            else if (entity.kitId) {
                let subRows = state.operationForm.elements.entities.filter((entity1) => entity1.parentId === entity.id);
                subRows = subRows.map(item => {
                    return {...item};
                });
                entity['subRows'] = subRows;
                newEntities.push(entity);
            }
        });

        let sortedData: OperationElement[] = sortBy(newEntities, sortFieldName);

        if(sortFieldName === 'leftoverInstanceCount'){
            let newData = [
                ...sortedData.filter((el)=>el.leftoverInstanceCount === 0),
                ...sortedData.filter((el)=>!el.keepLeftover && el.leftoverInstanceCount !== 0),
                ...sortedData.filter((el)=>el.keepLeftover && el.leftoverInstanceCount !== 0),
            ];
            sortedData = newData;
        }

        if (!sortAsc) sortedData.reverse();

        let elements:OperationElement[] = [];
        sortedData.forEach((item)=>{
            elements.push(item);
            if(item['subRows']){
                item['subRows'].forEach((subRow)=>{
                    elements.push(subRow);
                });
            }
        });

        const productId = instance.productId;
        const variantId = instance.variantId;

        let foundedNomenclatures = elements.filter((el:OperationElement)=>{
            if (el.productId == productId && el.variantId == variantId && el.isCancelled !== true) return true;
            return false;
        });

        if(foundedNomenclatures.length === 0){
            // showBarcodeScannerNotification('error', <>Номенклатура <span
            //     style={{fontWeight: 800}}>{instance.productShortName}</span> не найдена</>, undefined, null);
            getStore().dispatch(showScanErrorNotification({
                type: ScanErrorType.NomenclatureNotFound,
                productName: instance.productShortName,
            }));
            return;
        }

        let elToAdd = foundedNomenclatures.find((el:OperationElement)=>{
            if(el.anonymousInstanceCount > 0 && !el.instanceIds?.includes(instance.id)) return true;
            return false;
        });

        if(!canAddNewProductsToOperation(state.operationForm.typeCode)){
            let elToAdd = foundedNomenclatures.find((el:OperationElement)=>{
                return !el.instanceIdsOriginal?.includes(instance.id);
            });
            if(elToAdd){
                getStore().dispatch(showScanErrorNotification({
                    type: ScanErrorType.ScanningOnAddedTabErrorWhenCantAddingNewNomenclatures
                }));
                return;
            }
        }

        if(!elToAdd){
            let el = foundedNomenclatures.find((el:OperationElement)=>{
                if(el.instanceIds?.includes(instance.id)) return true;
                return false;
            });
            if(el){
                // showBarcodeScannerNotification('error', <>Экземпляр <span
                //     style={{fontWeight: 800}}>{instance.nameOrCode}</span> уже добавлен</>, undefined, null);
                getStore().dispatch(showScanErrorNotification({
                    type: ScanErrorType.InstanceAlreadyAdded,
                    instanceName: instance.nameOrCode
                }));
                return;
            }
        }

        if(elToAdd){
            if(foundedNomenclatures.find((el:OperationElement) => el.instanceIds?.includes(instance.id))){
                // showBarcodeScannerNotification('error', <>Экземпляр <span
                //     style={{fontWeight: 800}}>{instance.nameOrCode}</span> уже добавлен</>, undefined, null);
                getStore().dispatch(showScanErrorNotification({
                    type: ScanErrorType.InstanceAlreadyAdded,
                    instanceName: instance.nameOrCode
                }));
                return;
            }
            //
            const res = await loadNomenclaturesOnIntervalsIfNeed([{id: instance.id, type: NomenclatureEntityTypeCodeEnum.INSTANCE, from: moment(elToAdd.rentPeriodStartDate).valueOf(), until: moment(elToAdd.rentPeriodEndDate).valueOf()}]);
            getStore().dispatch(updateAnyTimetables(res.timetables, res.elementsDelayedReturnDates));
            getStore().dispatch(addInstanceToElement(elToAdd.id, instance.id));
            onSuccess();
        }else{
            // showBarcodeScannerNotification('error', <>Номенклатура <span
            //     style={{fontWeight: 800}}>{instance.productShortName}</span> с анонимным экз. не
            //     найдена</>, undefined, null);
            getStore().dispatch(showScanErrorNotification({
                type: ScanErrorType.NomenclatureWithAnonymousInstanceNotFound,
                productName: instance.productShortName,
            }));
        }
    };

}
