import React from 'react';
import ReactDOM from 'react-dom';
import App from './app/App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { Provider } from 'react-redux';
import { loadUser, OidcProvider } from 'redux-oidc';
//import axiosRetry from 'axios-retry';
//import axios from 'axios/index';
//import 'typeface-montserrat';
// TODO Посмотреть как шрифт и сколько весит
import '@openfonts/montserrat_all/index.css';
import './app/assets/styles/app.less';
import userManager from './app/config/userManager';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { IRootState } from './app/shared/reducers';
import isTouchDevice from 'is-touch-device';
import debounce from 'lodash/debounce';
import moment from 'moment';
import { Analytics } from './app/analytics/analytics';
import {
    ContactRecord, CrewMemberRecord,
    CrewMemberTransitionCodeEnum,
    InstanceRecord,
    InstanceTransitionCodeEnum,
    KitRecord,
    ProductRecord,
    TransportationTransitionCodeEnum,
    VariantRecord,
} from './app/server/api';
import {OperationElement, OperationFormState} from './app/modules/main/operationForm/reducers/operationForm.reducer';
import { RentElementsGridItem } from './app/types';
import globalAxios from 'axios';
import { setAppVersion } from './app/shared/reducers/system.reducer';
import { BarcodeScanEventTarget } from './app/shared/BarcodeScanEvent';
import { DerivativeToken, DesignToken, DesignTokenContext, ThemeContext } from './app/components/v2/theme/theme';
import { createTheme } from '@ant-design/cssinjs';
import { initializeStore } from './app/store/store';
import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import { LocalStorageUtils } from './app/core/utils/localStorageUtils';
import {SimpleOrderActionType} from "./app/modules/main/projects/simpleOrders/data/simpleOrderData";

export type EntityType =
    | 'renter'
    | 'product'
    | 'project'
    | 'offer'
    | 'simpleOrder'
    | 'subrent'
    | 'operation'
    | 'element'
    | 'user'
    | 'customField'
    | 'product-instances'
    | 'variant'
    | 'kits-product'
    | 'kit'
    | 'kit-composition'
    | 'template'
    | 'audit'
    | 'analyticsReport'
    | 'pricingScheme'
    | 'businessAccount'
    | 'payment'
    | 'contact'
    | 'location'
    | 'vehicle'
    | 'transportation'
    | 'profession'
    | 'expense'
    | 'crewMember'
    | 'crew-member-composition'
    | 'lifecycle-event'
    | 'workPlanning'
    | 'paymentMethod';

/*
setQuantity - Задать количество (для варианта)
* */
export type EntityActionType =
    | 'save'
    | 'copy'
    | 'edit'
    | 'pay'
    | 'lock'
    | 'archive'
    | 'underMaintenance'
    | 'InStock'
    | 'recover'
    | 'delete'
    | 'setQuantity'
    | 'addInstance'
    | 'createProject'
    | 'createProjectFromTemplate'
    | 'createSupply'
    | InstanceTransitionCodeEnum
    | 'cancel'
    | 'resume'
    | TransportationTransitionCodeEnum
    | CrewMemberTransitionCodeEnum
    | SimpleOrderActionType.rent
    | SimpleOrderActionType.book
    | SimpleOrderActionType.returnToDraft
    | SimpleOrderActionType.finish;

export type GridRecord =
    | ProductRecord
    | VariantRecord
    | InstanceRecord
    | OperationElement
    | RentElementsGridItem
    | ContactRecord
    | KitRecord
    | CrewMemberRecord
    | undefined;

export const getUserAccessToken = (): string => {
    let user = store.getState().oidc.user;
    return user ? user.access_token : '';
};

const setUserSettingsToLS = debounce((store:AppStore) => {
    const state = store.getState();
    if (state.system.businessAccountId && state.oidc.user?.profile.sub) {
        const key = LocalStorageUtils.getKey('user-settings', state.system.businessAccountId, state.oidc.user.profile.sub);

        const userSettings = { ...store.getState().userSettings };
        delete userSettings.blockedUrl;

        localStorage.setItem(key, JSON.stringify(userSettings));
    }
}, 1 * 1000);

const setOperationToLS = debounce((store:AppStore) => {
    const state = store.getState();
    const operationFormState = state.operationForm;
    if(state.system.businessAccountId && state.oidc.user?.profile.sub){
        const key = LocalStorageUtils.getKey('operation', state.system.businessAccountId, state.oidc.user.profile.sub);
        if(operationFormState.mnemoKey){
            let data:OperationFormState = {
                ...operationFormState,
                concurrentOperations: {
                    entities: null
                },
                products: {
                    ...operationFormState.products,
                    entities: null,
                    filteredCount: 0,
                    loading: false,
                    loadingError: null
                },
                equipment: {
                    ...operationFormState.equipment,
                    entities: null,
                    filteredCount: 0,
                    filteredEntities: [],
                    loading: false,
                    loadingError: null
                },
                timeTables: [],
                elementsDelayedReturnDates: {},
                kitsInfo: []
            };
            let str = JSON.stringify(data);
            str = Buffer.from(str).toString('base64');
            localStorage.setItem(key, str);
        }else{

        }
    }
}, 1 * 2000);

export const removeOperationFromLS = () => {
    const state = store.getState();
    if(state.system.businessAccountId && state.oidc.user?.profile.sub){
        const key = LocalStorageUtils.getKey('operation', state.system.businessAccountId, state.oidc.user.profile.sub);
        localStorage.removeItem(key);
    }
};

export const getOperationFromLS = () => {
    const dateParser = function (key, value) {
        const reISO = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*))(?:Z|(\+|-)([\d|:]*))?$/;
        if (typeof value === 'string') {
            let a = reISO.exec(value);
            if (a)return new Date(value);
        }
        return value;
    };

    const store = getStore();
    const state = store.getState();

    if(state.system.businessAccountId && state.oidc.user?.profile.sub){
        const key = LocalStorageUtils.getKey('operation', state.system.businessAccountId, state.oidc.user.profile.sub);
        const item = localStorage.getItem(key);
        if(item){
            const json = Buffer.from(item, 'base64').toString();
            return JSON.parse(json, dateParser) as OperationFormState;
        }
    }
};

export const isOperationInLS = ():boolean => {
    const store = getStore();
    const state = store.getState();
    if(state.oidc.user?.profile.sub){
        const key = LocalStorageUtils.getKey('operation', state.system.businessAccountId, state.oidc.user.profile.sub);
        return Boolean(localStorage.getItem(key));
    }
    return false;
};

/* Тут все ранее сохраненные данные */
const persistedState = {};

const store = initializeStore(persistedState);
store.subscribe(() => setUserSettingsToLS(store));
//store.subscribe(() => setOperationToLS(store));
export const getBusinessAccountId = (): number => store.getState().system.businessAccountId;
export const getStoreState = (): IRootState => store.getState();
export const getStore = () => store;
export type AppStore = typeof store;
export type AppDispatch = typeof store.dispatch;
export type GetState = typeof store.getState;
export type RootState = ReturnType<GetState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;

loadUser(store, userManager);

export const barcodeScanEventTarget = new BarcodeScanEventTarget();

if (isTouchDevice()) document.getElementsByTagName('body')[0].classList.add('rr-is-touch-device');

if (process.env.NODE_ENV !== 'development') console.log = () => {};

// Если есть не сохраненная операция, то выводиться предупреждение при закрытии вкладки
if (process.env.NODE_ENV !== 'development') {
    window.onbeforeunload = function (e) {
        if (getStoreState().operationForm.mnemoKey) {
            // const uuid = getStoreState().operationForm.currentOperationUUID;
            // if (uuid) localStorage.setItem('operation-uuid', uuid);
            let dialogText = 'Возможно, внесенные изменения не сохранятся.';
            e.returnValue = dialogText;
            return dialogText;
        }
    };
}

globalAxios.interceptors.response.use((next) => {
    if (next.headers && next.headers['x-rentrabbit-frontend-version']) {
        if (store.getState().system.appVersion !== next.headers['x-rentrabbit-frontend-version']) {
            store.dispatch(setAppVersion(next.headers['x-rentrabbit-frontend-version']));
        }
    }
    return Promise.resolve(next);
});

//
moment.relativeTimeThreshold('s', 60);
moment.relativeTimeThreshold('m', 60);
moment.relativeTimeThreshold('h', 24);
moment.relativeTimeThreshold('d', 30);
moment.relativeTimeThreshold('M', 12);
moment.relativeTimeThreshold('y', 365);

Analytics.getInstance().init('5ed3e947a413132b296c82f78335b5b6');

function derivativeA(designToken: DesignToken): DerivativeToken {
    return {
        ...designToken,
        //primaryColor: 'red',
        primaryColorDisabled: 'red',
    };
}

// function derivativeB(designToken: DesignToken): DerivativeToken {
//     return {
//         ...designToken,
//         //primaryColor: 'green',
//         primaryColorDisabled: 'green',
//     };
// }

ReactDOM.render(
    <DesignTokenContext.Provider value={{ hashed: true }}>
        <ThemeContext.Provider value={createTheme(derivativeA)}>
            <Provider store={store}>
                <OidcProvider store={store} userManager={userManager}>
                    <App />
                </OidcProvider>
            </Provider>
        </ThemeContext.Provider>
    </DesignTokenContext.Provider>,
    document.getElementById('root')
);

//serviceWorkerRegistration.register();
serviceWorkerRegistration.unregister();
