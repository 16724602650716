import React, {CSSProperties, FC} from 'react';
import {showConfirm} from "../../../../../../components/confirm/showConfirm";
import {LocalizationEnum, localizeIntl} from "../../../../../../localization";
import {useIntl} from "react-intl";
import {useAppDispatch, useAppSelector} from "../../../../../../store/hooks";
import {resetOperation} from "../../../reducers/operationForm.reducer";
import {replace} from "connected-react-router";
import {businessAccountIdSelector} from "../../../../../../shared/reducers/system.reducer";
import {IconClose} from "../../../../../../components/icons";
import {RRoundButton} from "../../../../../../components/button/rRoundButton";
import {Button, Icon} from "antd";
import {clearSimpleOrderListElements} from "../../../../elements/reducers/simpleOrderElements.reducer";

interface Props {
    creationMode?: boolean;
    withoutBorder?: boolean;
    style?: CSSProperties;
}

export const OrderCancelButton: FC<Props> = (props) => {

    const intl = useIntl();
    const dispatch = useAppDispatch();
    const businessAccountId = useAppSelector(businessAccountIdSelector);

    const operationForm_goBackPath = useAppSelector(state=>state.operationForm.goBackPath);

    const onCancelButtonClick = async () => {
        let ok = await showConfirm(
            intl,
            localizeIntl(intl, LocalizationEnum.PAGE__OPERATIONS__MODAL__QUESTIONS__CANCEL)
        );
        if (ok) {
            const path = operationForm_goBackPath && operationForm_goBackPath !== `/${businessAccountId}/order` ? operationForm_goBackPath : `/${businessAccountId}/projects/simpleOrders`;
            dispatch(resetOperation());
            dispatch(clearSimpleOrderListElements());
            dispatch(replace(path));
        }
    };

    const title = props.creationMode ? 'Отменить создание' : 'Сбросить изменения';

    return props.withoutBorder ? (
        <Button
            type={'link'} style={{paddingLeft: 0, paddingRight: 0, color: '#f4516c', fontWeight: 500, display: 'flex', alignItems: 'center', ...props.style}}
            onClick={onCancelButtonClick}
        >
            <Icon component={IconClose} style={{fontSize: 22, lineHeight: 0}} />
            {title}
        </Button>
    ) : (
        <RRoundButton
            style={{marginLeft: 40, marginRight: -22, ...props.style}}
            disabled={false}
            colorScheme={'danger2'}
            onClick={onCancelButtonClick}
            icon={IconClose}
            title={title}
        />
    );
};
