import React, {FC, useState} from 'react';
import {EntityDrawer} from "../../../../components/v2/entityDrawer/EntityDrawer";
import {useAppDispatch, useAppSelector} from "../../../../store/hooks";
import {reloadAllTimetables, setDefaultRentPeriodForSimpleOrder, setRenter} from "../reducers/operationForm.reducer";
import {SimpleOrderPageTopBlock} from "./comonents/simpleOrderPageTopBlock/simpleOrderPageTopBlock";
import moment from "moment";
import {SimpleOrderPageBottomBlock} from "./comonents/simpleOrderPageBottomBlock/simpleOrderPageBottomBlock";
import {ProjectInfoRead} from "../../../../server";
import {OrderElementsListEdit} from "./orderElementsListEdit";
import {useLocation} from "react-router-dom";
import {OrderElementsList} from "./orderElementsList";
import {NomenclaturesList} from "../operationModule/nomenclaturesTab/list/nomenclaturesList";
import {businessAccountIdSelector} from "../../../../shared/reducers/system.reducer";
import {replace} from "connected-react-router";

interface Props {
    orderEntity?: ProjectInfoRead;
    createOperation?: () => void;
}

export const OrderElements: FC<Props> = ({orderEntity, createOperation}) => {
    const [nomenclaturesDrawerVisible, setNomenclaturesDrawerVisible] = useState(false);
    const operationFormState = useAppSelector((state) => state.operationForm);
    const dispatch = useAppDispatch();
    const isSimpleOrderOperationStart = (operationFormState.typeCode && operationFormState.isSimpleOrder);
    const startDate = moment(isSimpleOrderOperationStart ? operationFormState.rentPeriodStartDate : orderEntity?.startDate);
    const endDate = moment(isSimpleOrderOperationStart ? operationFormState.rentPeriodEndDate : orderEntity?.targetFinishDate);
    const totalPrice = (isSimpleOrderOperationStart ? operationFormState.finalTotalPriceWithTaxes : orderEntity?.actualSumAfterTaxes) || 0;
    const taxRate = (isSimpleOrderOperationStart ? operationFormState.taxRate : orderEntity?.taxRate) || 0;
    const taxBaseType = isSimpleOrderOperationStart ? operationFormState.taxBaseType : orderEntity?.taxBaseType;
    const comment = orderEntity?.comment;
    const renterId = isSimpleOrderOperationStart ? operationFormState.renterId : orderEntity?.renterId;
    const taxAmount = isSimpleOrderOperationStart ? ((operationFormState.finalTotalPriceWithTaxes || 0) - operationFormState.finalTotalPrice) : (orderEntity ? (orderEntity.actualSumAfterTaxes - orderEntity.actualSumBeforeTaxes) : 0)
    const acceptedPaymentsSum = orderEntity?.acceptedPaymentsSum || 0;
    const acceptedPaymentsCount = orderEntity?.acceptedPaymentsCount;
    const debtSum = (isSimpleOrderOperationStart ? (totalPrice - acceptedPaymentsSum) : orderEntity?.debtSum) || 0;
    const businessAccountId = useAppSelector(businessAccountIdSelector);

    const data = {
        renterId,
        startDate,
        endDate,
        comment,
        taxRate,
        taxBaseType,
        taxAmount,
        totalPrice,
        acceptedPaymentsSum,
        acceptedPaymentsCount,
        debtSum
    };

    const location = useLocation();

    const rootPath = orderEntity ? location.pathname as any : `/${businessAccountId}/order`;

    return (
        <>
            <div style={{backgroundColor: '#FFFFFF', cursor: 'default'}}>
                <div style={{padding: 32}}>
                    <SimpleOrderPageTopBlock
                        dates={[data.startDate, data.endDate]}
                        onDatesChanged={(dates) => {
                            if (dates && dates[0] && dates[1]){
                                dispatch(setDefaultRentPeriodForSimpleOrder(dates[0], dates[1]));
                                dispatch(reloadAllTimetables());
                            }
                        }}
                        renterId={data.renterId}
                        orderId={orderEntity?.id}
                        order={orderEntity}
                        onRenterChange={(id, name, discount) => {
                            dispatch(setRenter(id, name, discount));
                        }}
                        totalPrice={data.totalPrice}
                        debtSum={data.debtSum}
                    />
                    {
                        !isSimpleOrderOperationStart && orderEntity && (
                            <OrderElementsList
                                gridName={'simpleOrder-elements'}
                                parentType={'project'}
                                parentId={orderEntity.id}
                                location={location}
                            />
                        )
                    }
                    {
                        isSimpleOrderOperationStart && (
                            <OrderElementsListEdit
                                location={location}
                                parentType={'project'}
                                parentId={0}
                                gridName={'simpleOrder-elements'}
                                addElements={() => {
                                    dispatch(replace(location.pathname));
                                    setNomenclaturesDrawerVisible(true);
                                }}
                                parentProjectEntity={orderEntity || undefined}
                                createOperation={createOperation}
                            />
                        )
                    }
                </div>
                <SimpleOrderPageBottomBlock
                    orderId={orderEntity?.id}
                    comment={data.comment}
                    taxRate={data.taxRate}
                    taxBaseType={data.taxBaseType}
                    taxAmount={data.taxAmount}
                    totalPrice={data.totalPrice}
                    acceptedPaymentsSum={data.acceptedPaymentsSum}
                    acceptedPaymentsCount={data.acceptedPaymentsCount}
                    debtSum={data.debtSum}
                />
            </div>

            <EntityDrawer
                visible={nomenclaturesDrawerVisible}
                width={'70vw'}
                isLoading={false}
                title={'Добавление в заказ'}
                placement={'left'}
                onClose={() => {
                    setTimeout(()=>{
                        dispatch(replace(location.pathname));
                    }, 100);
                }}
                onStartClose={() => {
                    setNomenclaturesDrawerVisible(false);
                }}
                destroyOnClose={true}
            >
                {/* два режима, когда добавляем из создания и из редактирования*/}
                <NomenclaturesList rootPath={rootPath} />
            </EntityDrawer>
        </>
    );
};
