import React, {CSSProperties} from 'react';
import drag from '../../../../../../../assets/images/drag.png';
import {Icon, Radio} from 'antd';
import {ProductCompositionOnEditRowAction} from '../hooks/editGrid/useOnRowEditAction';
import {ProductCompositionColumnsObj} from './productCompositionColumns.types';
import {ColumnTypes, TableColumn} from '../../../../../../../components/grid/Table';
import {LocalizationEnum, localize} from '../../../../../../../localization';
import {GRID_IMAGE_COLUMN_WIDTH, MAX_WIDTH} from '../../../../../../../config/constants';
import {EditableAmountField} from '../../../../../../../components/fields/EditableField/variants/EditableAmountField';
import {
    EditablePercentsField
} from '../../../../../../../components/fields/EditableField/variants/EditablePercentsField';
import {
    EditableCurrencyField
} from '../../../../../../../components/fields/EditableField/variants/EditableCurrencyField';
import {MoneyUtils} from '../../../../../../../core/utils/moneyUtils';
import {IconClose} from '../../../../../../../components/icons';
import {isDefined} from "../../../../../../../shared/util/utils";

const disabledStyles: CSSProperties = {
    fontWeight: 400,
    textDecoration: 'none',
    color: 'rgb(87, 89, 98)',
    cursor: 'default',
};

export const productCompositionEditColumns: TableColumn<ProductCompositionColumnsObj>[] = [
    {
        type: ColumnTypes.CustomRender,
        width: 80,
        resizable: true,
        sortable: false,
        render: (value: boolean, rowData: ProductCompositionColumnsObj) => {
            return <div>{rowData.main ? null : <img src={drag} style={{ marginTop: '-3px' }} alt="" />}</div>;
        },
    },
    {
        title: localize(LocalizationEnum.PAGE__KITS__IS_MAIN_MEMBER),
        type: ColumnTypes.CustomRender,
        dataIndex: 'main',
        width: 100,
        resizable: true,
        sortable: false,

        render: (value: boolean, rowData: ProductCompositionColumnsObj, onRowAction: ProductCompositionOnEditRowAction) => {
            const onClickHandler = () => {
                onRowAction('main', undefined, rowData);
            };

            return (
                <div>
                    <Radio checked={value} onClick={onClickHandler} />
                </div>
            );
        },
    },
    {
        title: localize(LocalizationEnum.ASPECT__GRID__COLUMN__IMAGE),
        dataIndex: 'image',
        type: ColumnTypes.Image,
        width: GRID_IMAGE_COLUMN_WIDTH,
        maxWidth: GRID_IMAGE_COLUMN_WIDTH,
        sortable: false,
        resizable: false,
    },
    {
        title: localize(LocalizationEnum.ASPECT__GRID__COLUMN__QUANTITY),
        dataIndex: 'instanceCount',
        type: ColumnTypes.CustomRender,
        minWidth: 150,
        resizable: false,
        sortable: false,
        render: (value: number, rowData: ProductCompositionColumnsObj, onRowAction: ProductCompositionOnEditRowAction) => {
            const instancesCount = rowData.instanceCount;

            return (
                <div
                    className={'rr-grid-shortName-cell'}
                    title={rowData.productShortName}
                    style={{
                        textAlign: 'center',
                    }}
                >
                    <EditableAmountField
                        currentValue={value}
                        baseValue={instancesCount}
                        onChange={(value) => {
                            onRowAction('instanceCount', value, rowData);
                        }}
                    />
                </div>
            );
        },
    },
    {
        title: localize(LocalizationEnum.ASPECT__ENTITY_TYPE__PRODUCT),
        dataIndex: 'productShortName',
        type: ColumnTypes.CustomRender,
        minWidth: 150,
        width: 250,
        resizable: true,
        sortable: false,
        render: (value: number, rowData: any) => {
            return (
                <div style={{ color: '#575962' }} className={`rr-grid-shortName-cell ${rowData.main ? 'kit-of-product-main-element' : ''}`}>
                    {value}
                </div>
            );
        },
    },
    {
        title: localize(LocalizationEnum.ASPECT__GRID__COLUMN__VARIANT),
        dataIndex: 'variantName',
        type: ColumnTypes.CustomRender,
        minWidth: 150,
        width: 200,
        resizable: true,
        sortable: false,
        render: (value: number) => {
            return (
                <div style={{ fontWeight: 400, color: '#575962' }} className={'rr-grid-shortName-cell'}>
                    {value}
                </div>
            );
        },
    },
    {
        title: localize(LocalizationEnum.PAGE__KITS__PRODUCT_STATE),
        dataIndex: 'productStateCode',
        type: ColumnTypes.Status,
        minWidth: 130,
        resizable: false,
        sortable: false,
        defaultHidden: true,
    },
    {
        title: localize(LocalizationEnum.ASPECT__GRID__COLUMN__PRICE_INSTANCE_PER_SHIFT),
        dataIndex: 'pricePerShift',
        type: ColumnTypes.Money,
        className: 'money',
        minWidth: 150,
        sortable: false,
        resizable: false,
    },
    {
        title: localize(LocalizationEnum.ASPECT__GRID__COLUMN__DISCOUNT),
        dataIndex: 'discount',
        type: ColumnTypes.CustomRender,
        minWidth: 150,
        resizable: false,
        sortable: false,
        render: (value: number, rowData: ProductCompositionColumnsObj, onRowAction: ProductCompositionOnEditRowAction) => {
            const { discount } = rowData;

            return (
                <div className={'rr-grid-shortName-cell'} title={rowData.productShortName}>
                    <EditablePercentsField
                        currentValue={value}
                        baseValue={discount}
                        onChange={(value) => {
                            onRowAction('discount', value, rowData);
                        }}
                    />
                </div>
            );
        },
    },
    {
        title: localize(LocalizationEnum.PAGE__KITS__PRICE_WHEN_IN_KIT),
        dataIndex: 'finalInstancePrice',
        type: ColumnTypes.CustomRender,
        minWidth: 150,
        resizable: false,
        sortable: false,
        render: (value: number, rowData: ProductCompositionColumnsObj, onRowAction: ProductCompositionOnEditRowAction) => {
            const { finalInstancePrice, pricePerShift } = rowData;

            const v = isDefined(value) ? MoneyUtils.fromCents(value) : value;
            const baseValue = isDefined(finalInstancePrice) ? MoneyUtils.fromCents(finalInstancePrice) : finalInstancePrice;

            return (
                <div className={'rr-grid-shortName-cell'} title={rowData.productShortName}>
                    <EditableCurrencyField
                        currentValue={v}
                        baseValue={baseValue}
                        onChange={(value) => {
                            onRowAction('finalInstancePrice', MoneyUtils.toCents(value), rowData);
                        }}
                        labelProps={
                            pricePerShift
                                ? undefined
                                : {
                                      style: disabledStyles,
                                      onClick: () => {},
                                  }
                        }
                    />
                </div>
            );
        },
    },
    {
        title: localize(LocalizationEnum.PAGE__KITS__TOTAL_PRICE_WHEN_IN_KIT),
        dataIndex: 'finalTotalPrice',
        type: ColumnTypes.CustomRender,
        minWidth: 170,
        resizable: false,
        sortable: false,
        render: (value: number, rowData: ProductCompositionColumnsObj, onRowAction: ProductCompositionOnEditRowAction) => {
            const { finalTotalPrice, pricePerShift } = rowData;

            const v = isDefined(value) ? MoneyUtils.fromCents(value) : value;
            const baseValue = isDefined(finalTotalPrice) ? MoneyUtils.fromCents(finalTotalPrice) : finalTotalPrice;

            return (
                <div className={'rr-grid-shortName-cell'} title={rowData.productShortName}>
                    <EditableCurrencyField
                        currentValue={v}
                        baseValue={baseValue}
                        onChange={(value) => {
                            onRowAction('finalTotalPrice', MoneyUtils.toCents(value), rowData);
                        }}
                        labelProps={
                            pricePerShift
                                ? undefined
                                : {
                                      style: disabledStyles,
                                      onClick: () => {},
                                  }
                        }
                    />
                </div>
            );
        },
    },
    {
        title: localize(LocalizationEnum.ASPECT__ACTION__DELETE),
        type: ColumnTypes.CustomRender,
        minWidth: 90,
        width: 90,
        maxWidth: MAX_WIDTH(90),
        resizable: false,
        sortable: false,
        render: (value: undefined, professionData: ProductCompositionColumnsObj, onRowAction: ProductCompositionOnEditRowAction) => {
            return (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Icon
                        className={'actions-delete-icon'}
                        onClick={() => onRowAction('delete', value, professionData)}
                        component={IconClose}
                    />
                </div>
            );
        },
    },
];
