import React, {CSSProperties, ReactNode} from 'react';
import {Icon, Select} from 'antd';
import {IconClose} from '../../../../../components/icons';
import {tableCellProblemRenderer} from '../../../../../components/grid/renderers/tableCellProblemRenderer';
import {
    InstanceRecord,
    InstanceStateCodeEnum,
    NomenclatureEntityTypeCodeEnum,
    OperationTypeCodeEnum,
    RentStateCodeEnum
} from '../../../../../server/api';
import {SelectValue} from 'antd/lib/select';
import {IRootState} from '../../../../../shared/reducers';
import {connect} from 'react-redux';
import {OperationElement} from '../../reducers/operationForm.reducer';
import {LocalizationEnum, localize} from '../../../../../localization';
import {
    canBeErrorWhenShortage,
    isOrderOperation,
    OperationErrorCodeEnum,
    operationWithShortage,
    canAddNewProductsToOperation
} from "../../utils/utils";
import {ProductInstancePopover} from "../../../inventory/instances/components/productInstancePopover/productInstancePopover";
import {ProductUtils} from "../../../../../shared/util/productUtils";
import Spin from "../../../../../components/spin/spin";
import {InstanceCalendarPopover} from "../components/instanceCalendarPopover/instanceCalendarPopover";
import {listAllInstancesOnIntervalForProductOrVariantFunction} from "../../api/operationForm.api";
import {findTimeTableById, getPossibleDelayedRentElementIds, getTargetStackTypeCodes} from "../../utils/nomenclaturesUtils";
import {getInstanceAvailability} from "../../utils/availabilityUtils";
import {getIsElementReducesAvailability} from "../../utils/operationUtils";
import moment from "moment";
import './ProductInstancesSelect.less';

export interface ProductInstancesSelectProps extends StateProps {
    onChange?: (value: SelectValue, option: React.ReactElement<any> | React.ReactElement<any>[], metaKey: boolean) => void;
    value?: SelectValue;
    productId: number;
    variantId?: number;
    ref1?: any;
    includeIds?: number[];
    startDate: number;
    endDate: number;
    elementRecord?: OperationElement;
    copyMode: boolean;
    dropdownStyle?: CSSProperties;
    requiredTimeIndentBetweenElementsInMinutes?: number;
    canUpdate: boolean;
}

export interface IState {
    loading: boolean;
    instances?: InstanceRecord[];
    loadingError: boolean;
}

export interface InstanceState {
    disabled: boolean;
    problem: boolean;
    warning: boolean;
    item: InstanceRecord;
    intervals: [number, number][];
    availableInstanceCount: number;
}

class _ProductVariantSelect extends React.PureComponent<ProductInstancesSelectProps, IState> {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            loadingError: false
        };
    }
    private metaKey = false;

    componentDidMount() {
        if(this.props.elementRecord) void this.loadData(true);
    };

    loadData = async (preloadData: boolean = false) => {
        let { renterId, isSubrentOperation } = this.props;
        this.setState({loading: true, loadingError: false});

        let from = moment(this.props.startDate)
            .startOf('month')
            .subtract(1, 'month')
            .valueOf();
        let until = moment(this.props.endDate)
            .endOf('month')
            .add(1, 'month')
            .valueOf();

        try{
            if(preloadData){
                if(this.props.value){
                    const arr:InstanceRecord[] = [];
                    if(this.props.value){
                        (this.props.value as number[]).forEach((id)=>{
                            const tt = findTimeTableById(id, NomenclatureEntityTypeCodeEnum.INSTANCE, this.props.timeTables);
                            if(tt){
                                arr.push(tt.nomenclature as InstanceRecord);
                            }
                        });
                    }
                    this.setState({instances: arr, loading: false});
                }
            }else{
                const res = await listAllInstancesOnIntervalForProductOrVariantFunction({
                    businessAccountId: this.props.businessAccountId,
                    from: from,
                    until: until,
                productId: this.props.productId,
                variantId: this.props.variantId,
                targetStackTypeCodes: getTargetStackTypeCodes({isProjectTemplate: this.props.projectTemplate,isOrderOperation: isOrderOperation(this.props.operationTypeCode)}),
                    creationOrNewElement: this.props.elementRecord && this.props.elementRecord.id > -1 ? false : true,
                    possibleDelayedRentElementIds: getPossibleDelayedRentElementIds(this.props.elements),
                    supplierId: isSubrentOperation && renterId ? renterId : undefined
                });
                this.setState({instances: res.data.records.map(record=>record.nomenclature as InstanceRecord), loading: false});
            }
        }catch(error){
            this.setState({loading: false, loadingError: true});
        }
    };

    shouldComponentUpdate(nextProps:ProductInstancesSelectProps, nextState) {
        if(!nextProps.canUpdate) return false;
        return true;
    };

    replaceAnonymous = async (anonymousCount: number) => {
        if (this.props.onChange) {
            if(!this.state.instances || this.state.instances.length === 0){
                await this.loadData();
            }
            let v = this.state.instances
                ? this.getInstancesData(this.state.instances)
                      .filter((item) => item.availableInstanceCount > 0)
                      .map((item) => item.item.id)
                      .slice(0, anonymousCount)
                : [];
            if (v.length > 0) this.props.onChange([...((this.props.value || []) as number[]), ...v], [], false);
        }
    };

    renderData = (data: InstanceRecord[]): ReactNode => {
        return this.getInstancesData(data).map(({ disabled, warning, problem, item, intervals }, index) => {
            return (
                <Select.Option
                    key={index}
                    value={item.id}
                    disabled={disabled}
                    label={
                        <div
                            className={
                                'rr-product-variant-select__choice' +
                                /*aviableCount < 0*/ (warning || problem ? ' rr-product-variant-select__choice-not-available' : '') +
                                (warning ? ' rr-product-variant-select__choice-warning' : '') +
                                (problem ? ' rr-product-variant-select__choice-error' : '')
                            }
                        >
                            <ProductInstancePopover instanceId={item.id}>
                                <span
                                    onClick={(e) => {
                                        e.stopPropagation();
                                    }}
                                >
                                    {item.nameOrCode}
                                </span>
                            </ProductInstancePopover>{' '}
                            <Icon className={'rr-product-variant-select__choice-closeIcon'} component={IconClose} />
                        </div>
                    }
                >
                    <div
                        className={
                            'rr-product-variant-select-menu-item' +
                            (item.problemsAndWarnings.anyShortage || item.problemsAndWarnings.anyDelay ? ' rr-grid-problem-row' : '') +
                            (disabled
                                ? ' rr-product-variant-select-menu-item-not-available' +
                                  /*this.props.elementRecord ? '__element' :*/ '__product'
                                : '') +
                            (warning ? ' rr-product-variant-select-menu-item__warning' : '') +
                            (problem ? ' rr-product-variant-select-menu-item__error' : '')
                        }
                    >
                        {item.problemsAndWarnings.anyShortage || item.problemsAndWarnings.anyDelay ? (
                            <div className={'rr-product-variant-select-menu-item__problem'} /*style={{pointerEvents: 'none'}}*/>
                                {tableCellProblemRenderer(undefined, item, undefined, 'product-instances')}
                            </div>
                        ) : null}
                        <div className={'rr-grid-shortName-cell rr-product-variant-select-menu-item__title'}>
                            <ProductInstancePopover instanceId={item.id}>
                                <span
                                    onClick={(e) => {
                                        e.stopPropagation();
                                    }}
                                >
                                    {item.nameOrCode}
                                </span>
                            </ProductInstancePopover>
                        </div>
                        <div className={'rr-product-variant-select-menu-item__calendar'}>
                            {
                                !this.props.projectTemplate ?
                                    // <OperationInstanceCalendarPopover startDate={this.props.startDate}
                                    //                                   endDate={this.props.endDate}
                                    //                                   productId={this.props.productId}
                                    //                                   variantId={this.props.variantId}
                                    //                                   instanceId={item.id} intervals={intervals}>
                                    //     {calendarIcon}
                                    // </OperationInstanceCalendarPopover>
                                    <InstanceCalendarPopover
                                        startDate={this.props.startDate}
                                        endDate={this.props.endDate}
                                        nomenclature={item}
                                        getInstancesDataFn={this.getInstancesData}
                                    />
                                    : <div style={{height: 22}} />
                            }
                        </div>
                    </div>
                </Select.Option>
            );
        });
    };

    onChange = (value: SelectValue, option: React.ReactElement<any> | React.ReactElement<any>[]) => {
        if (this.props.onChange) {
            let value1 = Array.isArray(value) ? (value as Array<any>).filter((v) => v !== '?') : value;
            this.props.onChange(value1, option, this.metaKey);
        }
    };

    onMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
        this.metaKey = e.ctrlKey || e.metaKey;
    };

    getInstancesData = (instances: InstanceRecord[]): InstanceState[] => {
        return instances.map((item) => {
            let disabled = false;
            let warning = false;
            let problem = false;

            let availableInstanceCount = 0;
            let intervals: [number, number][] = [];

            // let timeTable = findTimeTable(this.props.timeTables, {
            //     productId: this.props.productId,
            //     variantId: this.props.variantId,
            //     instanceId: item.id,
            // });

            let availabilityFactor = this.props.isSubrentOperation ? -1 : 1;

            //if (timeTable) {
                // Карта по этому экземпляру, которая типа в гриде
                //let map = isOrderOperation(this.props.operationTypeCode) ? timeTable.orderAvailable : timeTable.available1;
                //intervals = !this.props.projectTemplate ? map : timeTable.stock;
                intervals = getInstanceAvailability(item, this.props.operationForm);
                let itemSelected = this.props.value && (this.props.value as number[]).includes(item.id);

                let requiredIndent = (this.props.requiredTimeIndentBetweenElementsInMinutes || 0) * 60 * 1000;

                if (!this.props.elementRecord || this.props.copyMode === true) {
                    // Создание в продуктах или копирование в Инвентаре
                    let availableIntervals;
                    if (itemSelected) {
                        // Вычитаем столько сколько хотим добавить
                        intervals = ProductUtils.getNewIntervals(intervals, [[availabilityFactor * 1, this.props.startDate, this.props.endDate]]);

                        availableIntervals = ProductUtils.getNewIntervals(intervals, [
                            [availabilityFactor * 1, this.props.startDate - requiredIndent, this.props.startDate],
                            [availabilityFactor * 1, this.props.endDate, this.props.endDate + requiredIndent],
                            //...ProductUtils.findInstancesInElements(this.props.elements, item.id)
                        ]);
                    } else {
                        availableIntervals = intervals;
                    }

                    availableInstanceCount = ProductUtils.getMinimumValueInInterval(
                        availableIntervals /*intervals*/,
                        this.props.startDate - requiredIndent,
                        this.props.endDate + requiredIndent
                    );

                    if (itemSelected) {
                        // Экземпляр выбран
                        if (availableInstanceCount < 0) {
                            let err = canBeErrorWhenShortage(this.props.operationTypeCode, this.props.operationTypeCorrectionCode, undefined); // TODO EDIT !!!
                            if (err === OperationErrorCodeEnum.PROBLEM) problem = true;
                            else if (err === OperationErrorCodeEnum.WARNING) warning = true;
                        }
                    } else {
                        // Экземпляр не выбран
                        if (availableInstanceCount <= 0 && !operationWithShortage(this.props.operationTypeCode)) {
                            disabled = true;
                        }
                    }
                } else if (this.props.elementRecord && this.props.elementRecord.id < 0) {
                    // Редактирование нового
                    if (this.props.elementRecord.instanceIds?.includes(item.id)) {
                        intervals = ProductUtils.getNewIntervals(intervals, [
                            // Компенсируем что было в начале
                            [
                                availabilityFactor * -1,
                                this.props.elementRecord.rentPeriodStartDate.getTime(),
                                this.props.elementRecord.rentPeriodEndDate.getTime(),
                            ],
                        ]);
                    }

                    let availableIntervals;
                    if (itemSelected) {
                        // Вычитаем столько сколько хотим добавить
                        intervals = ProductUtils.getNewIntervals(intervals, [[availabilityFactor * 1, this.props.startDate, this.props.endDate]]);

                        availableIntervals = ProductUtils.getNewIntervals(intervals, [
                            [availabilityFactor * 1, this.props.startDate - requiredIndent, this.props.startDate],
                            [availabilityFactor * 1, this.props.endDate, this.props.endDate + requiredIndent],
                        ]);
                    } else {
                        availableIntervals = intervals;
                    }

                    availableInstanceCount = ProductUtils.getMinimumValueInInterval(
                        availableIntervals,
                        this.props.startDate - requiredIndent,
                        this.props.endDate + requiredIndent
                    );

                    if (
                        availableInstanceCount <= 0 &&
                        this.props.operationTypeCode !== OperationTypeCodeEnum.ORDER &&
                        this.props.operationTypeCode !== OperationTypeCodeEnum.DRAFT &&
                        this.props.operationTypeCode !== OperationTypeCodeEnum.CORRECT &&
                        !itemSelected
                    ) {
                        disabled = true;
                    }
                    if (availableInstanceCount < 0 && itemSelected) {
                        let err = canBeErrorWhenShortage(this.props.operationTypeCode, this.props.operationTypeCorrectionCode, undefined); // TODO EDIT !!!
                        if (err === OperationErrorCodeEnum.PROBLEM) problem = true;
                        else if (err === OperationErrorCodeEnum.WARNING) warning = true;
                    }

                    if (this.props.isSubrentOperation/*isSubrentOperation(this.props.operationTypeCode, this.props.operationTypeCorrectionCode)*/) {
                        disabled = false;
                    }
                } else if (this.props.elementRecord.rentPeriodStartDateOriginal && this.props.elementRecord.rentPeriodEndDateOriginal) {
                    // Редактирование существующего

                    let isElementReducesAvailability = getIsElementReducesAvailability(this.props.elementRecord);
                    let isCorrectCancelOperation =
                        this.props.operationTypeCode === OperationTypeCodeEnum.CORRECT &&
                        this.props.operationTypeCorrectionCode === RentStateCodeEnum.CANCELED;

                    intervals = ProductUtils.getNewIntervals(intervals, [
                        // Компенсируем текущее значение
                        [
                            availabilityFactor * (isCorrectCancelOperation ? 0 : 1) * -(this.props.elementRecord.instanceIds?.includes(item.id) ? 1 : 0),
                            this.props.elementRecord.rentPeriodStartDate.getTime(),
                            this.props.elementRecord.rentPeriodEndDate.getTime(),
                        ],
                        // Компенсируем остаток
                        [
                            availabilityFactor *
                                ((isElementReducesAvailability ||
                                    (this.props.elementRecord.stateCode === RentStateCodeEnum.ORDERED &&
                                        isOrderOperation(this.props.operationTypeCorrectionCode || this.props.operationTypeCode))) &&
                                this.props.elementRecord.variantId === this.props.elementRecord.variantIdOriginal
                                    ? 1
                                    : 0) *
                                -(this.props.elementRecord.instanceIdsOriginal?.includes(item.id) &&
                                !this.props.elementRecord.instanceIds?.includes(item.id)
                                    ? 1
                                    : 0),
                            this.props.elementRecord.rentPeriodStartDateOriginal.getTime(),
                            this.props.elementRecord.rentPeriodEndDateOriginal.getTime(),
                        ],
                    ]);

                    let availableIntervals;
                    if (itemSelected) {
                        intervals = ProductUtils.getNewIntervals(intervals, [
                            // Вычитаем текущее значение
                            [availabilityFactor * (isCorrectCancelOperation ? 0 : 1) * 1, this.props.startDate, this.props.endDate],
                        ]);

                        availableIntervals = ProductUtils.getNewIntervals(intervals, [
                            [availabilityFactor * (isCorrectCancelOperation ? 0 : 1) * 1, this.props.startDate - requiredIndent, this.props.startDate],
                            [availabilityFactor * (isCorrectCancelOperation ? 0 : 1) * 1, this.props.endDate, this.props.endDate + requiredIndent],
                            //...ProductUtils.findInstancesInElements(this.props.elements, item.id, this.props.elementRecord.id)
                        ]);
                    } else {
                        availableIntervals = intervals;
                    }

                    // Вычитаем остаток
                    if (this.props.elementRecord.keepLeftover) {
                        intervals = ProductUtils.getNewIntervals(intervals, [
                            [
                                availabilityFactor *
                                    (isElementReducesAvailability && this.props.elementRecord.variantId === this.props.elementRecord.variantIdOriginal ? 1 : 0) *
                                    (this.props.elementRecord.instanceIdsOriginal?.includes(item.id) && !itemSelected ? 1 : 0),
                                this.props.elementRecord.rentPeriodStartDateOriginal.getTime(),
                                this.props.elementRecord.rentPeriodEndDateOriginal.getTime(),
                            ],
                        ]);
                    }

                    availableInstanceCount = ProductUtils.getMinimumValueInInterval(
                        availableIntervals,
                        this.props.startDate - requiredIndent,
                        this.props.endDate + requiredIndent
                    );

                    if (
                        availableInstanceCount <= 0 &&
                        this.props.operationTypeCode !== OperationTypeCodeEnum.ORDER &&
                        this.props.operationTypeCode !== OperationTypeCodeEnum.DRAFT &&
                        this.props.operationTypeCode !== OperationTypeCodeEnum.CORRECT &&
                        !itemSelected
                    ) {
                        disabled = true;
                    }

                    if (availableInstanceCount < 0 && itemSelected) {
                        let err = canBeErrorWhenShortage(this.props.operationTypeCode, this.props.operationTypeCorrectionCode, undefined); // TODO EDIT !!!
                        if (err === OperationErrorCodeEnum.PROBLEM) problem = true;
                        else if (err === OperationErrorCodeEnum.WARNING) warning = true;
                    } else if (availableInstanceCount < 0) {
                        let err = canBeErrorWhenShortage(this.props.operationTypeCode, this.props.operationTypeCorrectionCode, undefined); // TODO EDIT !!!
                        if (err === OperationErrorCodeEnum.WARNING) disabled = false;
                    }
                    if (this.props.isSubrentOperation) {
                        disabled = false;
                    }
                    if (warning) disabled = false;

                    if (
                        !canAddNewProductsToOperation(this.props.operationTypeCode) &&
                        this.props.elementRecord &&
                        this.props.elementRecord.instanceIdsOriginal
                    ) {
                        disabled = !this.props.elementRecord.instanceIdsOriginal.includes(item.id);
                    }
                }
            //}

            if (this.props.projectTemplate) {
                if (problem || warning) warning = true;
                problem = false;
            }

            if(this.props.operationTypeCode === OperationTypeCodeEnum.EDIT){
                if(this.props.elementRecord?.stateCodeNew && [RentStateCodeEnum.RENT, RentStateCodeEnum.RETURNED, RentStateCodeEnum.RETURNEDBROKEN, RentStateCodeEnum.LOSTDURINGRENT, RentStateCodeEnum.SUBRENT, RentStateCodeEnum.SUBRENTRETURNEDTOSHIPPER].includes(this.props.elementRecord.stateCodeNew)){
                    if(this.props.elementRecord.instanceIdsOriginal && this.props.elementRecord.instanceIdsOriginal.includes(item.id)) {
                        disabled = true;
                    }
                }
            }

            return {
                problem,
                warning,
                disabled,
                item,
                intervals,
                availableInstanceCount,
            };
        });
    };

    render() {
        let instances = this.state.instances
            ? this.state.instances.filter((instance) => {
                  if (this.props.includeIds) {
                      return this.props.includeIds.includes(instance.id);
                  } else {
                      return true;
                  }
              })
            : [];

        instances = this.state.instances
            ? this.state.instances.filter(
                  (instance) =>
                      (instance.stateCode !== InstanceStateCodeEnum.DECOMMISSIONED &&
                          instance.stateCode !== InstanceStateCodeEnum.SUPPLIERDECOMMISSIONED) ||
                      ((instance.stateCode === InstanceStateCodeEnum.DECOMMISSIONED ||
                          instance.stateCode === InstanceStateCodeEnum.SUPPLIERDECOMMISSIONED) &&
                          this.props.elementRecord &&
                          this.props.elementRecord.instanceIdsOriginal &&
                          this.props.elementRecord.instanceIdsOriginal.includes(instance.id))
              )
            : [];

        let value = this.props.value;

        if (
            this.state.instances &&
            !this.state.instances.find(
                (inst) => this.props.value && Array.isArray(this.props.value) && (this.props.value as number[]).includes(inst.id)
            )
        ) {
            value = [];
        }

        return (
            <div onMouseDown={this.onMouseDown}>
                <Select
                    onDropdownVisibleChange={(visible: boolean) => {
                        if (visible) this.loadData();
                        //else this.setState({instances: undefined});
                    }}
                    getPopupContainer={(triggerNode) => triggerNode.parentNode as any}
                    ref={this.props.ref1}
                    showAction={['click']}
                    defaultActiveFirstOption={false}
                    value={instances.length ? value : []}
                    onChange={this.onChange}
                    className={'rr-product-variant-select'}
                    dropdownClassName={'rr-product-variant-select-dropdown'}
                    dropdownStyle={this.props.dropdownStyle}
                    mode="multiple"
                    style={{ width: '100%' }}
                    placeholder="Выбрать экземпляры"
                    optionLabelProp="label"
                    filterOption={(inputValue, option) => {
                        let id = option.props.value;
                        if (instances) {
                            let filteredInstances = instances.filter((item) =>
                                item.nameOrCode.toLowerCase().includes(inputValue.toLowerCase())
                            );
                            if (filteredInstances.length > 0) {
                                if (filteredInstances.find((item) => item.id === id)) return true;
                            }
                        }
                        return false;
                    }}
                    notFoundContent={localize(LocalizationEnum.ASPECT__DATA_PRESENCE__DATA_NOT_FOUND)}
                    dropdownRender={(menu) => (
                        <>
                            {!this.state.loading ? menu : undefined}
                            {this.state.loading && !this.state.loadingError ? (
                                <div className={'rr-custom-select-loading-block'}>
                                    <Spin size={'small'} delay={0} />
                                    <span>{localize(LocalizationEnum.ASPECT__DATA_PRESENCE__DATA_LOADING)}</span>
                                </div>
                            ) : null}
                            {!this.state.loading && this.state.loadingError ? (
                                <div className={'rr-custom-select-error-block'}>
                                    {localize(LocalizationEnum.ASPECT__DATA_PRESENCE__DATA_LOADING_ERROR)}
                                </div>
                            ) : null}
                        </>
                    )}
                >
                    {instances ? this.renderData(instances) : null}
                </Select>
            </div>
        );
    }
}

const mapStateToProps = (storeState: IRootState) => {
    return {
        businessAccountId: storeState.system.businessAccountId,
        elements: storeState.operationForm.elements.entities,
        operationTypeCode: storeState.operationForm.typeCode,
        operationTypeCorrectionCode: storeState.operationForm.targetStateCode,
        projectTemplate: storeState.operationForm.projectTemplate,
        isSubrentOperation: storeState.operationForm.isSubrent,
        renterId: storeState.operationForm.renterId,
        operationForm: storeState.operationForm,
        timeTables: storeState.operationForm.timeTables
    };
};


type StateProps = ReturnType<typeof mapStateToProps>;

export const ProductInstancesSelect = connect(mapStateToProps, null, null, { forwardRef: true })(_ProductVariantSelect);
