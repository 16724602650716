import React, {createRef, ReactNode, RefObject} from 'react';
import { Checkbox, Form, Icon, Popover } from 'antd';
import { IconCogsSolid } from '../icons';
import { FormItemType, IFormField, IFormFieldImageUpload, IFormProps } from '../dynamicForm/DynamicForm';
import { createFormItem } from '../dynamicForm/formUtils';
import './DynamicFilters.less';
import { LocalizationEnum, localize } from '../../localization';
import isEqual from 'lodash/isEqual';
import { CustomFieldObjRead } from '../../server';
import { CheckboxDragController } from '../controllers/drag/CheckboxDragController/CheckboxDragController';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { GridStorageData } from '../grid/utils';
import { GridName } from '../grid/utils/types';
import { UserPermissionEnum } from '../../shared/UserPermission';
import { GridSettingsData } from '../../shared/reducers/userSettings/userSettings.reducer';
import classNames from 'classnames';
import { ConditionalLink } from '../lib/ConditionalLink/ConditionalLink';
import { canUpdateSystemOptions } from '../../shared/util/permissionUtils';
import { FiltersCountBlock } from './components/FiltersCountBlock/FiltersCountBlock';
import { FiltersVisibleToggle } from './components/FiltersVisibleToggle/FiltersVisibleToggle';
import cloneDeep from 'lodash/cloneDeep';

interface IState {
    otherFiltersCollapsed?: boolean;
    hiddenFilters: string[];
}

interface IProps extends IFormProps {
    gridName: GridName;
    businessAccountId: number;
    permissions: UserPermissionEnum[];
    gridSettings: GridStorageData;
    updateGridSettings: (params: GridSettingsData) => void;
    categoriesFilterSelected?: boolean;
    resetFiltersCb?: () => void;
    otherFiltersCollapsed?: boolean;
    onHideCustomValue?: (key: string) => void;
    onOtherFiltersCollapse?: (collapsed: boolean) => void;
    otherFiltersCollapsed1?: (otherFiltersCollapsed: boolean | undefined) => void;
    canResetFilters?: boolean;
    dontResetFilters?: string[];
    title?: React.ReactElement;
    fetchEntityErrorCallback?: (entityId: number) => void;
    customFieldMarkers?: CustomFieldObjRead[];
    hiddenFieldsForCount?: string[];
    rightBlockExtraContent?: ReactNode;
}

class _DynamicFiltersMain extends React.PureComponent<IProps, IState> {
    private isCheckboxesOpened: boolean = false;
    private checkboxesPopoverRef: RefObject<Popover>;

    public static defaultProps = {
        viewMode: false,
        highlightChangedFields: true,
    };

    constructor(props: IProps) {
        super(props);

        this.checkboxesPopoverRef = createRef();

        let { data, currentValues, otherFiltersCollapsed } = this.props;

        const otherFiltersHasValues =
            currentValues == null
                ? false
                : data[1]?.fields
                      .filter(
                          (item) =>
                              this.props.currentValues &&
                              this.props.defaultValues &&
                              !isEqual(this.props.defaultValues[item.id], this.props.currentValues[item.id])
                      )
                      .some((field) => {
                          const value = currentValues?.[field.id];

                          if (this.props.excludeFields && this.props.excludeFields.includes(field.id)) return false;

                          if (value == null) return false;

                          if (Array.isArray(value)) {
                              return value[0] != null || value[1] != null;
                          } else {
                              return true;
                          }
                      });

        const hiddenFilters = this.processHiddenFilters();

        this.state = {
            otherFiltersCollapsed: otherFiltersHasValues ? false : otherFiltersCollapsed,
            hiddenFilters,
        };
    }

    processHiddenFilters = (): string[] => {
        const hiddenFilters: string[] = [];

        const gridData: GridStorageData = this.props.gridSettings ? cloneDeep(this.props.gridSettings) : ({} as GridStorageData);
        if (!gridData.visibleFilters) gridData.visibleFilters = {};

        this.props.data?.[1]?.fields.forEach((filter) => {
            if (gridData.visibleFilters == null) return;

            if (filter.defaultHidden === true && gridData.visibleFilters[filter.id] === undefined) {
                gridData.visibleFilters[filter.id] = { hidden: true };
            } else if (filter.defaultHidden === false) {
                gridData.visibleFilters[filter.id] = { hidden: false };
            }
        });

        for (let k in gridData.visibleFilters) {
            if (gridData.visibleFilters[k].hidden) hiddenFilters.push(k);
        }

        return hiddenFilters;
    };

    onSmartFiltersToggleButtonClick = () => {
        const otherFilters = this.props.data[1] ? this.props.data[1].fields : [];
        let hasSelectedOtherFilters =
            otherFilters.filter((item) => this.props.initialValues && this.props.initialValues[item.id] !== undefined).length > 0;

        if (this.props.defaultValues && this.props.currentValues) {
            hasSelectedOtherFilters =
                otherFilters.filter(
                    (item) =>
                        this.props.currentValues &&
                        this.props.defaultValues &&
                        this.props.defaultValues[item.id] !== this.props.currentValues[item.id]
                ).length > 0;
        }
        let otherFiltersCollapsed = true;
        if (this.state.otherFiltersCollapsed !== undefined) {
            otherFiltersCollapsed = this.state.otherFiltersCollapsed;
        } else {
            if (hasSelectedOtherFilters) otherFiltersCollapsed = false;
        }
        if (this.props.onOtherFiltersCollapse) this.props.onOtherFiltersCollapse(!otherFiltersCollapsed);
        this.setState({ otherFiltersCollapsed: !otherFiltersCollapsed });

        if (this.props.otherFiltersCollapsed1) {
            this.props.otherFiltersCollapsed1(this.state.otherFiltersCollapsed || true);
        }
    };

    renderCheckboxes = () => {
        const { customFieldMarkers } = this.props;

        const filters =
            this.props.data?.[1]?.fields.filter(
                (item) =>
                    this.props.customFieldMarkers == null ||
                    !Boolean(this.props.customFieldMarkers.find((marker) => marker.customFieldIndexKey === item.id))
            ) ?? [];

        return (
            <>
                <div className="rr-calendar-popup-header">Системные фильтры:</div>
                <CheckboxDragController className={'popover-filters-checkboxes'}>
                    {({ onMouseEnter, refSetter }) => (
                        <>
                            {filters.map((filter, index) => {
                                const dataIndex = filter.id;
                                let checked = !this.state.hiddenFilters.includes(dataIndex);

                                const onChange = (event: CheckboxChangeEvent) => {
                                    const { checked } = event.target;
                                    const visibleFilters = this.props.gridSettings.visibleFilters
                                        ? { ...this.props.gridSettings.visibleFilters }
                                        : {};
                                    if (!visibleFilters[dataIndex]) visibleFilters[dataIndex] = {};

                                    visibleFilters[dataIndex].hidden = checked ? undefined : true;

                                    this.props.updateGridSettings({
                                        [this.props.gridName]: { ...this.props.gridSettings, visibleFilters },
                                    });

                                    const hiddenFilters = [...this.state.hiddenFilters];

                                    if (!checked) {
                                        // Показываем
                                        if (!hiddenFilters.includes(dataIndex)) {
                                            hiddenFilters.push(dataIndex);
                                        }
                                    } else {
                                        // Прячем
                                        if (hiddenFilters.includes(dataIndex)) {
                                            hiddenFilters.splice(hiddenFilters.indexOf(dataIndex), 1);
                                        }
                                    }
                                    this.setState({ ...this.state, hiddenFilters: hiddenFilters, otherFiltersCollapsed: false });
                                };

                                return (
                                    <Checkbox
                                        ref={refSetter(index)}
                                        key={index}
                                        disabled={false}
                                        checked={checked}
                                        onChange={onChange}
                                        onMouseEnter={() => onMouseEnter(index)}
                                    >
                                        {filter.label ? localize(filter.label as LocalizationEnum) : ''}
                                    </Checkbox>
                                );
                            })}
                        </>
                    )}
                </CheckboxDragController>
                {customFieldMarkers && (
                    <>
                        <div
                            className={classNames('rr-calendar-popup-header', 'custom-fields-text-title', 'for-filters')}
                            style={{ marginTop: 12, width: 180 }}
                        >
                            Фильтры настраиваемых полей:
                        </div>
                        {customFieldMarkers.length > 0 ? (
                            <CheckboxDragController>
                                {({ onMouseEnter, refSetter }) => (
                                    <>
                                        {customFieldMarkers?.map((marker, index) => {
                                            const dataIndex = marker.customFieldIndexKey;
                                            const customFilter = this.props.data?.[1]?.fields.find((field) => field.id === dataIndex);
                                            if (customFilter == null) return null;

                                            let checked = !this.state.hiddenFilters.includes(customFilter.id);

                                            const onChange = (event: CheckboxChangeEvent) => {
                                                const { checked } = event.target;
                                                const visibleFilters = this.props.gridSettings.visibleFilters
                                                    ? { ...this.props.gridSettings.visibleFilters }
                                                    : {};
                                                if (!visibleFilters[dataIndex]) visibleFilters[dataIndex] = {};

                                                visibleFilters[dataIndex].hidden = checked ? undefined : true;

                                                this.props.updateGridSettings({
                                                    [this.props.gridName]: { ...this.props.gridSettings, visibleFilters },
                                                });

                                                const hiddenFilters = [...this.state.hiddenFilters];

                                                if (!checked && !hiddenFilters.includes(dataIndex)) {
                                                    hiddenFilters.push(dataIndex);
                                                    this.props.onHideCustomValue?.(dataIndex);
                                                } else if (hiddenFilters.includes(dataIndex)) {
                                                    hiddenFilters.splice(hiddenFilters.indexOf(dataIndex), 1);
                                                }
                                                this.setState({ ...this.state, hiddenFilters, otherFiltersCollapsed: false });
                                            };

                                            return (
                                                <Checkbox
                                                    ref={refSetter(index)}
                                                    key={index}
                                                    disabled={false}
                                                    checked={checked}
                                                    onChange={onChange}
                                                    onMouseEnter={() => onMouseEnter(index)}
                                                >
                                                    {marker.name}
                                                </Checkbox>
                                            );
                                        })}
                                    </>
                                )}
                            </CheckboxDragController>
                        ) : (
                            <span className={classNames('checkboxes-popover-text', 'for-filters')} style={{}}>
                                Настраиваемых полей нет, добавьте в{' '}
                                <ConditionalLink
                                    condition={canUpdateSystemOptions(this.props.permissions)}
                                    to={`/${this.props.businessAccountId}/settings/customFields`}
                                >
                                    настройках компании
                                </ConditionalLink>
                            </span>
                        )}
                    </>
                )}
            </>
        );
    };

    render() {
        console.log('DynamicFiltersMain render()', this.props, this.state);

        const mainFilters = this.props.data[0]
            ? this.props.data[0].fields.filter(
                  (item) =>
                      item.id !== 'archive' &&
                      item.id !== 'hideArchive' &&
                      item.id !== 'hideVariants' &&
                      item.id !== 'hideInactive' &&
                      item.id !== 'hide' &&
                      item.id !== 'hideStandardFields' &&
                      item.id !== 'hideStandardTemplates' &&
                      item.id !== 'hideAuto' &&
                      item.id !== 'hideDeleted' &&
                      item.id !== 'hideCancelled' &&
                      (!this.props.excludeFields || !this.props.excludeFields.includes(item.id))
              )
            : [];
        const archiveFilter = this.props.data[0]
            ? this.props.data[0].fields.filter(
                  (item) =>
                      (item.id === 'archive' ||
                          item.id === 'hideArchive' ||
                          item.id === 'hideVariants' ||
                          item.id === 'hideInactive' ||
                          item.id === 'hide' ||
                          item.id === 'hideStandardFields' ||
                          item.id === 'hideStandardTemplates' ||
                          item.id === 'hideAuto' ||
                          item.id === 'hideDeleted' ||
                          item.id === 'hideCancelled') &&
                      (!this.props.excludeFields || !this.props.excludeFields.includes(item.id))
              )
            : [];
        const otherFilters = this.props.data[1]
            ? this.props.data[1].fields.filter((item) => !this.props.excludeFields || !this.props.excludeFields.includes(item.id))
            : [];
        const visibleOtherFilters = otherFilters.filter((field) => {
            const isVisible =
                field.visible == null
                    ? true
                    : typeof field.visible === 'function'
                    ? field.visible(this.props.form.getFieldValue, this.props.form)
                    : field.visible;

            return isVisible && !this.state.hiddenFilters.includes(field.id);
        });

        let isSelectedOtherFiltersExists =
            visibleOtherFilters.filter((item) => this.props.initialValues && this.props.initialValues[item.id] !== undefined).length > 0;

        let selectedFiltersCount = this.props.categoriesFilterSelected ? 1 : 0;

        const getHiddenVisible = (item: IFormField | IFormFieldImageUpload) =>
            item.type !== FormItemType.Hidden || (this.props.hiddenFieldsForCount && this.props.hiddenFieldsForCount.includes(item.id));

        if (this.props.defaultValues && this.props.currentValues) {
            isSelectedOtherFiltersExists =
                visibleOtherFilters.filter(
                    (item) =>
                        this.props.currentValues &&
                        this.props.defaultValues &&
                        JSON.stringify(this.props.defaultValues[item.id]) !== JSON.stringify(this.props.currentValues[item.id])
                ).length > 0;

            const visibleOtherFiltersCount = visibleOtherFilters.filter(
                (item) =>
                    getHiddenVisible(item) &&
                    (!this.props.dontResetFilters || !this.props.dontResetFilters.includes(item.id)) &&
                    this.props.currentValues &&
                    this.props.defaultValues &&
                    JSON.stringify(this.props.defaultValues[item.id]) !== JSON.stringify(this.props.currentValues[item.id])
            ).length;
            selectedFiltersCount += visibleOtherFiltersCount;

            const mainFiltersCount = mainFilters.filter(
                (item) =>
                    getHiddenVisible(item) &&
                    (!this.props.dontResetFilters || !this.props.dontResetFilters.includes(item.id)) &&
                    this.props.currentValues &&
                    this.props.defaultValues &&
                    JSON.stringify(this.props.defaultValues[item.id]) !== JSON.stringify(this.props.currentValues[item.id])
            ).length;
            selectedFiltersCount += mainFiltersCount;

            const archiveFilterCount = archiveFilter.filter(
                (item) =>
                    getHiddenVisible(item) &&
                    (!this.props.dontResetFilters || !this.props.dontResetFilters.includes(item.id)) &&
                    this.props.currentValues &&
                    this.props.defaultValues &&
                    JSON.stringify(this.props.defaultValues[item.id]) !== JSON.stringify(this.props.currentValues[item.id])
            ).length;
            selectedFiltersCount += archiveFilterCount;
        }

        let otherFiltersCollapsed = true;
        if (this.state.otherFiltersCollapsed !== undefined) {
            otherFiltersCollapsed = this.state.otherFiltersCollapsed;
        } else {
            if (isSelectedOtherFiltersExists) otherFiltersCollapsed = false;
        }

        const renderFiltersCountBlock = () =>
            this.props.canResetFilters && selectedFiltersCount > 0 ? (
                <FiltersCountBlock selectedFiltersCount={selectedFiltersCount} resetFiltersCb={this.props.resetFiltersCb} />
            ) : null;

        return (
            <div>
                <Form layout={'inline'}>
                    {
                        <div className="rr-grid-filters">
                            <div className="rr-grid-filters-left">
                                {this.props.title ? (
                                    <div className="ant-row ant-form-item">
                                        <div className="ant-col ant-form-item-label">{this.props.title}</div>
                                    </div>
                                ) : null}
                                {mainFilters.map((item, index) => createFormItem(item as IFormField, index, this.props, this))}
                            </div>
                            {visibleOtherFilters.length > 0 ||
                            (visibleOtherFilters.length === 0 && this.props.customFieldMarkers) ||
                            archiveFilter.length > 0 ? (
                                <div className="rr-grid-filters-right">
                                    {archiveFilter.length > 0 ? (
                                        <div style={{ display: 'inline-block' }}>
                                            {archiveFilter.map((item, index) =>
                                                createFormItem(item as IFormField, index, this.props, this)
                                            )}
                                        </div>
                                    ) : null}
                                    {visibleOtherFilters.length > 0 ? (
                                        <FiltersVisibleToggle
                                            onClick={this.onSmartFiltersToggleButtonClick}
                                            bold={isSelectedOtherFiltersExists}
                                            collapsed={otherFiltersCollapsed}
                                        />
                                    ) : null}
                                    {this.props.customFieldMarkers && (
                                        <Popover
                                            ref={this.checkboxesPopoverRef}
                                            trigger={'click'}
                                            placement="bottomRight"
                                            arrowPointAtCenter
                                            overlayClassName={'rr-grid-columns-control-popover-overlay'}
                                            content={
                                                <div className={'rr-grid-columns-control-popover-content'}>{this.renderCheckboxes()}</div>
                                            }
                                            onVisibleChange={(visible) => {
                                                if (visible && !this.isCheckboxesOpened) {
                                                    setTimeout(() => {
                                                        this.isCheckboxesOpened = true;
                                                        const textTitleElement = document.querySelector(
                                                            '.custom-fields-text-title.for-filters'
                                                        );
                                                        const checkboxesElement = document.querySelector('.popover-filters-checkboxes');
                                                        const checkboxesTextElement = document.querySelector(
                                                            '.checkboxes-popover-text.for-filters'
                                                        );

                                                        if (textTitleElement && checkboxesElement && checkboxesTextElement) {
                                                            const textTitleElementWidth = textTitleElement.clientWidth;
                                                            const checkboxesElementWidth = checkboxesElement.clientWidth;
                                                            (checkboxesTextElement as HTMLDivElement).style.width = `${Math.max(
                                                                textTitleElementWidth,
                                                                checkboxesElementWidth
                                                            )}px`;
                                                            this.checkboxesPopoverRef.current?.forceUpdate();
                                                        }
                                                    });
                                                }
                                            }}
                                        >
                                            <div
                                                className={'rr-grid-smart_filters-toggle-btn'}
                                                style={{
                                                    display: 'inline',
                                                    marginLeft: 16,
                                                }}
                                            >
                                                <Icon component={IconCogsSolid} />
                                            </div>
                                        </Popover>
                                    )}
                                    {renderFiltersCountBlock()}
                                    {
                                        this.props.rightBlockExtraContent && (
                                            <div>
                                                {this.props.rightBlockExtraContent}
                                            </div>
                                        )
                                    }
                                </div>
                            ) : (
                                renderFiltersCountBlock()
                            )}
                        </div>
                    }
                    {visibleOtherFilters.length > 0 && (
                        <div
                            className={'rr-grid-smart_filters'}
                            style={{ display: visibleOtherFilters.length > 0 && !otherFiltersCollapsed ? 'block' : 'none' }}
                        >
                            <div className={'ant-form-inline'}>
                                {otherFilters
                                    .filter((filter) => !this.state.hiddenFilters.includes(filter.id))
                                    .map((item, index) => createFormItem(item as IFormField, index, this.props, this))}
                            </div>
                        </div>
                    )}
                </Form>
            </div>
        );
    }
}

export default Form.create<IProps>()(_DynamicFiltersMain);
