import { FAILURE, REQUEST, SUCCESS } from '../../../../../shared/reducers/action-type.util';
import { RenterRecord, RenterRecordList, serverApi, WorkflowDirective } from '../../../../../server';
import { showNotification } from '../../../../../components/notification/showNotification';
import { IRootState } from '../../../../../shared/reducers';
import { LOCATION_CHANGE } from 'connected-react-router';
import { getDatesFromParamsString, getStateFromPath2, getStringServerProblem, test1, xxxParams } from '../../../../../shared/util/utils';
import { LocalizationEnum, localizeIntl } from '../../../../../localization';
import { IntlShape } from 'react-intl';
import { getAvailableGridSortById } from '../../../../../shared/util/getAvailableGridSortById';
import counterpartyColumns from '../../tabs/counterparties/list/CounterpartiesColumns';
import { gridDataChangedSignal } from '../../../../../components/controllers/scroll/SelectScrollController/SelectScrollController';
import { changeCategoryBinding } from '../../../categories/reducers/categories.reducer';
import { subrentModuleEnabledSelector } from '../../../../../shared/reducers/businessAccountPreferences.reducer';
import { getStoreState } from '../../../../../../index';
import { ProblemEnum } from '../../../../../types';
import { MoneyUtils } from '../../../../../core/utils/moneyUtils';

export const ACTION_TYPES = {
    LOAD_ENTITIES: 'renters/LOAD_ENTITIES',
    EXPORT_EXCEL: 'renters/EXPORT_EXCEL',
    RESET: 'renters/RESET',
    CHANGE_ARCHIVE: 'renters/CHANGE_ARCHIVE',
    CHANGE_STATUS: 'renters/CHANGE_STATUS',
};

interface PageParams {
    startFrom?: number | undefined;
    page?: number | undefined;
    limit?: number | undefined;
    sortBy?: string;
    sortOrder?: 'ASC' | 'DESC';
}

export interface RentersPageParams extends PageParams {
    search?: string;
    status?: string;
    typeCode?: string;
    country?: string;
    hideArchive?: boolean;
    shortage?: boolean;
    delay?: boolean;
    problem?: string;
    categoryIds?: string;
    lastActivityDate?: (number | undefined)[];
    activityTypeCode?: string;
    sourceCode?: string;
    debtOfRenter?: number[] | undefined;
    debtToSupplier?: number[] | undefined;
}

export const initialParamsState: RentersPageParams = {
    startFrom: 0,
    page: 1,
    limit: 10,
    sortBy: undefined,
    sortOrder: undefined,
    hideArchive: undefined,
    lastActivityDate: [undefined, undefined],
    activityTypeCode: undefined,
    debtOfRenter: undefined,
    debtToSupplier: undefined,
};

const initialState = {
    loading: false,
    loadingError: null,
    entities: null as Array<RenterRecord> | null,
    filteredCount: 0,
    smartFiltersOpened: false,
    params: {
        ...initialParamsState,
    } as RentersPageParams,
};

export type RentersState = Readonly<typeof initialState>;

// Reducer

export default (state: RentersState = initialState, action): RentersState => {
    switch (action.type) {
        case LOCATION_CHANGE:
            let path = action.payload?.location?.pathname || '';

            if (/\/\d+\/crm\/counterparties(\/?|\/new\/?)$/.test(path)) {
                let params = getStateFromPath2(action.payload.location.search);
                let newParams = xxxParams(state.params, params, initialParamsState);
                return {
                    ...state,
                    params: {
                        ...newParams,
                        problem: params.problem !== undefined ? params.problem : initialParamsState.problem,
                        lastActivityDate: getDatesFromParamsString(params.lastActivityDate, initialParamsState.lastActivityDate),
                        debtOfRenter: test1(params.debtOfRenter, initialParamsState.debtOfRenter)?.map((price) => Math.round(price)),
                        debtToSupplier: test1(params.debtToSupplier, initialParamsState.debtToSupplier)?.map((price) => Math.round(price)),
                    },
                };
            }
            return state;

        case REQUEST(ACTION_TYPES.CHANGE_ARCHIVE):
            return {
                ...state,
                loading: true,
            };
        case FAILURE(ACTION_TYPES.CHANGE_ARCHIVE):
            return {
                ...state,
                loading: false,
            };
        case SUCCESS(ACTION_TYPES.CHANGE_ARCHIVE):
            return {
                ...state,
                loading: !false,
            };
        case REQUEST(ACTION_TYPES.CHANGE_STATUS):
            return {
                ...state,
                loading: true,
            };
        case FAILURE(ACTION_TYPES.CHANGE_STATUS):
            return {
                ...state,
                loading: false,
            };
        case SUCCESS(ACTION_TYPES.CHANGE_STATUS):
            return {
                ...state,
                loading: !false,
            };

        case REQUEST(ACTION_TYPES.LOAD_ENTITIES):
            return {
                ...state,
                loadingError: null,
                loading: true,
            };
        case FAILURE(ACTION_TYPES.LOAD_ENTITIES):
            return {
                ...state,
                loadingError: action.payload,
                loading: false,
            };
        case SUCCESS(ACTION_TYPES.LOAD_ENTITIES):
            gridDataChangedSignal();

            return {
                ...state,
                loading: false,
                entities: (action.payload.data as RenterRecordList).records,
                filteredCount: (action.payload.data as RenterRecordList).listAttributes.filteredCount,
            };
        case changeCategoryBinding.pending.type:
            return {
                ...state,
                loadingError: null,
                loading: true,
            };
        case changeCategoryBinding.rejected.type:
            return {
                ...state,
                loadingError: action.payload,
                loading: false,
            };
        case changeCategoryBinding.fulfilled.type:
            return {
                ...state,
                loading: false,
                loadingError: null,
            };
        case ACTION_TYPES.RESET:
            return {
                ...initialState,
            };
        default:
            return state;
    }
};

export const getCounterpartiesListFilters = (params: RentersPageParams, subrentModuleEnabled: boolean | undefined) => {
    let filters: string[] = [];
    if (params.status) filters.push('stateCode;EQ;' + params.status);
    if (params.typeCode) filters.push('typeCode;EQ;' + params.typeCode);
    if (params.country) filters.push('country;EQ;' + params.country);
    if (params.hideArchive === true) filters.push('archive;EQ;false');
    if (params.problem) {
        let problem: string | undefined = params.problem;
        if (
            !subrentModuleEnabled &&
            (problem === ProblemEnum.SUBRENT_SHIPMENT_DELAY || problem === ProblemEnum.SUBRENT_RETURN_TO_SHIPPER_DELAY)
        )
            problem = undefined;
        if (problem) filters.push(getStringServerProblem(problem));
    }
    if (params.categoryIds !== undefined) filters.push('categoryIds;IN;' + params.categoryIds);
    if (params.lastActivityDate !== undefined && params.lastActivityDate[0] !== undefined)
        filters.push('lastActivityDate;GE;' + params.lastActivityDate[0]);
    if (params.lastActivityDate !== undefined && params.lastActivityDate[1] !== undefined)
        filters.push('lastActivityDate;LE;' + params.lastActivityDate[1]);
    if (subrentModuleEnabled && params.activityTypeCode) filters.push('activityTypeCode;EQ;' + params.activityTypeCode);
    if (params.sourceCode) filters.push('sourceCode;EQ;' + params.sourceCode);
    if (params.debtOfRenter) {
        if (params.debtOfRenter[0] != null) filters.push('debtOfRenter;GE;' + MoneyUtils.toCents(params.debtOfRenter[0]));
        if (params.debtOfRenter[1] != null) filters.push('debtOfRenter;LE;' + MoneyUtils.toCents(params.debtOfRenter[1]));
    }
    if (params.debtToSupplier) {
        if (params.debtToSupplier[0] != null) filters.push('debtToSupplier;GE;' + MoneyUtils.toCents(params.debtToSupplier[0]));
        if (params.debtToSupplier[1] != null) filters.push('debtToSupplier;LE;' + MoneyUtils.toCents(params.debtToSupplier[1]));
    }

    return filters;
};

export const loadEntities = (intl: IntlShape, businessAccountId: number) => {
    return (dispatch, getState) => {
        let state: RentersState = (getState() as IRootState).renters;
        const subrentModuleEnabled = subrentModuleEnabledSelector(getStoreState());
        let { params } = state;

        const filters = getCounterpartiesListFilters(params, subrentModuleEnabled);

        let page = params.page !== undefined ? params.page : initialParamsState.page || 1;
        let limit = params.limit !== undefined ? params.limit : initialParamsState.limit || 10;

        const sortBy = getAvailableGridSortById(
            params.sortBy === 'problemSeverity' ? 'problemsAndWarnings.severity' : params.sortBy,
            counterpartyColumns,
            ['problemsAndWarnings.severity'],
            initialParamsState.sortBy
        );

        return dispatch({
            type: ACTION_TYPES.LOAD_ENTITIES,
            payload: serverApi.listRenters(
                businessAccountId,
                params.limit,
                (page - 1) * limit,
                filters,
                sortBy,
                params.sortOrder,
                params.search,
                { query: { filters: filters } }
            ),
        }).then((result) => {
            if (result instanceof Error) {
                showNotification('error', localizeIntl(intl, LocalizationEnum.PAGE__RENTERS__POPUP_NOTIFICATIONS__GET_LIST_FAILED));
            } else {
            }
        });
    };
};

export const setArchiveState = (intl: IntlShape, businessAccountId: number, directives: Array<WorkflowDirective>) => {
    return (dispatch) => {
        return dispatch({
            type: ACTION_TYPES.CHANGE_ARCHIVE,
            payload: serverApi.transitRenterWorkflowBulk(businessAccountId, { directives: directives }),
        }).then((result) => {
            if (result instanceof Error) {
                // В архив не изменен
                showNotification('error', localizeIntl(intl, LocalizationEnum.ASPECT__GLOBAL__ERROR));
            } else {
                // В архив изменен
                showNotification(
                    'success',
                    localizeIntl(
                        intl,
                        directives.length === 1
                            ? LocalizationEnum.PAGE__RENTERS__POPUP_NOTIFICATIONS__RENTER_UPDATED
                            : LocalizationEnum.PAGE__RENTERS__POPUP_NOTIFICATIONS__RENTERS_UPDATED
                    )
                );
                dispatch(loadEntities(intl, businessAccountId));
            }
        });
    };
};

export const setStatus = (intl: IntlShape, businessAccountId: number, directives: Array<WorkflowDirective>) => {
    return (dispatch) => {
        return dispatch({
            type: ACTION_TYPES.CHANGE_STATUS,
            payload: serverApi.transitRenterWorkflowBulk(businessAccountId, { directives: directives }),
        }).then((result) => {
            if (result instanceof Error) {
                // Статус арендатора не изменен
                showNotification('error', localizeIntl(intl, LocalizationEnum.ASPECT__GLOBAL__ERROR));
            } else {
                // Статус арендатора изменен
                showNotification(
                    'success',
                    directives.length === 1
                        ? localizeIntl(intl, LocalizationEnum.PAGE__RENTERS__POPUP_NOTIFICATIONS__RENTER_STATE_UPDATED)
                        : localizeIntl(intl, LocalizationEnum.PAGE__RENTERS__POPUP_NOTIFICATIONS__RENTERS_STATE_UPDATED)
                );
                dispatch(loadEntities(intl, businessAccountId));
            }
        });
    };
};

export const reset = () => ({
    type: ACTION_TYPES.RESET,
});
