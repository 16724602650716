import {FAILURE, REQUEST, SUCCESS} from '../../../../../shared/reducers/action-type.util';
import {
    ProjectInfoCreate,
    ProjectInfoRead,
    ProjectInfoUpdate,
    ProjectTransitionCodeEnum,
    serverApi,
    ServerError
} from '../../../../../server';
import {push} from 'connected-react-router';
import {getServerError} from '../../../../../shared/util/utils';
import {showNotification} from '../../../../../components/notification/showNotification';
import {logEvent} from '../../../../../analytics/analytics';
import {LocalizationEnum, localizeIntl} from '../../../../../localization';

export const ACTION_TYPES = {
    LOAD_ENTITY: 'simpleOrder/LOAD_ENTITY',
    UPDATE_ENTITY: 'simpleOrder/UPDATE_ENTITY',
    CHANGE_ENTITY_STATUS: 'simpleOrder/CHANGE_ENTITY_STATUS',
    RESET: 'simpleOrder/RESET',
    RESET_ERROR: 'simpleOrder/RESET_ERROR',
    RESET_STATUS_WAS_CHANGED_FLAG: 'simpleOrder/RESET_STATUS_WAS_CHANGED_FLAG',
};

const initialState = {
    loading: false,
    loadingError: undefined as (undefined | ServerError),
    updating: false,
    updatingError: undefined as (undefined | ServerError),
    entity: null as (ProjectInfoRead | null),
    statusWasChangedFlag: false
};

export type SimpleOrderState = Readonly<typeof initialState>;

// Reducer

export default (state: SimpleOrderState = initialState, action): SimpleOrderState => {
    switch (action.type) {
        case REQUEST(ACTION_TYPES.LOAD_ENTITY):
            return {
                ...state,
                loadingError: undefined,
                loading: true
            };

        case FAILURE(ACTION_TYPES.LOAD_ENTITY):
            return {
                ...state,
                loadingError: getServerError(action.payload),
                loading: false
            };

        case SUCCESS(ACTION_TYPES.LOAD_ENTITY):

            return {
                ...state,
                entity: action.payload.data,
                loading: false
            };

        case REQUEST(ACTION_TYPES.UPDATE_ENTITY):
            return {
                ...state,
                updating: true,
                updatingError: undefined
            };

        case FAILURE(ACTION_TYPES.UPDATE_ENTITY):
            return {
                ...state,
                updating: false,
                updatingError: getServerError(action.payload)
            };

        case REQUEST(ACTION_TYPES.CHANGE_ENTITY_STATUS):
            return {
                ...state,
                loading: true
            };

        case FAILURE(ACTION_TYPES.CHANGE_ENTITY_STATUS):
            return {
                ...state,
                loading: false
            };

        case SUCCESS(ACTION_TYPES.UPDATE_ENTITY):
            return {
                ...state,
                updating: false
            };

        case SUCCESS(ACTION_TYPES.CHANGE_ENTITY_STATUS):
            return {
                ...state,
                loading: false,
                statusWasChangedFlag: true
            };

        case ACTION_TYPES.RESET:
            return {
                ...initialState
            };

        case ACTION_TYPES.RESET_ERROR:
            return {
                ...state,
                updatingError: undefined,
                loadingError: undefined
            };
        case ACTION_TYPES.RESET_STATUS_WAS_CHANGED_FLAG:
            return {
                ...state,
                statusWasChangedFlag: false
            };

        default:
            return state;
    }
};

// Actions

// Получение сущности
export const loadSimpleOrder = (businessAccountId: number, id: number) => ({
    type: ACTION_TYPES.LOAD_ENTITY,
    payload: serverApi.getProjectById(businessAccountId, id)
});

// Изменение сущности
export const updateEntity = (intl, businessAccountId: number, id: number, entity: ProjectInfoUpdate) => {
    return dispatch => {
        return dispatch({
            type: ACTION_TYPES.UPDATE_ENTITY,
            payload: serverApi.updateProjectById(businessAccountId, id, entity)
        }).then((result) => {
            if (result instanceof Error) {
                showNotification('error', 'Не удалось обновить заказ');
            } else {
                // Сущность обновлена успешно
                showNotification('success', 'Заказ изменен');
                dispatch(push('/' + businessAccountId + '/projects/simpleOrders/' + id)); //  + '?tab=description'
                dispatch(loadSimpleOrder(businessAccountId, id));
            }
        });
    };
};

export const convertToProject = (intl, businessAccountId: number, id: number) => {
    return dispatch => {
        return dispatch({
            type: ACTION_TYPES.UPDATE_ENTITY,
            payload: serverApi.convertOrderToProject(businessAccountId, id)
        }).then((result) => {
            if (result instanceof Error) {
                showNotification('error', 'Не удалось преобразовать заказ в проект');
            } else {
                // Сущность обновлена успешно
                showNotification('success', 'Заказ преобразован в проект');
                dispatch(push('/' + businessAccountId + '/projects/production/' + id)); //  + '?tab=description'
            }
        });
    };
};

// Изменение статуса
export const setStatusAndArchive = (intl, businessAccountId: number, id: number, businessVersion: number, transitionCode?: ProjectTransitionCodeEnum, archive?: boolean) => {
    return dispatch => {
        return dispatch({
            type: ACTION_TYPES.CHANGE_ENTITY_STATUS,
            payload: serverApi.transitProjectWorkflowBulk(businessAccountId, {
                directives: [{
                    id,
                    businessVersion,
                    transitionCode,
                    archive
                }]
            })
        }).then((result) => {
            if (result instanceof Error) {
                // Статус не изменен
                showNotification('error', localizeIntl(intl, LocalizationEnum.ASPECT__GLOBAL__ERROR)); // TODO Обработать ошибку от сервера, и выводить норм сообщение
            } else {
                if (archive === undefined) {
                    showNotification('success', 'Статус заказа изменен');
                } else {
                    showNotification('success', archive ? 'Заказ отправлен в архив' : 'Заказ возвращен из архива');
                }

                setTimeout(() => dispatch(loadSimpleOrder(businessAccountId, id)), 1500);
            }
        });
    };
};

export const reset = () => ({
    type: ACTION_TYPES.RESET
});

export const resetError = () => ({
    type: ACTION_TYPES.RESET_ERROR
});

export const resetStatusWasChangedFlag = () => ({
    type: ACTION_TYPES.RESET_STATUS_WAS_CHANGED_FLAG
});