import React, { FC, useMemo, useState } from 'react';
import Spin from '../../../../../../components/spin/spin';
import { calendarPageUrlRoute } from '../../../../calendar/utils/tabs';
import { Avatar } from '../../../../../../components/avatar/avatar';
import { Button, Card, Form, Icon } from 'antd';
import { LocalizationEnum, localize } from '../../../../../../localization';
import { Link, useLocation } from 'react-router-dom';
import { IconClose, IconHourglassEndSolid } from '../../../../../../components/icons';
import { useIntl } from 'react-intl';
import { useAppSelector } from '../../../../../../store/hooks';
import { businessAccountIdSelector } from '../../../../../../shared/reducers/system.reducer';
import { getPathFromState } from '../../../../../../shared/util/utils';
import { getOperationStatusTypeData } from '../../../../operationForm/utils/utils';
import { OperationTypeCodeEnum } from '../../../../../../server';
import { getGridStorageData, setGridStorageDataFilters } from '../../../../../../components/grid/utils';
import { useListDashboardQuery } from '../../../api/rent/dashboardApi';
import { DashboardData } from '../../../types/DashboardData';
import { DashboardList } from '../../dashboardsList';
import { dashboardRentColumnRight } from '../../../columns/dashboardColumns';
import { canViewFinancialDataSelector } from '../../../../../../shared/reducers/permissions.reducer';
import { PeriodSelect } from '../../../components/PeriodSelect/PeriodSelect';
import { getStylesForBtnTIme } from './utils/getStylesForBtnTIme';
import { useDashboardGridState } from '../../../hooks/useDashboardGridState';
import { DashboardApiUtils } from '../../../api/rent/dashboardApiUtils';
import { ServerUtils } from '../../../../../../core/utils/serverUtils';
import {CalendarPageTabsEnum} from "../../../../../../shared/constants/tabEnums";

interface DashboardRentRightListProps {
    dashboardData: DashboardData;
}

const dashboardRendRightGridName: string = 'dashboard-elements-rent';

export const DashboardRentRightList: FC<DashboardRentRightListProps> = (props) => {
    const { dashboardData } = props;
    const intl = useIntl();
    const location = useLocation();
    const businessAccountId = useAppSelector(businessAccountIdSelector);
    const showFinancialData = useAppSelector(canViewFinancialDataSelector);
    const gridData = getGridStorageData(dashboardRendRightGridName);
    const [isTimeButtonSelected, setIsTimeButtonSelected] = useState(gridData.filters?.isBtnTime === true);

    const [{ page, pageSize, period, sortBy, sortOrder }, { onPageChanged, onPeriodChange, onSetPeriod, onSortedChange }] =
        useDashboardGridState(dashboardRendRightGridName);

    const {
        currentDelayedRentedElementsCount,
        currentDelayedBookedElementsCount,
        currentRentedElementsCount: elementsCount,
    } = dashboardData;

    const requestFilters = useMemo(
        () => [
            ...DashboardApiUtils.getCommonFilters(period),
            ...ServerUtils.createRequestFilters([
                ['stateCode', 'EQ', 'RENT'],
                isTimeButtonSelected && currentDelayedBookedElementsCount && ['problemsAndWarnings.anyDelay', 'EQ', true],
            ]),
        ],
        [currentDelayedBookedElementsCount, isTimeButtonSelected, period]
    );

    const { data: dashboardListData, isLoading } = useListDashboardQuery(
        {
            intl,
            params: {
                pageSize,
                sortOrder,
                sortBy,
                page,
                period,
            },
            side: 'right',
            isSubrent: false,
            filters: requestFilters,
        },
        {
            refetchOnMountOrArgChange: true,
        }
    );

    const typeData = getOperationStatusTypeData(OperationTypeCodeEnum.RETURN);

    const onTimeFilter = () => {
        setGridStorageDataFilters(dashboardRendRightGridName, { isBtnTime: !isTimeButtonSelected });
        setIsTimeButtonSelected((prev) => !prev);
        onPeriodChange();
    };

    const stylesForBtnTime = getStylesForBtnTIme(isTimeButtonSelected, currentDelayedRentedElementsCount);

    return (
        <Spin spinning={isLoading}>
            <div className={`dashboard-grid-header ${period ? 'dashboard-header-input-select' : 'dashboard-header-input'}`}>
                <Link
                    to={getPathFromState(`/${businessAccountId}/${calendarPageUrlRoute}`, '', {
                        tab: CalendarPageTabsEnum.ELEMENTS,
                        typeCode: 'RENT',
                        group: 'endDate',
                        capture: 'all',
                    })}
                >
                    <div className="dashboard-header-avatar-button dashboard-header-avatar-button-right">
                        <Avatar
                            className={'rr-avatar-small-with-shadow rr-operation-avatar-RETURN'}
                            size={44}
                            style={{ boxShadow: '0px 0px 0px 5px rgba(52, 191, 163, 0.15)', marginRight: '20px' }}
                        >
                            <Icon component={typeData.iconComponent} />
                        </Avatar>
                        <div>
                            <div>
                                <strong style={{ fontSize: '20px' }}>{localize(LocalizationEnum.PAGE__DASHBOARD__RETURNS)}</strong>
                            </div>
                            <div>
                                {elementsCount} {localize(LocalizationEnum.ASPECT__GLOBAL__TOTAL_NUMBER)}
                            </div>
                        </div>
                    </div>
                </Link>
                <div style={{ marginTop: '12px' }} className="dashboard-filters-buttons">
                    <span>
                        <Button
                            type="danger"
                            style={stylesForBtnTime}
                            onClick={onTimeFilter}
                            disabled={currentDelayedRentedElementsCount === 0 && !isTimeButtonSelected}
                        >
                            <Icon component={IconHourglassEndSolid} style={{ fontSize: '20px' }} />
                            <span style={{ fontWeight: 600 }}>{currentDelayedRentedElementsCount}</span>
                            {isTimeButtonSelected ? <Icon component={IconClose} /> : null}
                        </Button>
                    </span>
                </div>
                <Form.Item style={{ marginRight: '30px', marginTop: '12px' }}>
                    <PeriodSelect period={period} onSetPeriod={onSetPeriod} />
                </Form.Item>
            </div>

            <Card bordered={false} className="grid-dashboard grid-dashboar-left">
                <DashboardList
                    businessAccountId={businessAccountId}
                    columns={dashboardRentColumnRight}
                    currentPage={page}
                    data={dashboardListData?.records}
                    defaultSortDesc={sortOrder}
                    defaultSorted={sortBy}
                    entities={dashboardListData?.records}
                    excludeColumns={showFinancialData ? undefined : ['finalTotalPrice']}
                    filtered={dashboardListData?.listAttributes.filteredCount}
                    gridName={dashboardRendRightGridName}
                    location={location}
                    onPageChanged={onPageChanged}
                    onSortedChange={onSortedChange}
                    parentType={'operation'}
                />
            </Card>
        </Spin>
    );
};
