import {
    BusinessAccountTypeCodeEnum, ContractValidationTypeCodeEnum,
    CounterpartyActivityTypeCodeEnum, PaymentMethodRequirementTypeCodeEnum,
    ProjectSourceCodeEnum,
    RenterSourceCodeEnum,
    RentIndustryCodeEnum,
    RentStateCodeEnum,
    ShiftCountRoundingTypeCodeEnum,
} from '../../server/api';
import {ALL_STATE_CODES, RENT_STATE_CODES, SUBRENT_STATE_CODES} from '../../types';
import {isDefined} from './utils';
import {LocalizationEnum, localize} from '../../localization';

export const getContractValidationTypeByCode = (code: ContractValidationTypeCodeEnum | undefined) => {
    // if (code === ContractValidationTypeCodeEnum.NOVALIDATION) return 'Отключен';
    // else if (code === ContractValidationTypeCodeEnum.ONLYFILE) return 'Только файл';
    // else if (code === ContractValidationTypeCodeEnum.ALLFIELDS) return 'Файл, номер и даты';
    // else return undefined;
    return code ? contractValidationTypesMap[code] : code;
};

export const contractValidationTypesAllTypes = [
    ContractValidationTypeCodeEnum.NOVALIDATION,
    ContractValidationTypeCodeEnum.ONLYFILE,
    ContractValidationTypeCodeEnum.ALLFIELDS,
];

export const contractValidationTypesMap: Record<ContractValidationTypeCodeEnum, string> = {
    [ContractValidationTypeCodeEnum.NOVALIDATION]: 'Отключен',
    [ContractValidationTypeCodeEnum.ONLYFILE]: 'Только файл',
    [ContractValidationTypeCodeEnum.ALLFIELDS]: 'Файл, номер и даты',
};

export const getShiftCountRoundingTypeByCode = (code: ShiftCountRoundingTypeCodeEnum | undefined) => {
    if (code === ShiftCountRoundingTypeCodeEnum.ROUNDHALFUP) return 'К ближайшему целому';
    else if (code === ShiftCountRoundingTypeCodeEnum.FRACTIONSECONDSIGNROUNDHALFUP) return 'До второго знака после запятой';
    else if (code === ShiftCountRoundingTypeCodeEnum.DOWN) return 'В меньшую сторону';
    else if (code === ShiftCountRoundingTypeCodeEnum.UP) return 'В большую сторону';
    else return undefined;
};

export const getCounterpartyActivityTypeByCode = (code: CounterpartyActivityTypeCodeEnum | undefined) => {
    if (code === CounterpartyActivityTypeCodeEnum.RENTERONLY) return 'Арендатор';
    else if (code === CounterpartyActivityTypeCodeEnum.SUPPLIERONLY) return 'Поставщик';
    else if (code === CounterpartyActivityTypeCodeEnum.RENTERANDSUPPLIER) return 'Арендатор и поставщик';
    else return undefined;
};

export const getCounterpartySourceByCode = (code: RenterSourceCodeEnum | undefined) => {
    if (code === RenterSourceCodeEnum.STOREFRONT) return 'Витрина';
    else if (code === RenterSourceCodeEnum.INTERNAL) return 'Система';
    else return undefined;
};

export const getProjectSourceByCode = (code: ProjectSourceCodeEnum | undefined) => {
    if (code === ProjectSourceCodeEnum.STOREFRONT) return 'Витрина';
    else if (code === ProjectSourceCodeEnum.INTERNAL) return 'Система';
    else return undefined;
};

export const getBusinessAccountTypeByCode = (code: BusinessAccountTypeCodeEnum | undefined) => {
    if (code === BusinessAccountTypeCodeEnum.TRIAL) return 'Триал';
    else if (code === BusinessAccountTypeCodeEnum.DEMOTEMPLATE) return 'Демо шаблон';
    else if (code === BusinessAccountTypeCodeEnum.SANDBOX) return 'Песочница';
    else if (code === BusinessAccountTypeCodeEnum.PRODUCTION) return 'Коммерческий';
    else return undefined;
};

export const getRentIndustryByCode = (code: RentIndustryCodeEnum | undefined) => {
    if (code === RentIndustryCodeEnum.AV) return localize(LocalizationEnum.PAGE__COMPANY_REGISTRATION__INDUSTRY_FIELD_OPTION__AV);
    else if (code === RentIndustryCodeEnum.HR) return localize(LocalizationEnum.PAGE__COMPANY_REGISTRATION__INDUSTRY_FIELD_OPTION__HR);
    else if (code === RentIndustryCodeEnum.CLOTHES)
        return localize(LocalizationEnum.PAGE__COMPANY_REGISTRATION__INDUSTRY_FIELD_OPTION__CLOTHES);
    else if (code === RentIndustryCodeEnum.EVENTS)
        return localize(LocalizationEnum.PAGE__COMPANY_REGISTRATION__INDUSTRY_FIELD_OPTION__EVENTS);
    else if (code === RentIndustryCodeEnum.CONSTRUCTION)
        return localize(LocalizationEnum.PAGE__COMPANY_REGISTRATION__INDUSTRY_FIELD_OPTION__CONSTRUCTION);
    else if (code === RentIndustryCodeEnum.PROPS)
        return localize(LocalizationEnum.PAGE__COMPANY_REGISTRATION__INDUSTRY_FIELD_OPTION__PROPS);
    else if (code === RentIndustryCodeEnum.SPORT)
        return localize(LocalizationEnum.PAGE__COMPANY_REGISTRATION__INDUSTRY_FIELD_OPTION__SPORT_INVENTORY);
    else if (code === RentIndustryCodeEnum.TRANSPORT)
        return localize(LocalizationEnum.PAGE__COMPANY_REGISTRATION__INDUSTRY_FIELD_OPTION__TRANSPORT);
    else if (code === RentIndustryCodeEnum.OTHER)
        return localize(LocalizationEnum.PAGE__COMPANY_REGISTRATION__INDUSTRY_FIELD_OPTION__OTHER);
    else return undefined;
    /*
{name: localize(LocalizationEnum.PAGE__COMPANY_REGISTRATION__INDUSTRY_FIELD_OPTION__AV), value: RentIndustryCodeEnum.AV},
{name: localize(LocalizationEnum.PAGE__COMPANY_REGISTRATION__INDUSTRY_FIELD_OPTION__HR), value: RentIndustryCodeEnum.HR},
{name: localize(LocalizationEnum.PAGE__COMPANY_REGISTRATION__INDUSTRY_FIELD_OPTION__CLOTHES), value: RentIndustryCodeEnum.CLOTHES},
{name: localize(LocalizationEnum.PAGE__COMPANY_REGISTRATION__INDUSTRY_FIELD_OPTION__EVENTS), value: RentIndustryCodeEnum.EVENTS},
{name: localize(LocalizationEnum.PAGE__COMPANY_REGISTRATION__INDUSTRY_FIELD_OPTION__CONSTRUCTION), value: RentIndustryCodeEnum.CONSTRUCTION},
{name: localize(LocalizationEnum.PAGE__COMPANY_REGISTRATION__INDUSTRY_FIELD_OPTION__PROPS), value: RentIndustryCodeEnum.PROPS},
{name: localize(LocalizationEnum.PAGE__COMPANY_REGISTRATION__INDUSTRY_FIELD_OPTION__SPORT_INVENTORY), value: RentIndustryCodeEnum.SPORT},
{name: localize(LocalizationEnum.PAGE__COMPANY_REGISTRATION__INDUSTRY_FIELD_OPTION__TRANSPORT), value: RentIndustryCodeEnum.TRANSPORT},
{name: localize(LocalizationEnum.PAGE__COMPANY_REGISTRATION__INDUSTRY_FIELD_OPTION__OTHER), value: RentIndustryCodeEnum.OTHER}
    * */

    // if(code === BusinessAccountTypeCodeEnum.TRIAL) return 'Триал';
    // else if(code === BusinessAccountTypeCodeEnum.DEMOTEMPLATE) return 'Демо шаблон';
    // else if(code === BusinessAccountTypeCodeEnum.SANDBOX) return 'Песочница';
    // else if(code === BusinessAccountTypeCodeEnum.PRODUCTION) return 'Коммерческий';
    // else return undefined;
};

export const getHideStateCodeFilterTypes = (code: string | undefined): RentStateCodeEnum[] => {
    let res: RentStateCodeEnum[] = [];
    if (code === 'inactive') {
        const codes = ALL_STATE_CODES.filter(
            (code) =>
                ![
                    RentStateCodeEnum.RENT,
                    RentStateCodeEnum.ORDERED,
                    RentStateCodeEnum.BOOKED,
                    RentStateCodeEnum.SUBRENTSHIPMENTBOOKED,
                    RentStateCodeEnum.SUBRENT,
                    RentStateCodeEnum.MIXED,
                ].includes(code)
        );
        res = [...codes];
    } else if (code === 'cancelled') {
        const codes = [RentStateCodeEnum.CANCELED, RentStateCodeEnum.SUBRENTCANCELED];
        res = [...codes];
    } else if (code === 'cancelled+draft') {
        const codes = [
            RentStateCodeEnum.CANCELED,
            RentStateCodeEnum.SUBRENTCANCELED,
            RentStateCodeEnum.DRAFT,
            RentStateCodeEnum.SUBRENTDRAFT,
        ];
        res = [...codes];
    } else if (code === 'rent') {
        res = [...RENT_STATE_CODES];
    } else if (code === 'subrent') {
        res = [...SUBRENT_STATE_CODES];
    }
    return res;
};

type HideStateCodeType = 'inactive'|'cancelled'|'cancelled+draft'|'rent'|'subrent';

export const getHideStateCodeFilterStateCodes = (code:HideStateCodeType) => {
    if (code === 'inactive') {
        const codes = ALL_STATE_CODES.filter(
            (code) =>
                ![
                    RentStateCodeEnum.RENT,
                    RentStateCodeEnum.ORDERED,
                    RentStateCodeEnum.BOOKED,
                    RentStateCodeEnum.SUBRENTSHIPMENTBOOKED,
                    RentStateCodeEnum.SUBRENT,
                    RentStateCodeEnum.MIXED,
                ].includes(code)
        );
        return codes;
    } else if (code === 'cancelled') {
        const codes = [RentStateCodeEnum.CANCELED, RentStateCodeEnum.SUBRENTCANCELED];
        return codes;
    } else if (code === 'cancelled+draft') {
        const codes = [
            RentStateCodeEnum.CANCELED,
            RentStateCodeEnum.SUBRENTCANCELED,
            RentStateCodeEnum.DRAFT,
            RentStateCodeEnum.SUBRENTDRAFT,
        ];
        return codes;
    } else if (code === 'rent') {
        return [...RENT_STATE_CODES];
    } else if (code === 'subrent') {
        return [...SUBRENT_STATE_CODES];
    }
};

export const getHideStateCodeFilterString = (code: string | undefined): string => {
    let str = '';
    if (code === 'inactive') {
        const codes = ALL_STATE_CODES.filter(
            (code) =>
                ![
                    RentStateCodeEnum.RENT,
                    RentStateCodeEnum.ORDERED,
                    RentStateCodeEnum.BOOKED,
                    RentStateCodeEnum.SUBRENTSHIPMENTBOOKED,
                    RentStateCodeEnum.SUBRENT,
                    RentStateCodeEnum.MIXED,
                ].includes(code)
        );
        str = codes.join(';');
    } else if (code === 'cancelled') {
        const codes = [RentStateCodeEnum.CANCELED, RentStateCodeEnum.SUBRENTCANCELED];
        str = codes.join(';');
    } else if (code === 'cancelled+draft') {
        const codes = [
            RentStateCodeEnum.CANCELED,
            RentStateCodeEnum.SUBRENTCANCELED,
            RentStateCodeEnum.DRAFT,
            RentStateCodeEnum.SUBRENTDRAFT,
        ];
        str = codes.join(';');
    } else if (code === 'rent') {
        str = RENT_STATE_CODES.join(';');
    } else if (code === 'subrent') {
        str = SUBRENT_STATE_CODES.join(';');
    }
    if (str) str = `hideStateCode;IN;${str}`;
    return str;
};

export const formatShiftCount = (value: number | undefined): string => {
    return isDefined(value) ? '' + value : '';
};

export const getAllStatusesExceptSelected = <T extends Object, Selected extends T[keyof T]>(
    _enum: T,
    status: Selected | Selected[]
): Selected[] => {
    const filters: (typeof status)[] = [];
    for (let key in _enum) {
        const value = _enum[key] as Selected;
        if (Array.isArray(status)) {
            if (!status.includes(value)) filters.push(value as typeof status);
        } else {
            if (value !== status) filters.push(value as typeof status);
        }
    }

    return filters as Selected[];
};

export const getEnumValues = <T extends Object>(_enum: T): T[keyof T][] => {
    return (Object.keys(_enum) as T[keyof T][]).map((key) => _enum[key as keyof T]);
};
