import React, {CSSProperties, ReactNode} from 'react';
import { ActionButton, ActionButtonActionType, ActionButtonType, useActionButton } from '../actionButton/actionButton';
import { ActionButtonsPopover } from '../actionButtonsPopover/actionButtonsPopover';

export type ActionButtonsData<T extends string> = Record<T, ActionButtonType>;

export type Action<T> = { type: T; disabled?: boolean, tooltip?: ReactNode } | T | undefined | null | false;

export interface ActionButtonsGroupProps<T extends string> {
    data: ActionButtonsData<T>;
    mainActions?: Action<T>[];
    additionalActions?: Action<T>[];
    onAction?: (action: T) => void;
    disabled?: boolean;
}

export const ActionButtonsGroup = <T extends string>(props: ActionButtonsGroupProps<T>) => {
    let { mainActions, additionalActions, onAction, data, disabled: isGroupDisabled } = props;
    const createActionButton = useActionButton();

    mainActions = mainActions?.filter((action) => action);
    additionalActions = additionalActions?.filter((action) => action);

    return (
        <>
            {[
                ...(mainActions?.map((action, index, arr) => {
                    if (action) {
                        const type = typeof action === 'object' ? action.type : action;
                        const disabled = isGroupDisabled || (typeof action === 'object' ? action.disabled : undefined);
                        const tooltip = (typeof action === 'object' ? action.tooltip : undefined);
                        const style: CSSProperties | undefined =
                            index < arr.length - 1 || (index === arr.length - 1 && additionalActions && additionalActions.length > 0)
                                ? { marginRight: 20 }
                                : undefined;

                        return (
                            data &&
                            createActionButton({
                                data: data,
                                type: type,
                                onClick: () => {
                                    onAction?.(type);
                                },
                                style: style,
                                disabled,
                                tooltip
                            })
                        );
                    }
                }) || []),

                additionalActions && additionalActions.length > 0 && (
                    <ActionButtonsPopover
                        data={data}
                        actions={additionalActions}
                        onAction={(action) => {
                            onAction?.(action);
                        }}
                    >
                        <ActionButton type={ActionButtonActionType.OTHER} disabled={isGroupDisabled} />
                    </ActionButtonsPopover>
                ),
            ]}
        </>
    );
};
