import { FAILURE, REQUEST, SUCCESS } from '../../../../../shared/reducers/action-type.util';
import { serverApi, VariantRecord, VariantRecordList, VersionedEntityObj, WorkflowDirective } from '../../../../../server';
import { showNotification } from '../../../../../components/notification/showNotification';
import { IRootState } from '../../../../../shared/reducers';
import { LOCATION_CHANGE } from 'connected-react-router';
import { convertAvailableFiltersToObj, getStateFromPath2, test1 } from '../../../../../shared/util/utils';
import { LocalizationEnum, localizeIntl } from '../../../../../localization';
import { loadEntity } from '../../products/reducers/product.reducer';
import { loadEntities as loadInstances } from '../../instances/reducers/product-instances.reducer';
import { MoneyUtils } from '../../../../../core/utils/moneyUtils';
import { gridDataChangedSignal } from '../../../../../components/controllers/scroll/SelectScrollController/SelectScrollController';
import { ProductPageTabsEnum } from '../../../../../shared/constants/tabEnums';

export const ACTION_TYPES = {
    LOAD_ENTITIES: 'productVariants/LOAD_ENTITIES',
    DELETE_ENTITIES: 'productVariants/DELETE_ENTITIES',
    RESET: 'productVariants/RESET',
    RESET_VARIANTS: 'productVariants/RESET_VARIANTS',
    CHANGE_ARCHIVE: 'productVariants/CHANGE_ARCHIVE',
    REMOVE_CUSTOM_FIELD: 'productVariants/REMOVE_CUSTOM_FIELD',
    ADD_CUSTOM_FIELD: 'productVariants/ADD_CUSTOM_FIELD',
    SET_LOADING: 'productVariants/SET_LOADING',
    SET_VARIANTS_TO_UPDATE_LIST: 'productVariants/SET_VARIANTS_TO_UPDATE_LIST',
    LOAD_AVAILABLE_FILTERS: 'productVariants/LOAD_AVAILABLE_FILTERS',
};

export const initialParamsState = {
    search: undefined as string | undefined,
    sortBy: undefined as string | undefined,
    sortOrder: undefined as 'ASC' | 'DESC' | undefined,
    status: undefined as string | undefined,
    page: 1,
    limit: 10,
    hideArchive: false as boolean | undefined,
    finalTotalPrice: undefined as number[] | undefined,
    mode: undefined as string | undefined,
    id: undefined as number | undefined,
    availableFilters: undefined as Record<string, [number, number]> | undefined,
};

const initialState = {
    loading: true,
    loadingError: null,
    entities: null as Array<VariantRecord> | null,
    filteredCount: 0,
    params: {
        ...initialParamsState,
    },
    needsToupdateEntities: false,
    availableFilters: undefined as Record<string, [number, number]> | undefined,
};

export type ProductVariantsState = Readonly<typeof initialState>;

// Reducer

export default (state: ProductVariantsState = initialState, action): ProductVariantsState => {
    switch (action.type) {
        case LOCATION_CHANGE:
            let pathName =
                action.payload && action.payload.location && action.payload.location.pathname ? action.payload.location.pathname : '';
            let params = getStateFromPath2(action.payload.location.search);

            if (/\/\d+\/inventory\/products\/(\d+)/.test(pathName) && params.tab === ProductPageTabsEnum.VARIANTS) {
                return {
                    ...state,
                    params: {
                        ...state.params,
                        search: params.search !== undefined ? '' + params.search : initialParamsState.search,
                        sortBy: params.sortBy !== undefined ? params.sortBy : initialParamsState.sortBy,
                        sortOrder: params.sortOrder !== undefined ? params.sortOrder : initialParamsState.sortOrder,
                        status: params.status !== undefined ? params.status : initialParamsState.status,
                        page: params.page !== undefined ? +params.page : initialParamsState.page,
                        limit: params.limit !== undefined ? +params.limit : initialParamsState.limit,
                        hideArchive: params.hideArchive !== undefined ? params.hideArchive : initialParamsState.hideArchive,
                        finalTotalPrice: test1(params.finalTotalPrice, initialParamsState.finalTotalPrice)?.map((price) =>
                            Math.round(price)
                        ),
                        mode: params.mode !== undefined ? params.mode : initialParamsState.mode,
                        id: params.id !== undefined ? params.id : initialParamsState.id,
                    },
                };
            }
            return state;

        case REQUEST(ACTION_TYPES.CHANGE_ARCHIVE):
            return {
                ...state,
                loading: true,
            };
        case FAILURE(ACTION_TYPES.CHANGE_ARCHIVE):
            return {
                ...state,
                loading: false,
            };
        case SUCCESS(ACTION_TYPES.CHANGE_ARCHIVE):
            return {
                ...state,
                loading: !false,
            };
        case REQUEST(ACTION_TYPES.LOAD_ENTITIES):
            return {
                ...state,
                loadingError: null,
                loading: true,
                needsToupdateEntities: false,
            };
        case FAILURE(ACTION_TYPES.LOAD_ENTITIES):
            return {
                ...state,
                loadingError: action.payload,
                loading: false,
            };
        case SUCCESS(ACTION_TYPES.LOAD_ENTITIES):
            gridDataChangedSignal();

            return {
                ...state,
                loading: false,
                entities: (action.payload.data as VariantRecordList).records,
                filteredCount: (action.payload.data as VariantRecordList).listAttributes.filteredCount,
            };
        case ACTION_TYPES.RESET:
            return {
                ...initialState,
            };

        case REQUEST(ACTION_TYPES.REMOVE_CUSTOM_FIELD):
            return {
                ...state,
                loading: true,
            };
        case FAILURE(ACTION_TYPES.REMOVE_CUSTOM_FIELD):
            return {
                ...state,
                loading: false,
            };
        case SUCCESS(ACTION_TYPES.REMOVE_CUSTOM_FIELD):
            return {
                ...state,
                loading: true,
            };
        case REQUEST(ACTION_TYPES.ADD_CUSTOM_FIELD):
            return {
                ...state,
                loading: true,
            };
        case FAILURE(ACTION_TYPES.ADD_CUSTOM_FIELD):
            return {
                ...state,
                loading: false,
            };
        case SUCCESS(ACTION_TYPES.ADD_CUSTOM_FIELD):
            return {
                ...state,
                loading: true,
            };

        case ACTION_TYPES.SET_LOADING:
            return {
                ...state,
                loading: action.payload,
            };

        case ACTION_TYPES.RESET_VARIANTS:
            return {
                ...state,
                entities: null,
            };

        case ACTION_TYPES.SET_VARIANTS_TO_UPDATE_LIST:
            return {
                ...state,
                needsToupdateEntities: true,
            };

        case REQUEST(ACTION_TYPES.DELETE_ENTITIES):
            return {
                ...state,
                loading: true,
            };
        case FAILURE(ACTION_TYPES.DELETE_ENTITIES):
            return {
                ...state,
                loading: false,
            };
        case SUCCESS(ACTION_TYPES.DELETE_ENTITIES):
            return {
                ...state,
                loading: false,
            };

        case REQUEST(ACTION_TYPES.LOAD_AVAILABLE_FILTERS):
            return {
                ...state,
                loading: true,
            };
        case FAILURE(ACTION_TYPES.LOAD_AVAILABLE_FILTERS):
            return {
                ...state,
                loading: false,
            };
        case SUCCESS(ACTION_TYPES.LOAD_AVAILABLE_FILTERS):
            return {
                ...state,
                loading: false,
                availableFilters: convertAvailableFiltersToObj(action.payload.data.filters),
            };

        default:
            return state;
    }
};

// Actions

export const getProductVariantsFilters = (params: typeof initialParamsState): string[] => {
    let filters: string[] = [];
    if (params.hideArchive === true) filters.push('archive;EQ;false');

    if (params.finalTotalPrice) {
        if (params.finalTotalPrice[0] !== undefined) filters.push('pricePerShift;GE;' + MoneyUtils.toCents(params.finalTotalPrice[0]));
        if (params.finalTotalPrice[1] !== undefined) filters.push('pricePerShift;LE;' + MoneyUtils.toCents(params.finalTotalPrice[1]));
    }
    return filters;
};

export const loadEntities = (intl, businessAccountId: number, productId: number) => {
    return (dispatch, getState) => {
        let state: ProductVariantsState = (getState() as IRootState).productVariants;
        let params = state.params;

        let filters = getProductVariantsFilters(params);
        const sortBy = params.sortBy === 'problemSeverity' ? 'problemsAndWarnings.severity' : params.sortBy;
        return dispatch({
            type: ACTION_TYPES.LOAD_ENTITIES,
            payload: serverApi.listProductVariants(
                businessAccountId,
                productId,
                params.limit,
                (params.page - 1) * params.limit,
                undefined,
                sortBy,
                params.sortOrder,
                params.search,
                { query: { filters: filters } }
            ),
        }).then((result) => {
            if (result instanceof Error) {
                showNotification('error', localizeIntl(intl, LocalizationEnum.PAGE__VARIANTS__POPUP_NOTIFICATIONS__GET_LIST_FAILED));
            }
        });
    };
};

export const loadAvailableFilters = (businessAccountId: number, productId: number) => {
    return (dispatch) => {
        return dispatch({
            type: ACTION_TYPES.LOAD_AVAILABLE_FILTERS,
            payload: serverApi.listProductVariantsAvailableFiltersValues(businessAccountId, productId),
        }).then((result) => {
            if (result instanceof Error) {
            } else {
                return result;
            }
        });
    };
};

export const setArchiveState = (intl, businessAccountId: number, productId: number, directives: Array<WorkflowDirective>) => {
    return (dispatch) => {
        return dispatch({
            type: ACTION_TYPES.CHANGE_ARCHIVE,
            payload: serverApi.transitProductVariantWorkflowBulk(businessAccountId, productId, { directives: directives }), // TODO Тут должен быть метод для Продуктов, а не для Арендаторов
        }).then((result) => {
            if (result instanceof Error) {
                // В архив не изменен
                showNotification('error', localizeIntl(intl, LocalizationEnum.ASPECT__GLOBAL__ERROR));
            } else {
                // В архив изменен
                showNotification(
                    'success',
                    localizeIntl(
                        intl,
                        directives.length === 1
                            ? LocalizationEnum.PAGE__VARIANTS__POPUP_NOTIFICATIONS__VARIANT_UPDATED
                            : LocalizationEnum.PAGE__VARIANTS__POPUP_NOTIFICATIONS__VARIANTS_UPDATED
                    )
                );
                setTimeout(() => dispatch(loadEntities(intl, businessAccountId, productId)), 1500);
            }
        });
    };
};

export const removeCustomField = (intl, businessAccountId: number, productId: number, id: number) => {
    return (dispatch) => {
        return dispatch({
            type: ACTION_TYPES.REMOVE_CUSTOM_FIELD,
            payload: serverApi.deleteCustomFieldLinksToProductVariants(businessAccountId, productId, id),
        }).then((result) => {
            if (result instanceof Error) {
                // Поле не удалено
                showNotification('error', localizeIntl(intl, LocalizationEnum.ASPECT__GLOBAL__ERROR));
            } else {
                // Поле удалено
                setTimeout(async () => {
                    try {
                        await dispatch(loadEntity(businessAccountId, productId));
                        dispatch(loadEntities(intl, businessAccountId, productId));
                    } catch (e) {}
                }, 1500);
            }
        });
    };
};

export const addCustomField = (intl, businessAccountId: number, productId: number, ids: number[]) => {
    return (dispatch) => {
        return dispatch({
            type: ACTION_TYPES.ADD_CUSTOM_FIELD,
            payload: serverApi.createCustomFieldLinksToProductVariants(businessAccountId, productId, {
                objects: ids.map((id) => ({ customFieldId: id, values: [] })),
            }),
        }).then((result) => {
            if (result instanceof Error) {
                // Поле не добавлено
                showNotification('error', localizeIntl(intl, LocalizationEnum.ASPECT__GLOBAL__ERROR));
            } else {
                // Поле добавлено
                setTimeout(async () => {
                    try {
                        await dispatch(loadEntity(businessAccountId, productId));
                        dispatch(loadEntities(intl, businessAccountId, productId));
                    } catch (e) {}
                }, 1500);
            }
        });
    };
};

export const reset = () => ({
    type: ACTION_TYPES.RESET,
});

export const setLoading = (loading: boolean) => ({
    type: ACTION_TYPES.SET_LOADING,
    payload: loading,
});

export const resetVariants = () => ({
    type: ACTION_TYPES.RESET_VARIANTS,
});

export const setVariantsToUpdateList = () => ({
    type: ACTION_TYPES.SET_VARIANTS_TO_UPDATE_LIST,
});

export const deleteEntities = (
    intl,
    businessAccountId: number,
    productId: number,
    variants: VersionedEntityObj[],
    onSuccessCallback?: (variantsId: number[]) => void
) => {
    return (dispatch) => {
        return dispatch({
            type: ACTION_TYPES.DELETE_ENTITIES,
            payload: serverApi.deleteProductVariantsBulk(businessAccountId, productId, { entities: variants }),
        }).then((result) => {
            if (result instanceof Error) {
                // не Удалены
                showNotification('error', localizeIntl(intl, LocalizationEnum.ASPECT__GLOBAL__ERROR));
            } else {
                // Удалены
                showNotification('success', variants.length === 1 ? 'Вариант удален' : 'Варианты удалены');
                dispatch(loadEntities(intl, businessAccountId, productId));
                dispatch(loadInstances(intl, businessAccountId, productId));
                dispatch(loadEntity(businessAccountId, productId));
                if (onSuccessCallback) onSuccessCallback(variants.map(({ id }) => id));
            }
        });
    };
};
