import React, {FC} from 'react';
import {Picker} from "../picker/Picker";
import {CounterpartySelectAndCreate} from "../../../../../../components/v2/select/counterpartySelect/counterpartySelectAndCreate";
import {Moment} from "moment";
import {RangePickerProps} from "../../../../../../components/v2/calendar/rangePicker/rangePicker";
import {LocalizationEnum} from "../../../../../../localization";
import {useAppSelector} from "../../../../../../store/hooks";
import {canViewFinancialDataSelector} from "../../../../../../shared/reducers/permissions.reducer";
import {DataDisplayState} from "../../../../../../components/dataDisplay";
import {LabelValue} from "../labelValue/labelValue";
import {LabelValueTable} from "../labelValue/labelValueTable";
import {CounterpartyActivityTypeCodeEnum, OperationTypeCodeEnum, ProjectInfoRead, RenterStateCodeEnum} from "../../../../../../server";
import {EditOperationButton} from "../../../../elements/editOperationButton";
import './simpleOrderPageTopBlock.less';
import {useLocalize} from "../../../../../../core/hooks/useLocalize";

interface SimpleOrderPageTopBlockProps {
    dates: [Moment, Moment];
    onDatesChanged: RangePickerProps['onChange'];
    renterId?: number;
    orderId?: number;
    order?: ProjectInfoRead;
    onRenterChange: (id:number, name:string|undefined, discount:number|undefined) => void;
    totalPrice: number;
    debtSum: number;
}

export const SimpleOrderPageTopBlock: FC<SimpleOrderPageTopBlockProps> = (props) => {

    const L = useLocalize();
    const canViewFinancialData = useAppSelector(canViewFinancialDataSelector);
    const typeCode = useAppSelector((state) => state.operationForm.typeCode);

    const controlsDisabled = !Boolean(typeCode) || typeCode !== OperationTypeCodeEnum.EDIT;

    return (
        <div style={{display: 'flex', flexWrap: 'wrap', marginBottom: 36}} className={'rr-simpleOrderPage-topBlock'}>
            <div style={{marginRight: 60}}>
                <LabelValue label={'Период аренды'}>
                    <Picker disabled={controlsDisabled} value={props.dates} onChange={props.onDatesChanged}/>
                </LabelValue>
            </div>
            <div>
                <LabelValue label={'Арендатор'}>
                    <CounterpartySelectAndCreate
                        counterpartyType={'renter'}
                        filters={{
                            activityTypeCode: [CounterpartyActivityTypeCodeEnum.RENTERONLY, CounterpartyActivityTypeCodeEnum.RENTERANDSUPPLIER]
                        }}
                        disabled={controlsDisabled}
                        allowClear={false}
                        value={props.renterId}
                        placeholder={'Не выбран'}
                        style={{minWidth: 200}}
                        className={'rr-select-gray'}
                        onDataChange={(record) => {
                            if (record && record[0]) {
                                props.onRenterChange(record[0].id, record[0].shortName, record[0].defaultDiscount);
                            }
                        }}
                        onChange={(value)=>{
                            props.onRenterChange(value as number, undefined, undefined);
                        }}
                    />
                </LabelValue>
            </div>
            {
                props.orderId && (!typeCode || (typeCode && typeCode !== OperationTypeCodeEnum.EDIT) ) && (
                    <div style={{marginLeft: 60}}>
                        <LabelValue label={<span>&nbsp;</span>}>
                            {/*{<ElementsList3 parentType={'project'} parentId={props.orderId}/>}*/}
                            {<EditOperationButton order={props.order} parentType={'project'} parentId={props.orderId} />}
                        </LabelValue>
                    </div>
                )
            }
            {
                canViewFinancialData && (
                    <div style={{flexGrow: 1, display: 'flex', alignItems: 'center', justifyContent: 'end'}}>
                        <LabelValueTable data={[
                            {
                                label: L(LocalizationEnum.ASPECT__GLOBAL__IN_TOTAL),
                                value: props.totalPrice
                            },
                            props.orderId && {
                                label: L(LocalizationEnum.PAGE__PROJECTS__CARD__DEBT_SUM),
                                value: props.debtSum,
                                state: props.debtSum === 0 ? DataDisplayState.Success : DataDisplayState.Problem
                            },
                        ]}/>
                    </div>
                )
            }
        </div>
    );
};
