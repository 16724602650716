import React from 'react';
import {Alert, Button, Card, Checkbox, Col, Icon, Input, Modal, notification, Radio, Row, Select, Switch, TimePicker, Tooltip,} from 'antd';
import moment from 'moment';
import {IconFileZipO} from '../../../../components/icons/IconFileZipO';
import {SearchInput} from '../../../../components/search/search.component';
import {CustomSelect} from '../../../../components/select/CustomSelect';
import {IconBriefcase, IconCashRegisterSolid, IconDownloadSolid2, IconEdit, IconLevelUp} from '../../../../components/icons';
import RoundButton from '../../../../components/button/roundButton';
import {showNotification} from '../../../../components/notification/showNotification';
import {EntityRemoteSelect} from '../../../../components/select/EntityRemoteSelect';
import './components-page.less';
import {IRootState} from '../../../../shared/reducers';
import {connect, DispatchProp} from 'react-redux';
import {BasePage, IBasePageProps} from '../../../../components/page/BasePage';
import 'rc-trigger/assets/index.css';
import {RangePickerValue} from 'antd/lib/date-picker/interface';
import {DatePicker, RangePicker} from '../../../../components';
import {Link} from 'react-router-dom';
import {SliderRange} from '../../../../components/slider/SliderRange/SliderRange';
import axios from 'axios';
import {getUserAccessToken} from '../../../../../index';
import userManager from '../../../../config/userManager';
import {Avatar} from '../../../../components/avatar/avatar';
import {RecalculateShiftsCountButton} from '../../operationForm/RecalculateShiftsCountButton';
import {TimeRangePicker} from '../../../../components/timeRangePicker/timeRangePicker';
import {SystemIcon, SystemIconType} from '../../../../components/v2/systemIcon/systemIcon';
import {AddButton} from '../../../../components/buttons/addButton/addButton';
import {CreateEntityButton} from '../../../../components/buttons/EntityButton/v1/CreateEntityButton';
import {LinkButton} from '../../../../components/buttons/linkButton/linkButton';
import {AttachmentIcon} from '../../attachments/attachmentIcon';
import {DocumentTypeCodeEnum, FileTypeCodeEnum} from '../../../../server';
import {DownloadDocumentButton} from '../../../../components/buttons/downloadDocumentButton/downloadDocumentButton';
import {NumberInputDiscount} from '../../../../components/numberInputs/numberInputDiscount';
import {NumberInput} from '../../../../components/numberInputs/numberInput';
import {EditButton} from "../../../../components/buttons/editButton/editButton";
import {AddButton2} from "../../../../components/buttons/addButton2/addButton2";
import {LinkButton2} from "../../../../components/buttons/linkButton2/linkButton2";
import {LocalizationEnum, localize} from "../../../../localization";

const dateFormat = 'DD.MM.YYYY';

const Search = Input.Search;
const RadioGroup = Radio.Group;

interface IState {
    modalOpened1: boolean;
    modalOpened2: boolean;
    isPopoverOpen: boolean;

    selectedDates?: RangePickerValue;
}

interface IProps extends StateProps, DispatchProp, IBasePageProps {}

function generateOptions(length, disabledOptions, hideDisabledOptions) {
    var step = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 1;
    var arr: any = [];

    for (var value = 0; value < length; value += step) {
        if (!disabledOptions || disabledOptions.indexOf(value) < 0 || !hideDisabledOptions) {
            arr.push(value);
        }
    }

    return arr;
}

class ComponentsModule extends BasePage<IProps, IState> {
    constructor(props) {
        super(props);

        this.state = {
            isPopoverOpen: true,
            modalOpened1: false,
            modalOpened2: false,
        };
    }

    showModal1 = () => {
        this.setState({ modalOpened1: true });
    };

    closeModal1 = () => {
        this.setState({ modalOpened1: false });
    };

    showModal2 = () => {
        this.setState({ modalOpened2: true });
    };

    closeModal2 = () => {
        this.setState({ modalOpened2: false });
    };

    info = () => {
        Modal.info({
            title: 'This is a notification message',
            content: (
                <div>
                    <p>some messages...some messages...</p>
                    <p>some messages...some messages...</p>
                </div>
            ),
            onOk() {},
        });
    };

    success = () => {
        Modal.success({
            title: 'This is a success message',
            content: 'some messages...some messages...',
        });
    };

    error = () => {
        Modal.error({
            title: 'This is an error message',
            content: 'some messages...some messages...',
        });
    };

    warning = () => {
        Modal.warning({
            title: 'This is a warning message',
            content: 'some messages...some messages...',
        });
    };

    confirm = () => {
        Modal.confirm({
            title: 'Confirm',
            content: 'Bla bla ...',
            okText: 'ok',
            cancelText: 'cancel',
        });
    };

    renderContent() {
        // showNotification('success', 'Категория 1 изменена');
        const loremIpsum =
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley.";

        //var disabledHourOptions = this.disabledHours();
        //var disabledMinuteOptions = disabledMinutes(value ? value.hour() : null);
        //var disabledSecondOptions = disabledSeconds(value ? value.hour() : null, value ? value.minute() : null);
        var hourOptions = new Array(24).fill(0).map((i, index) => index); //generateOptions(24, disabledHourOptions, hideDisabledOptions, 1);
        var minuteOptions = new Array(60).fill(0).map((i, index) => index); //generateOptions(60, disabledMinuteOptions, hideDisabledOptions, 1);
        //var secondOptions = generateOptions(60, disabledSecondOptions, hideDisabledOptions, 1);

        return (
            <>
                <Card style={{ marginBottom: 16 }} title={'Кнопки'}>
                    <Row gutter={16} type={'flex'}>
                        <Col span={6}>
                            <Card title={'CreateEntityButton'}>
                                <CreateEntityButton style={{ marginRight: 16 }} />
                                <CreateEntityButton style={{ marginRight: 16 }} disabled={true} />
                            </Card>
                        </Col>
                        <Col span={6}>
                            <Card title={'AddButton'}>
                                <AddButton style={{ marginRight: 16 }} label={null} />
                                <AddButton style={{ marginRight: 16 }} label={null} disabled={true} />

                                <AddButton style={{ marginRight: 16 }} />
                                <AddButton style={{ marginRight: 16 }} label={'Создать'} />
                                <AddButton style={{ marginRight: 16 }} disabled={true} />

                                <AddButton icon={<Icon component={IconDownloadSolid2} />} style={{ marginRight: 16 }} label={'Загрузить файл договора'} />
                                <AddButton icon={<Icon component={IconDownloadSolid2} />} style={{ marginRight: 16 }} label={'Загрузить файл договора'} disabled={true} />
                            </Card>
                        </Col>
                        <Col span={6}>
                            <Card title={'AddButton2'}>
                                <AddButton2 style={{ marginRight: 16 }} onClick={()=>alert('click')} />
                                <AddButton2 style={{ marginRight: 16 }} onClick={()=>alert('click')} label={'Добавить новые'} />
                                <AddButton2 style={{ marginRight: 16 }} onClick={()=>alert('click')} disabled={true} />
                            </Card>
                        </Col>
                        <Col span={6}>
                            <Card title={'EditButton'}>
                                <EditButton style={{ marginRight: 16 }} onClick={()=>alert('click')} />
                                <EditButton style={{ marginRight: 16 }} onClick={()=>alert('click')} label={'Редактировать'} />
                                <EditButton style={{ marginRight: 16 }} disabled={true} />
                                <EditButton style={{ marginRight: 16, height: 38, lineHeight: '38px'}} />
                            </Card>
                        </Col>
                        <Col span={6}>
                            <Card title={'LinkButton'}>
                                <LinkButton style={{ marginRight: 16 }}>123</LinkButton>
                                <LinkButton style={{ marginRight: 16 }}>444</LinkButton>
                                <LinkButton style={{ marginRight: 16 }} disabled={true}>
                                    11
                                </LinkButton>
                                <LinkButton style={{ marginRight: 16 }} disabled={!true}>
                                    <SystemIcon type={SystemIconType.cancel} />
                                    321
                                </LinkButton>
                            </Card>
                        </Col>
                        <Col span={6}>
                            <Card title={'LinkButton'}>
                                <LinkButton2
                                    icon={<Icon style={{color: '#ffcd43', fontSize: 21}} component={IconCashRegisterSolid} />}
                                    label={localize(LocalizationEnum.PAGE__PROJECTS__ACTIONS__ACCEPT_PAYMENT)}
                                    onClick={()=>alert('click')}
                                />
                                <LinkButton2
                                    disabled={true}
                                    icon={<Icon style={{color: '#ffcd43', fontSize: 21}} component={IconCashRegisterSolid} />}
                                    label={localize(LocalizationEnum.PAGE__PROJECTS__ACTIONS__ACCEPT_PAYMENT)}
                                    onClick={()=>alert('click')}
                                />
                            </Card>
                        </Col>
                        <Col span={6}>
                            <Card title={'DownloadDocumentButton'}>
                                <DownloadDocumentButton documentType={DocumentTypeCodeEnum.CONTRACT} style={{ marginRight: 16 }} />
                                <DownloadDocumentButton
                                    documentType={DocumentTypeCodeEnum.CONTRACT}
                                    style={{ marginRight: 16 }}
                                    disabled={true}
                                />
                                <DownloadDocumentButton documentType={DocumentTypeCodeEnum.BILLOFLADING} style={{ marginRight: 16 }} />
                                <DownloadDocumentButton
                                    documentType={DocumentTypeCodeEnum.BILLOFLADING}
                                    style={{ marginRight: 16 }}
                                    disabled={true}
                                />
                            </Card>
                        </Col>
                    </Row>
                </Card>

                <Card style={{ marginBottom: 16 }} title={'Иконки'}>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Card title={'SystemIcon'}>
                                <Row>
                                    {Object.values(SystemIconType).map((item) => {
                                        return (
                                            <Col
                                                span={6}
                                                style={{
                                                    marginBottom: 10,
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <div style={{ fontWeight: 500 }}>{`${item}`}</div>
                                                <div>
                                                    <SystemIcon type={item} />
                                                </div>
                                            </Col>
                                        );
                                    })}
                                </Row>
                            </Card>
                        </Col>
                        <Col span={24}>
                            <Card title={'AttachmentIcon'}>
                                <Row>
                                    {Object.values(FileTypeCodeEnum).map((item) => {
                                        return (
                                            <Col
                                                span={6}
                                                style={{
                                                    marginBottom: 10,
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <div style={{ fontWeight: 500 }}>{`${item}`}</div>
                                                <div>
                                                    <AttachmentIcon type={item} />
                                                </div>
                                            </Col>
                                        );
                                    })}
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                </Card>

                <Card style={{ marginBottom: 16 }} title={'Поля ввода'}>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Card title={'InputNumber'}>
                                <Row>
                                    <Col></Col>
                                </Row>
                            </Card>

                            <Card title={'InputDiscount'}>
                                <Row>
                                    <Col>
                                        <NumberInputDiscount
                                            style={{ marginRight: 16 }}
                                            onChange={(v) => {
                                                console.log('onChange', v);
                                            }}
                                        />
                                        <NumberInputDiscount
                                            style={{ marginRight: 16 }}
                                            onChange={(v) => {
                                                console.log('onChange', v);
                                            }}
                                            defaultValue={50}
                                        />
                                        <NumberInputDiscount
                                            style={{ marginRight: 16 }}
                                            onChange={(v) => {
                                                console.log('onChange', v);
                                            }}
                                            disabled={true}
                                        />
                                        <NumberInputDiscount
                                            style={{ marginRight: 16 }}
                                            onChange={(v) => {
                                                console.log('onChange', v);
                                            }}
                                            defaultValue={10}
                                        />
                                        <NumberInputDiscount
                                            style={{ marginRight: 16 }}
                                            onChange={(v) => {
                                                console.log('onChange', v);
                                            }}
                                            defaultValue={10}
                                        />
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                </Card>

                <Row gutter={16}>
                    <Col span={24} style={{ paddingBottom: 24 }}>
                        <Card bordered={false} title={'InputNumber'}>
                            <Row>
                                <Col span={8}>
                                    <NumberInput min={0} max={10} defaultValue={1} style={{ marginRight: 10 }} />
                                    <NumberInput disabled min={0} max={10} defaultValue={1} style={{ marginRight: 10 }} />
                                </Col>

                                <Col span={8}>
                                    <NumberInput
                                        className={'rr-input-number-light'}
                                        style={{ marginRight: 10 }}
                                        defaultValue={0}
                                        min={0}
                                        max={23}
                                        step={1}
                                    />
                                    <NumberInput
                                        className={'rr-input-number-light'}
                                        style={{ marginRight: 10 }}
                                        defaultValue={0}
                                        min={0}
                                        max={23}
                                        step={1}
                                        disabled
                                    />
                                </Col>

                                <Col span={8} style={{ backgroundColor: '#525a95' }}>
                                    <NumberInput
                                        min={0}
                                        max={10}
                                        defaultValue={1}
                                        className={'rr-input-number'}
                                        style={{ marginRight: 10 }}
                                    />
                                    <NumberInput
                                        disabled
                                        min={0}
                                        max={10}
                                        defaultValue={1}
                                        className={'rr-input-number'}
                                        style={{ marginRight: 10 }}
                                    />
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>

                <Card style={{ marginBottom: 16 }} title={'Выпадающие списки'}>
                    <Row gutter={16}>
                        <Col span={24}>
                            {/*<Card title={'SystemIcon'}>*/}
                            {/*    <Row>*/}
                            {/*        {Object.values(SystemIconType).map((item)=>{*/}
                            {/*            return <Col span={6} style={{marginBottom: 10, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>*/}
                            {/*                <div style={{fontWeight: 500}}>{`${item}`}</div>*/}
                            {/*                <div><SystemIcon type={item} /></div>*/}
                            {/*            </Col>*/}
                            {/*        })}*/}
                            {/*    </Row>*/}
                            {/*</Card>*/}
                        </Col>
                    </Row>
                </Card>

                {/*<Row>
                    <Col span={18} push={6} style={{backgroundColor: '#00FF00', height: 200}}>
                        col-18 col-push-6
                    </Col>
                    <Col span={6} pull={18} style={{backgroundColor: '#00FFFF'}}>
                        col-6 col-pull-18
                    </Col>
                </Row>

                <br/><br/>


                <Row>
                    <Col span={24} xl={{span: 10, push:14, pull: 0}} style={{backgroundColor: '#00FFFF'}}>2</Col>
                    <Col span={24} xl={{span: 14, push:0, pull: 10}} style={{backgroundColor: '#00FF00', height: 200}}>1</Col>

                    <Col span={24} xl={{span: 10, push:14, pull: 0}} style={{backgroundColor: '#FFFF00', height: 500}}>3</Col>

                    <Col span={24} style={{backgroundColor: '#00FFFF'}}>4</Col>
                </Row>
                */}

                {moment.duration(49, 'minute').humanize()}

                {moment('20111031', 'YYYYMMDD').fromNow()}
                {moment().subtract(3, 'days').calendar()}
                {/*<OperationProblemModal visible={true} type={'warning'} message={'Операция приведет к нехватке по заявкам'} products={[{name: 'Sony Ericsson', count: 4}, {name: 'Лампа настольная Милочка', count: 4}]} />*/}
                {/*} <OperationProblemModal
                    visible={true}
                    type={OperationProblemModalType.ERROR}
                    isCorrection={true}
                    products={[
                        { name: 'Sony Ericsson', count: 4, id: 3060 },
                        { name: 'Лампа настольная Милочка', count: 4, id: 1468 }
                    ]}
                />*/}
                {/*<OperationProblemModal type={'error'} message={'Операция приведет к нехватке по заявкам'} products={[{name: 'Sony Ericsson', count: 4}, {name: 'Лампа настольная Милочка', count: 4}]} />*/}
                {/*<Modal title={<>
                    <Icon component={IconExclamationCircleSolid} style={{marginRight: 4, fontSize: 32, color: '#f8516c', verticalAlign: 'middle'}}/>
                    <span>Проблема</span>
                </>} visible={true} closable={false} footer={[
                    <Button key="back" type={'primary'}>
                        Отмена
                    </Button>,
                    <Button key="submit" type="link" loading={!true} style={{color: '#f8516c'}}>
                        Применить операцию
                    </Button>,
                ]}>
                    <>
                        <div style={{fontSize: 18, fontWeight: 'bold', marginBottom: 22}}>Операция приведет к нехватке по заявкам</div>
                        <div style={{marginBottom: 6}}>Продукты</div>
                        <div>
                            <span style={{fontSize: 18, fontWeight: 'bold', marginBottom: 4}}>Sony</span>
                            <span style={{fontSize: 18, fontWeight: 'bold', marginLeft: 12, color: '#ff9924'}}>4</span>
                            <span style={{marginLeft: 4, color: '#ff9924'}}>
                                <Link to={'#'}>

                                </Link>
                            </span>
                        </div>
                        <div>
                            <span style={{fontSize: 18, fontWeight: 'bold', marginBottom: 4}}>Sony</span>
                            <span style={{fontSize: 18, fontWeight: 'bold', marginLeft: 12, color: '#f8516c'}}>4</span>
                            <span style={{marginLeft: 4, color: '#f8516c'}}></span>
                        </div>
                    </>
                </Modal>
*/}

                <RecalculateShiftsCountButton
                    from={7}
                    to={13}
                    onClick={() => {
                        console.log('onClick');
                    }}
                />

                <RecalculateShiftsCountButton
                    from={7}
                    to={7}
                    onClick={() => {
                        console.log('onClick');
                    }}
                />

                <RecalculateShiftsCountButton from={7} to={27} />

                <RecalculateShiftsCountButton from={7} to={7} />

                {/*'HH:mm'*/}

                {/*
                      + format: _propTypes["default"].string,
                      defaultOpenValue: _propTypes["default"].object,
                      + prefixCls: _propTypes["default"].string,
                      value: _propTypes["default"].object,
                      onChange: _propTypes["default"].func,
                      onAmPmChange: _propTypes["default"].func,
                      + showHour: _propTypes["default"].bool,
                      + showMinute: _propTypes["default"].bool,
                      + showSecond: _propTypes["default"].bool,
                      hourOptions: _propTypes["default"].array,
                      minuteOptions: _propTypes["default"].array,
                      secondOptions: _propTypes["default"].array,
                      + disabledHours: _propTypes["default"].func,
                      + disabledMinutes: _propTypes["default"].func,
                      + disabledSeconds: _propTypes["default"].func,
                      onCurrentSelectPanelChange: _propTypes["default"].func,
                      + use12Hours: _propTypes["default"].bool,
                      onEsc: _propTypes["default"].func,
                      isAM: _propTypes["default"].bool

                    * */}

                {/*

                  format: _propTypes["default"].string,
                  defaultOpenValue: _propTypes["default"].object,
                  prefixCls: _propTypes["default"].string,
                  value: _propTypes["default"].object,
                  onChange: _propTypes["default"].func,
                  onAmPmChange: _propTypes["default"].func,
                  showHour: _propTypes["default"].bool,
                  showMinute: _propTypes["default"].bool,
                  showSecond: _propTypes["default"].bool,
                  hourOptions: _propTypes["default"].array,
                  minuteOptions: _propTypes["default"].array,
                  secondOptions: _propTypes["default"].array,
                  disabledHours: _propTypes["default"].func,
                  disabledMinutes: _propTypes["default"].func,
                  disabledSeconds: _propTypes["default"].func,
                  onCurrentSelectPanelChange: _propTypes["default"].func,
                  use12Hours: _propTypes["default"].bool,
                  onEsc: _propTypes["default"].func,
                  isAM: _propTypes["default"].bool

                */}

                {/*  ==================== */}

                <Button
                    onClick={() => {
                        axios.get(`https://auth.dev.rr.council.ru/auth/realms/RentRabbit/account/`, {
                            headers: { Authorization: 'Bearer ' + getUserAccessToken() },
                        });
                    }}
                >
                    get account
                </Button>

                <Button
                    onClick={async () => {
                        let req = await axios.post(
                            `https://auth.dev.rr.council.ru/auth/realms/RentRabbit/account/`,
                            {
                                // attributes: {locale: ["ru"]},
                                email: 'pavel.1c@mail.ru', //"nnetsys@gmail.com",
                                // emailVerified: true,
                                firstName: 'al1XXXs2sdds',
                                lastName: 'sss',
                                //username: "nnetsys@gmail.com"
                            },
                            {
                                headers: {
                                    // "Access-Control-Allow-Origin": "*",
                                    // "Access-Control-Allow-Credentials": "true",
                                    // 'Content-Type': 'application/json',
                                    // 'Access-Control-Allow-Methods': 'GET, PUT, POST, DELETE, OPTIONS',
                                    // 'Access-Control-Allow-Headers': 'Content-Type, X-Experience-API-Version,Authorization',
                                    Authorization: 'Bearer ' + getUserAccessToken(),
                                    /*
                            * response.addHeader("Access-Control-Allow-Methods", "GET, PUT, POST, DELETE, OPTIONS");
response.addHeader("Access-Control-Allow-Headers", "Content-Type, X-Experience-API-Version,Authorization");
                            * */

                                    /*
                                     */
                                },
                            }
                        );
                        console.log(req);
                    }}
                >
                    change account
                </Button>

                <Button
                    onClick={async () => {
                        // axios.post(`https://auth.dev.rr.council.ru/auth/realms/RentRabbit/account/`, {
                        //     //firstName: 'sss',
                        //     //lastName: 'XxX'
                        // }, {
                        //     headers: {Authorization: 'Bearer ' + getUserAccessToken()}
                        // });

                        //userManager.signinRedirect();
                        //return;

                        try {
                            let user = await userManager.signinSilent();
                            console.log('A', user);
                            //console.log('B', await userManager.getUser());
                        } catch (e) {
                            console.log(e);
                        }
                    }}
                >
                    locale
                </Button>

                <Button
                    onClick={async () => {
                        // axios.post(`https://auth.dev.rr.council.ru/auth/realms/RentRabbit/account/`, {
                        //     //firstName: 'sss',
                        //     //lastName: 'XxX'
                        // }, {
                        //     headers: {Authorization: 'Bearer ' + getUserAccessToken()}
                        // });
                        try {
                            console.log('B', await userManager.getUser());
                        } catch (e) {
                            console.log(e);
                        }

                        // https://auth.dev.rr.council.ru/auth/realms/RentRabbit/protocol/openid-connect/userinfo
                    }}
                >
                    getUser
                </Button>

                <Button
                    onClick={async () => {
                        let req = await axios.get(
                            `https://auth.dev.rr.council.ru/auth/realms/RentRabbit/protocol/openid-connect/userinfo`,
                            {
                                headers: { Authorization: 'Bearer ' + getUserAccessToken() },
                            }
                        );
                        console.log('RRR', req.data);

                        let user = await userManager.getUser();
                        if (user) {
                            user.profile.email = req.data.email;
                            user.profile.email_verified = req.data.email_verified;
                            user.profile.family_name = req.data.family_name;
                            user.profile.given_name = req.data.given_name;
                            user.profile.locale = req.data.locale;
                            user.profile.name = req.data.name;
                            user.profile.preferred_username = req.data.preferred_username;
                            user.profile.sub = req.data.sub;
                            userManager.storeUser(user);
                        }
                    }}
                >
                    userinfo
                </Button>
                <br />
                <br />
                <Link to={'/234/components'}>234 акк</Link>
                <br />
                <Link to={'/1000/components'}>1000 акк</Link>
                <br />
                <Link to={'/666/components'}>666 акк</Link>
                <br />
                <Link to={'/components'}>хз акк</Link>
                <br />
                <SliderRange formatStyle={'number'} tooltipVisible range />
                <br />
                <br />
                <RangePicker
                    onChange={(dates: RangePickerValue) => {
                        this.setState({ selectedDates: dates });
                    }}
                />
                {/*


                <br/>
                <br/>
                <br/>
                <br/>
                */}
                {/*
                <CustomRangeCalendar showTime={!false} intervals={[
                    [430, 1574888400447],
                    [430, 1575111168782],
                    [433, 1575111168782],
                    [433, 1576184400447]
                ]} selectedValue={this.state.selectedDates} />

                <CustomRangeCalendar showTime={!false} onChange={(dates: RangePickerValue, dateStrings: [string, string])=>{
                    console.log('CustomRangeCalendar CHANGED', dates, dateStrings);
                    //this.setState({selectedDates: dates});
                }} />
                */}
                {/* selectedValue={this.state.selectedDates} defaultSelectedValue={[moment(), moment()]} */}
                <div>
                    {/*<DatePicker

                        dateRender={current => {
                            const style = {};
                            if (current.date() === 1) {
                                //style.border = '1px solid #1890ff';
                                //style.borderRadius = '50%';
                            }
                            return (
                                <div className="ant-calendar-date" style={style}>
                                    {current.date()}ZZZ
                                </div>
                            );
                        }}
                    ></DatePicker>*/}

                    {/*<RangePicker format={'DD.MM.YYYY - HH:mm'}
                        dateRender={current => {
                            //console.log('!!!', current);
                            //const style = {position: "relative"};
                            if (current.date() === 1) {
                                //style.border = '1px solid #1890ff';
                                //style.borderRadius = '50%';
                            }
                            return (
                                <div className="ant-calendar-date" style={{position: "relative"}}>
                                    <div className={'rr-test-1X'}>10</div>
                                    <div className={'rr-test-2'}></div>
                                    <div className={'rr-test-3'}></div>
                                    <div className={'rr-test-4'}>
                                        {current.date()}
                                    </div>
                                </div>
                            );
                        }}
                                            renderExtraFooter={()=>{
                                                return <div>123</div>
                                            }}

                                            showTime={{
                                                format: "h:mm",
                                                minuteStep: 15
                                            }}
                                            //open
                    />*/}
                </div>
                <br />
                <Row gutter={16}>
                    <Col span={24} style={{ paddingBottom: 24 }}>
                        <Card bordered={false} className={'icons-example'} title={'Иконки'}>
                            <Icon type={'hand-stop'} />
                            <Icon type={'cubes'} />
                            <Icon type={'close-circle'} theme={'filled'} />
                            <Icon type={'close'} />

                            <Icon type={'calendar'} />
                            <Icon type={'eye'} />
                            <Icon type={'eye-invisible'} />

                            <Icon type={'down'} />
                            <Icon type={'left'} />
                            <Icon type={'right'} />

                            <Icon type={'minus-square'} />
                            <Icon type={'plus-square'} />
                            <Icon type={'loading'} />
                            <Icon type={'search'} />
                            <Icon type={'plus'} />
                            {/*<Icon component={IconExcel} />
                            <Icon component={IconAngleDoubleLeft} />
                            <Icon component={IconAngleDoubleRight} />
                            <Icon component={IconBell} />
                            <Icon component={IconBriefcase} />
                            <Icon component={IconBullhorn} />
                            <Icon component={IconCheckCircle} />
                            <Icon component={IconCheckSquare} />
                            <Icon component={IconClipboard} />
                            <Icon component={IconClone} />
                            <Icon component={IconCogs} />
                            <Icon component={IconComments} />
                            <Icon component={IconFileZipO} />
                            <Icon component={IconFilter} />
                            <Icon component={IconPencil} />
                            <Icon component={IconPlusCircle} />
                            <Icon component={IconRepeat} />
                            <Icon component={IconReply} />
                            <Icon component={IconSliders} />
                            <Icon component={IconTags} />
                            <Icon component={IconUsd} />
                            <Icon component={IconUser} />
                            <Icon component={IconUsers} />*/}
                        </Card>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24} style={{ paddingBottom: 24 }}>
                        <Card bordered={false} title={'Tooltip'}>
                            <Tooltip title={'tooltip'} mouseEnterDelay={0.6}>
                                tooltip
                            </Tooltip>

                            <br />

                            <Tooltip
                                title={
                                    <span>
                                        tooltip <b>tooltip</b>
                                    </span>
                                }
                                visible={true}
                                placement="right"
                            >
                                tooltip visible
                            </Tooltip>
                        </Card>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24} style={{ paddingBottom: 24 }}>
                        <Card bordered={false} title={'Уведомления без текста'}>
                            <Button style={{ marginRight: 12 }} onClick={() => showNotification('error', 'Уведомление')}>
                                error
                            </Button>
                            <Button style={{ marginRight: 12 }} onClick={() => showNotification('success', 'Уведомление')}>
                                success
                            </Button>
                            <Button style={{ marginRight: 12 }} onClick={() => showNotification('info', 'Уведомление')}>
                                info
                            </Button>
                            <Button style={{ marginRight: 12 }} onClick={() => showNotification('warning', 'Уведомление')}>
                                warning
                            </Button>
                        </Card>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24} style={{ paddingBottom: 24 }}>
                        <Card bordered={false} title={'Уведомления c текстом'}>
                            <Button style={{ marginRight: 12 }} onClick={() => showNotification('error', 'Уведомление', loremIpsum)}>
                                error
                            </Button>
                            <Button style={{ marginRight: 12 }} onClick={() => showNotification('success', 'Уведомление', loremIpsum)}>
                                success
                            </Button>
                            <Button style={{ marginRight: 12 }} onClick={() => showNotification('info', 'Уведомление', loremIpsum)}>
                                info
                            </Button>
                            <Button style={{ marginRight: 12 }} onClick={() => showNotification('warning', 'Уведомление', loremIpsum)}>
                                warning
                            </Button>
                        </Card>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24} style={{ paddingBottom: 24 }}>
                        <Card bordered={false} title={'Аватар'}>
                            <Avatar className={'rr-avatar-big'}>
                                <Icon component={IconBriefcase} />
                            </Avatar>

                            <Avatar
                                className={'rr-avatar-big'}
                                src={
                                    'https://www.passion.ru/thumb/551x0/filters:quality(75)/imgs/2019/08/29/03/3536574/95443de7546d9b339e95a02e9a7624a5650079dc.jpg'
                                }
                            >
                                <Icon component={IconBriefcase} />
                            </Avatar>

                            <Avatar className={'rr-avatar-big'} src={'1'}></Avatar>
                        </Card>
                    </Col>
                </Row>
                <EntityRemoteSelect className={'rr-select-gray'} showSearch={true} operationName={'listUsersSimple'} />
                <RoundButton
                    colorScheme={'default'}
                    onClick={() => {
                        showNotification(
                            ['error', 'success', 'info', 'warning'][Math.round(Math.random() * 3)] as
                                | 'error'
                                | 'success'
                                | 'info'
                                | 'warning',
                            'Категория 1 изменена',
                            'dsdsdsd'
                        );
                    }}
                >
                    Показать уведомление
                </RoundButton>
                <br />
                <br />
                <RoundButton
                    colorScheme={'default'}
                    onClick={() => {
                        notification[['error', 'success', 'info', 'warning'][Math.round(Math.random() * 3)]]({
                            message: 'dsdsd',
                            description: 'csdsdsd',
                        });
                    }}
                >
                    Показать дефолтное уведомление
                </RoundButton>
                <br />
                <br />
                <Row gutter={16}>
                    <Col span={24} style={{ paddingBottom: 24 }}>
                        <Card bordered={false} className={'icons-example'} title={'Кнопки новые'}>
                            <RoundButton colorScheme={'default'}>
                                <Icon component={IconFileZipO} />
                                Архивировать
                            </RoundButton>

                            <RoundButton colorScheme={'danger'} icon={'close'}>
                                Отменить
                            </RoundButton>
                            <RoundButton colorScheme={'info'}>Сохранить изменения</RoundButton>
                            <RoundButton colorScheme={'brandSecond'}>Сохранить изменения</RoundButton>
                            <RoundButton colorScheme={'default'}>
                                <Icon component={IconEdit} />
                                Редактировать
                            </RoundButton>

                            <RoundButton colorScheme={'TO_ARCHIVE'}>
                                <Icon component={IconFileZipO} />В архив
                            </RoundButton>
                            <RoundButton colorScheme={'FROM_ARCHIVE'}>
                                <Icon component={IconLevelUp} />
                                Из архива
                            </RoundButton>

                            <RoundButton colorScheme={'success'} icon={'plus'}>
                                Создать
                            </RoundButton>
                        </Card>
                    </Col>
                </Row>
                <Button type={'link'}>Кнопка/Ссылка</Button>
                <Row gutter={32}>
                    <Col span={12} style={{ paddingBottom: 24 }}>
                        <Card bordered={false} title={'Модальные окна'}>
                            <Button type="primary" onClick={this.showModal1}>
                                Open Modal 1
                            </Button>
                            <Modal title="Заголовок окна 1" visible={this.state.modalOpened1} onCancel={this.closeModal1}>
                                <div>
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
                                    industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and
                                    scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap
                                    into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the
                                    release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing
                                    software like Aldus PageMaker including versions of Lorem Ipsum.
                                </div>
                            </Modal>

                            <Button type="primary" onClick={this.showModal2}>
                                Open Modal 2
                            </Button>
                            <Modal title="Заголовок окна 2" visible={this.state.modalOpened2} onCancel={this.closeModal2}>
                                <p>Lorem ipsum...</p>
                                <p>Lorem ipsum...</p>
                                <p>Lorem ipsum...</p>
                            </Modal>

                            <div>
                                <Button onClick={this.info}>Info</Button>
                                <Button onClick={this.success}>Success</Button>
                                <Button onClick={this.error}>Error</Button>
                                <Button onClick={this.warning}>Warning</Button>
                                <Button onClick={this.confirm}>Confirm</Button>
                            </div>
                        </Card>
                    </Col>
                </Row>
                <Row gutter={32}>
                    <Col span={12} style={{ paddingBottom: 24 }}>
                        <Card bordered={false} title={'Предупреждения'}>
                            <Alert
                                message="Уведомление"
                                description={
                                    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
                                }
                                type="success"
                                showIcon
                                closable
                            />

                            <Alert
                                message="Уведомление"
                                description={
                                    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
                                }
                                type="info"
                                showIcon
                                closable
                            />

                            <Alert
                                message="Уведомление"
                                description={
                                    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
                                }
                                type="warning"
                                showIcon
                                closable
                            />

                            <Alert
                                message="Предупреждение"
                                description={
                                    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
                                }
                                type="error"
                                showIcon
                                closable
                            />

                            <Alert
                                message="Предупреждение"
                                description={
                                    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
                                }
                                type="error"
                                showIcon
                            />

                            <Alert
                                message="Предупреждение"
                                description={
                                    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
                                }
                                type="error"
                            />

                            <Alert
                                message="Предупреждение"
                                description={
                                    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
                                }
                                type="error"
                                closable
                            />

                            <Alert message="Предупреждение" type="error" />

                            <Alert message="Предупреждение" type="error" closable />

                            <Alert message="Предупреждение" type="error" showIcon closable />
                        </Card>
                    </Col>
                </Row>
                <Row gutter={32}>
                    <Col span={24} style={{ paddingBottom: 24 }}>
                        {/*<Popover overlayClassName={'rr-grid-actions-popover'} autoAdjustOverflow content={<ActionsPopover/>} placement="bottomLeft" trigger="click">
                            <Icon style={{fontSize: '20px'}} component={IconPencil} />
                        </Popover>*/}
                    </Col>
                </Row>
                <Row gutter={32}>
                    <Col span={24} style={{ paddingBottom: 24 }}>
                        <Radio.Group defaultValue="a" buttonStyle="solid">
                            <Radio.Button value="a">Аналитика</Radio.Button>
                            <Radio.Button value="b">Задачи</Radio.Button>
                            <Radio.Button value="c">Операции</Radio.Button>
                        </Radio.Group>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col xl={6} lg={8}>
                        <Card bordered={false} className={'inputs-example'} title={'Textarea'}>
                            <div>
                                <Input.TextArea rows={1} placeholder={'Введите текст'} />
                            </div>
                            <div>
                                <Input.TextArea rows={1} placeholder={'Введите текст'} defaultValue={'Текст'} />
                            </div>
                            <div>
                                <Input.TextArea rows={1} placeholder={'Введите текст'} disabled={true} />
                            </div>
                            <div>
                                <Input.TextArea rows={1} placeholder={'Введите текст'} defaultValue={'Текст'} disabled={true} />
                            </div>

                            <div className={'has-error'}>
                                <div>
                                    <Input.TextArea rows={1} placeholder={'Введите текст'} />
                                </div>
                                <div>
                                    <Input.TextArea rows={1} placeholder={'Введите текст'} defaultValue={'Текст'} />
                                </div>
                                <div>
                                    <Input.TextArea rows={1} placeholder={'Введите текст'} disabled={true} />
                                </div>
                                <div>
                                    <Input.TextArea rows={1} placeholder={'Введите текст'} defaultValue={'Текст'} disabled={true} />
                                </div>
                            </div>
                        </Card>
                    </Col>

                    <Col xl={6} lg={8}>
                        <Card bordered={false} className={'inputs-example'} title={'Textarea'}>
                            <div>
                                <Input.TextArea rows={1} placeholder={'Введите текст'} className={'rr-input-gray'} />
                            </div>
                            <div>
                                <Input.TextArea rows={1} placeholder={'Введите текст'} defaultValue={'Текст'} className={'rr-input-gray'} />
                            </div>
                            <div>
                                <Input.TextArea rows={1} placeholder={'Введите текст'} disabled={true} className={'rr-input-gray'} />
                            </div>
                            <div>
                                <Input.TextArea
                                    rows={1}
                                    placeholder={'Введите текст'}
                                    defaultValue={'Текст'}
                                    disabled={true}
                                    className={'rr-input-gray'}
                                />
                            </div>

                            <div className={'has-error'}>
                                <div>
                                    <Input.TextArea rows={1} placeholder={'Введите текст'} className={'rr-input-gray'} />
                                </div>
                                <div>
                                    <Input.TextArea
                                        rows={1}
                                        placeholder={'Введите текст'}
                                        defaultValue={'Текст'}
                                        className={'rr-input-gray'}
                                    />
                                </div>
                                <div>
                                    <Input.TextArea rows={1} placeholder={'Введите текст'} disabled={true} className={'rr-input-gray'} />
                                </div>
                                <div>
                                    <Input.TextArea
                                        rows={1}
                                        placeholder={'Введите текст'}
                                        defaultValue={'Текст'}
                                        disabled={true}
                                        className={'rr-input-gray'}
                                    />
                                </div>
                            </div>
                        </Card>
                    </Col>

                    <Col xl={6} lg={8}>
                        <Card bordered={false} className={'inputs-example'} title={'Текстовое поле'}>
                            <div>
                                <Input placeholder={'Плэйсхолдер Placeholder Плэйсх'} allowClear={true} />
                            </div>
                            <div>
                                <Input placeholder={'Placeholder'} defaultValue={'Value'} />
                            </div>
                            <div>
                                <Input placeholder={'Placeholder'} disabled={true} />
                            </div>
                            <div>
                                <Input placeholder={'Placeholder'} defaultValue={'Value'} disabled={true} />
                            </div>

                            <div className={'has-error'}>
                                <div>
                                    <Input placeholder={'Введите текст'} />
                                </div>
                                <div>
                                    <Input placeholder={'Введите текст'} defaultValue={'Текст'} />
                                </div>
                                <div>
                                    <Input placeholder={'Введите текст'} disabled={true} />
                                </div>
                                <div>
                                    <Input placeholder={'Введите текст'} defaultValue={'Текст'} disabled={true} />
                                </div>
                            </div>
                        </Card>
                    </Col>
                    <Col xl={6} lg={8}>
                        <Card bordered={false} className={'inputs-example'} title={'Текстовое поле (серое)'}>
                            <div>
                                <Input placeholder={'Введите текст'} className={'rr-input-gray'} />
                            </div>
                            <div>
                                <Input placeholder={'Введите текст'} defaultValue={'Текст'} className={'rr-input-gray'} />
                            </div>
                            <div>
                                <Input placeholder={'Введите текст'} disabled={true} className={'rr-input-gray'} />
                            </div>
                            <div>
                                <Input placeholder={'Введите текст'} defaultValue={'Текст'} disabled={true} className={'rr-input-gray'} />
                            </div>

                            <div className={'has-error'}>
                                <div>
                                    <Input placeholder={'Введите текст'} className={'rr-input-gray'} />
                                </div>
                                <div>
                                    <Input placeholder={'Введите текст'} defaultValue={'Текст'} className={'rr-input-gray'} />
                                </div>
                                <div>
                                    <Input placeholder={'Введите текст'} disabled={true} className={'rr-input-gray'} />
                                </div>
                                <div>
                                    <Input
                                        placeholder={'Введите текст'}
                                        defaultValue={'Текст'}
                                        disabled={true}
                                        className={'rr-input-gray'}
                                    />
                                </div>
                            </div>
                        </Card>
                    </Col>
                    <Col xl={6} lg={8}>
                        <Card bordered={false} className={'inputs-example'} title={'Поиск'}>
                            <div>
                                ZZZ
                                <Search
                                    onChange={(value) => console.log('onChange', value.target.value)}
                                    onSearch={(value) => console.log('onSearch', value)}
                                    placeholder="Поиск"
                                />
                            </div>
                            <div>
                                <Search onSearch={(value, e) => {}} placeholder="Поиск" defaultValue={'Текст'} />
                            </div>
                            <div>
                                <Search onSearch={(value) => console.log('onSearch', value)} placeholder="Поиск" disabled={true} />
                            </div>
                            <div>
                                <Search onSearch={(value, e) => {}} placeholder="Поиск" defaultValue={'Текст'} disabled={true} />
                            </div>

                            <div className={'has-error'}>
                                <div>
                                    <Search onSearch={(value) => console.log('onSearch', value)} placeholder="Поиск" />
                                </div>
                                <div>
                                    <Search onSearch={(value, e) => {}} placeholder="Поиск" defaultValue={'Текст'} />
                                </div>
                                <div>
                                    <Search onSearch={(value) => console.log('onSearch', value)} placeholder="Поиск" disabled={true} />
                                </div>
                                <div>
                                    <Search onSearch={(value, e) => {}} placeholder="Поиск" defaultValue={'Текст'} disabled={true} />
                                </div>
                            </div>
                        </Card>
                    </Col>
                    <Col xl={6} lg={8}>
                        <Card bordered={false} className={'inputs-example'} title={'Поиск (серый)'}>
                            <div>
                                <Search
                                    onSearch={(value) => console.log('onSearch', value)}
                                    placeholder="Поиск"
                                    className={'rr-input-gray'}
                                />
                            </div>
                            <div>
                                <Search
                                    onSearch={(value, e) => {}}
                                    placeholder="Поиск"
                                    defaultValue={'Текст'}
                                    className={'rr-input-gray'}
                                />
                            </div>
                            <div>
                                <Search
                                    onSearch={(value) => console.log('onSearch', value)}
                                    placeholder="Поиск"
                                    disabled={true}
                                    className={'rr-input-gray'}
                                />
                            </div>
                            <div>
                                <Search
                                    onSearch={(value, e) => {}}
                                    placeholder="Поиск"
                                    defaultValue={'Текст'}
                                    disabled={true}
                                    className={'rr-input-gray'}
                                />
                            </div>

                            <div className={'has-error'}>
                                <div>
                                    <Search
                                        onSearch={(value) => console.log('onSearch', value)}
                                        placeholder="Поиск"
                                        className={'rr-input-gray'}
                                    />
                                </div>
                                <div>
                                    <Search
                                        onSearch={(value, e) => {}}
                                        placeholder="Поиск"
                                        defaultValue={'Текст'}
                                        className={'rr-input-gray'}
                                    />
                                </div>
                                <div>
                                    <Search
                                        onSearch={(value) => console.log('onSearch', value)}
                                        placeholder="Поиск"
                                        disabled={true}
                                        className={'rr-input-gray'}
                                    />
                                </div>
                                <div>
                                    <Search
                                        onSearch={(value, e) => {}}
                                        placeholder="Поиск"
                                        defaultValue={'Текст'}
                                        disabled={true}
                                        className={'rr-input-gray'}
                                    />
                                </div>
                            </div>
                        </Card>
                    </Col>

                    <Col xl={6} lg={8}>
                        <Card bordered={false} className={'inputs-example'} title={'Кастомный поиск'}>
                            <div>
                                <SearchInput
                                    placeholder={'Поиск'}
                                    onChange={(e) => console.log('input onChange')}
                                    onPressEnter={(event) => console.log(event.target)}
                                />
                            </div>
                            <div>
                                <SearchInput
                                    placeholder={'Поиск'}
                                    defaultValue={'Текст'}
                                    onChange={(e) => console.log('input onChange')}
                                    onPressEnter={(event) => console.log(event.target)}
                                />
                            </div>
                            <div>
                                <SearchInput
                                    placeholder={'Поиск'}
                                    onChange={(e) => console.log('input onChange')}
                                    onPressEnter={(event) => console.log(event.target)}
                                    disabled={true}
                                />
                            </div>
                            <div>
                                <SearchInput
                                    placeholder={'Поиск'}
                                    defaultValue={'Текст'}
                                    onChange={(e) => console.log('input onChange')}
                                    onPressEnter={(event) => console.log(event.target)}
                                    disabled={true}
                                />
                            </div>

                            <div className={'has-error'}>
                                <div>
                                    <SearchInput
                                        placeholder={'Поиск'}
                                        onChange={(e) => console.log('input onChange')}
                                        onPressEnter={(event) => console.log(event.target)}
                                    />
                                </div>
                                <div>
                                    <SearchInput
                                        placeholder={'Поиск'}
                                        defaultValue={'Текст'}
                                        onChange={(e) => console.log('input onChange')}
                                        onPressEnter={(event) => console.log(event.target)}
                                    />
                                </div>
                                <div>
                                    <SearchInput
                                        placeholder={'Поиск'}
                                        onChange={(e) => console.log('input onChange')}
                                        onPressEnter={(event) => console.log(event.target)}
                                        disabled={true}
                                    />
                                </div>
                                <div>
                                    <SearchInput
                                        placeholder={'Поиск'}
                                        defaultValue={'Текст'}
                                        onChange={(e) => console.log('input onChange')}
                                        onPressEnter={(event) => console.log(event.target)}
                                        disabled={true}
                                    />
                                </div>
                            </div>
                        </Card>
                    </Col>

                    <Col xl={6} lg={8}>
                        <Card bordered={false} className={'inputs-example'} title={'Кастомный поиск (серый)'}>
                            <div>
                                <SearchInput
                                    placeholder={'Поиск'}
                                    onChange={(e) => console.log('input onChange')}
                                    onPressEnter={(event) => console.log(event.target)}
                                    className={'rr-input-gray'}
                                />
                            </div>
                            <div>
                                <SearchInput
                                    placeholder={'Поиск'}
                                    defaultValue={'Текст'}
                                    onChange={(e) => console.log('input onChange')}
                                    onPressEnter={(event) => console.log(event.target)}
                                    className={'rr-input-gray'}
                                />
                            </div>
                            <div>
                                <SearchInput
                                    placeholder={'Поиск'}
                                    onChange={(e) => console.log('input onChange')}
                                    onPressEnter={(event) => console.log(event.target)}
                                    disabled={true}
                                    className={'rr-input-gray'}
                                />
                            </div>
                            <div>
                                <SearchInput
                                    placeholder={'Поиск'}
                                    defaultValue={'Текст'}
                                    onChange={(e) => console.log('input onChange')}
                                    onPressEnter={(event) => console.log(event.target)}
                                    disabled={true}
                                    className={'rr-input-gray'}
                                />
                            </div>

                            <div className={'has-error'}>
                                <div>
                                    <SearchInput
                                        placeholder={'Поиск'}
                                        onChange={(e) => console.log('input onChange')}
                                        onPressEnter={(event) => console.log(event.target)}
                                        className={'rr-input-gray'}
                                    />
                                </div>
                                <div>
                                    <SearchInput
                                        placeholder={'Поиск'}
                                        defaultValue={'Текст'}
                                        onChange={(e) => console.log('input onChange')}
                                        onPressEnter={(event) => console.log(event.target)}
                                        className={'rr-input-gray'}
                                    />
                                </div>
                                <div>
                                    <SearchInput
                                        placeholder={'Поиск'}
                                        onChange={(e) => console.log('input onChange')}
                                        onPressEnter={(event) => console.log(event.target)}
                                        disabled={true}
                                        className={'rr-input-gray'}
                                    />
                                </div>
                                <div>
                                    <SearchInput
                                        placeholder={'Поиск'}
                                        defaultValue={'Текст'}
                                        onChange={(e) => console.log('input onChange')}
                                        onPressEnter={(event) => console.log(event.target)}
                                        disabled={true}
                                        className={'rr-input-gray'}
                                    />
                                </div>
                            </div>
                        </Card>
                    </Col>

                    <Col xl={6} lg={8}>
                        <Card bordered={false} className={'inputs-example'} title={'Combobox'}>
                            <div>
                                <CustomSelect placeholder={'Выбор'} style={{ minWidth: '100%' }}>
                                    <Select.Option value="jack">Jack</Select.Option>
                                    <Select.Option value="lucy">Lucy</Select.Option>
                                    <Select.Option value="disabled" disabled>
                                        Disabled
                                    </Select.Option>
                                    <Select.Option value="Yiminghe">yiminghe</Select.Option>
                                </CustomSelect>
                            </div>

                            <div>
                                <CustomSelect defaultValue="lucy" style={{ minWidth: '100%' }}>
                                    <Select.Option value="jack">Jack</Select.Option>
                                    <Select.Option value="lucy">Lucy</Select.Option>
                                    <Select.Option value="disabled" disabled>
                                        Disabled
                                    </Select.Option>
                                    <Select.Option value="Yiminghe">yiminghe</Select.Option>
                                </CustomSelect>
                            </div>

                            <div>
                                <CustomSelect defaultValue="disabled" style={{ minWidth: '100%' }}>
                                    <Select.Option value="jack">Jack</Select.Option>
                                    <Select.Option value="lucy">Lucy</Select.Option>
                                    <Select.Option value="disabled" disabled>
                                        Disabled
                                    </Select.Option>
                                    <Select.Option value="Yiminghe">yiminghe</Select.Option>
                                </CustomSelect>
                            </div>

                            <div>
                                <CustomSelect placeholder={'Выбор'} style={{ minWidth: '100%' }} disabled>
                                    <Select.Option value="jack">Jack</Select.Option>
                                    <Select.Option value="lucy">Lucy</Select.Option>
                                    <Select.Option value="disabled" disabled>
                                        Disabled
                                    </Select.Option>
                                    <Select.Option value="Yiminghe">yiminghe</Select.Option>
                                </CustomSelect>
                            </div>

                            <div>
                                <CustomSelect defaultValue="lucy" style={{ minWidth: '100%' }} disabled>
                                    <Select.Option value="jack">Jack</Select.Option>
                                    <Select.Option value="lucy">Lucy</Select.Option>
                                    <Select.Option value="disabled" disabled>
                                        Disabled
                                    </Select.Option>
                                    <Select.Option value="Yiminghe">yiminghe</Select.Option>
                                </CustomSelect>
                            </div>

                            <div>
                                <CustomSelect defaultValue="disabled" style={{ minWidth: '100%' }} disabled>
                                    <Select.Option value="jack">Jack</Select.Option>
                                    <Select.Option value="lucy">Lucy</Select.Option>
                                    <Select.Option value="disabled" disabled>
                                        Disabled
                                    </Select.Option>
                                    <Select.Option value="Yiminghe">yiminghe</Select.Option>
                                </CustomSelect>
                            </div>
                        </Card>
                    </Col>

                    <Col xl={6} lg={8}>
                        <div className={'has-error'}>
                            <Card bordered={false} className={'inputs-example'} title={'Combobox'}>
                                <div>
                                    <CustomSelect placeholder={'Выбор'} style={{ minWidth: '100%' }}>
                                        <Select.Option value="jack">Jack</Select.Option>
                                        <Select.Option value="lucy">Lucy</Select.Option>
                                        <Select.Option value="disabled" disabled>
                                            Disabled
                                        </Select.Option>
                                        <Select.Option value="Yiminghe">yiminghe</Select.Option>
                                    </CustomSelect>
                                </div>

                                <div>
                                    <CustomSelect defaultValue="lucy" style={{ minWidth: '100%' }}>
                                        <Select.Option value="jack">Jack</Select.Option>
                                        <Select.Option value="lucy">Lucy</Select.Option>
                                        <Select.Option value="disabled" disabled>
                                            Disabled
                                        </Select.Option>
                                        <Select.Option value="Yiminghe">yiminghe</Select.Option>
                                    </CustomSelect>
                                </div>

                                <div>
                                    <CustomSelect defaultValue="disabled" style={{ minWidth: '100%' }}>
                                        <Select.Option value="jack">Jack</Select.Option>
                                        <Select.Option value="lucy">Lucy</Select.Option>
                                        <Select.Option value="disabled" disabled>
                                            Disabled
                                        </Select.Option>
                                        <Select.Option value="Yiminghe">yiminghe</Select.Option>
                                    </CustomSelect>
                                </div>

                                <div>
                                    <CustomSelect placeholder={'Выбор'} style={{ minWidth: '100%' }} disabled>
                                        <Select.Option value="jack">Jack</Select.Option>
                                        <Select.Option value="lucy">Lucy</Select.Option>
                                        <Select.Option value="disabled" disabled>
                                            Disabled
                                        </Select.Option>
                                        <Select.Option value="Yiminghe">yiminghe</Select.Option>
                                    </CustomSelect>
                                </div>

                                <div>
                                    <CustomSelect defaultValue="lucy" style={{ minWidth: '100%' }} disabled>
                                        <Select.Option value="jack">Jack</Select.Option>
                                        <Select.Option value="lucy">Lucy</Select.Option>
                                        <Select.Option value="disabled" disabled>
                                            Disabled
                                        </Select.Option>
                                        <Select.Option value="Yiminghe">yiminghe</Select.Option>
                                    </CustomSelect>
                                </div>

                                <div>
                                    <CustomSelect defaultValue="disabled" style={{ minWidth: '100%' }} disabled>
                                        <Select.Option value="jack">Jack</Select.Option>
                                        <Select.Option value="lucy">Lucy</Select.Option>
                                        <Select.Option value="disabled" disabled>
                                            Disabled
                                        </Select.Option>
                                        <Select.Option value="Yiminghe">yiminghe</Select.Option>
                                    </CustomSelect>
                                </div>
                            </Card>
                        </div>
                    </Col>

                    <Col xl={6} lg={8}>
                        <Card bordered={false} className={'inputs-example'} title={'Multiple Combobox'}>
                            <div>
                                <CustomSelect placeholder={'Выбор'} mode={'multiple'} style={{ minWidth: '100%' }}>
                                    <Select.Option value="jack">Alaska</Select.Option>
                                    <Select.Option value="lucy">Alaska</Select.Option>
                                    <Select.Option value="disabled" disabled>
                                        Alaska
                                    </Select.Option>
                                    <Select.Option value="Yiminghe">Alaska</Select.Option>
                                </CustomSelect>
                            </div>

                            <div>
                                <CustomSelect placeholder={'Выбор'} defaultValue="lucy" mode={'multiple'} style={{ minWidth: '100%' }}>
                                    <Select.Option value="jack">Alaska</Select.Option>
                                    <Select.Option value="lucy">Alaska</Select.Option>
                                    <Select.Option value="disabled" disabled>
                                        Alaska
                                    </Select.Option>
                                    <Select.Option value="Yiminghe">Alaska</Select.Option>
                                </CustomSelect>
                            </div>

                            <div>
                                <CustomSelect placeholder={'Выбор'} defaultValue="disabled" mode={'multiple'} style={{ minWidth: '100%' }}>
                                    <Select.Option value="jack">Alaska</Select.Option>
                                    <Select.Option value="lucy">Alaska</Select.Option>
                                    <Select.Option value="disabled" disabled>
                                        Alaska
                                    </Select.Option>
                                    <Select.Option value="Yiminghe">Alaska</Select.Option>
                                </CustomSelect>
                            </div>

                            <div>
                                <CustomSelect placeholder={'Выбор'} mode={'multiple'} style={{ minWidth: '100%' }} disabled={true}>
                                    <Select.Option value="jack">Alaska</Select.Option>
                                    <Select.Option value="lucy">Alaska</Select.Option>
                                    <Select.Option value="disabled" disabled>
                                        Alaska
                                    </Select.Option>
                                    <Select.Option value="Yiminghe">Alaska</Select.Option>
                                </CustomSelect>
                            </div>

                            <div>
                                <CustomSelect
                                    placeholder={'Выбор'}
                                    defaultValue="lucy"
                                    mode={'multiple'}
                                    style={{ minWidth: '100%' }}
                                    disabled={true}
                                >
                                    <Select.Option value="jack">Alaska</Select.Option>
                                    <Select.Option value="lucy">Alaska</Select.Option>
                                    <Select.Option value="disabled" disabled>
                                        Alaska
                                    </Select.Option>
                                    <Select.Option value="Yiminghe">Alaska</Select.Option>
                                </CustomSelect>
                            </div>

                            <div>
                                <CustomSelect
                                    placeholder={'Выбор'}
                                    defaultValue="disabled"
                                    mode={'multiple'}
                                    style={{ minWidth: '100%' }}
                                    disabled={true}
                                >
                                    <Select.Option value="jack">Alaska</Select.Option>
                                    <Select.Option value="lucy">Alaska</Select.Option>
                                    <Select.Option value="disabled" disabled>
                                        Alaska
                                    </Select.Option>
                                    <Select.Option value="Yiminghe">Alaska</Select.Option>
                                </CustomSelect>
                            </div>
                        </Card>
                    </Col>

                    <Col xl={6} lg={8}>
                        <Card bordered={false} className={'inputs-example'} title={'Multiple Combobox'}>
                            <div className={'has-error'}>
                                <div>
                                    <CustomSelect placeholder={'Выбор'} mode={'tags'} style={{ minWidth: '100%' }}>
                                        <Select.Option value="jack">Alaska</Select.Option>
                                        <Select.Option value="lucy">Alaska</Select.Option>
                                        <Select.Option value="disabled" disabled>
                                            Alaska
                                        </Select.Option>
                                        <Select.Option value="Yiminghe1">Alaska</Select.Option>
                                        <Select.Option value="jack1">Alaska</Select.Option>
                                        <Select.Option value="lucy1">Alaska</Select.Option>
                                        <Select.Option value="disabled1" disabled>
                                            Alaska
                                        </Select.Option>
                                        <Select.Option value="Yiminghe2">Alaska</Select.Option>
                                        <Select.Option value="jack2">Alaska</Select.Option>
                                        <Select.Option value="lucy3">Alaska</Select.Option>
                                        <Select.Option value="disabled4" disabled>
                                            Alaska
                                        </Select.Option>
                                        <Select.Option value="Yiminghe5">Alaska</Select.Option>
                                    </CustomSelect>
                                </div>

                                <div>
                                    <CustomSelect placeholder={'Выбор'} defaultValue="lucy" mode={'multiple'} style={{ minWidth: '100%' }}>
                                        <Select.Option value="jack">Alaska</Select.Option>
                                        <Select.Option value="lucy">Alaska</Select.Option>
                                        <Select.Option value="disabled" disabled>
                                            Alaska
                                        </Select.Option>
                                        <Select.Option value="Yiminghe">Alaska</Select.Option>
                                    </CustomSelect>
                                </div>

                                <div>
                                    <CustomSelect
                                        placeholder={'Выбор'}
                                        defaultValue="disabled"
                                        mode={'multiple'}
                                        style={{ minWidth: '100%' }}
                                    >
                                        <Select.Option value="jack">Alaska</Select.Option>
                                        <Select.Option value="lucy">Alaska</Select.Option>
                                        <Select.Option value="disabled" disabled>
                                            Alaska
                                        </Select.Option>
                                        <Select.Option value="Yiminghe">Alaska</Select.Option>
                                    </CustomSelect>
                                </div>

                                <div>
                                    <CustomSelect placeholder={'Выбор'} mode={'multiple'} style={{ minWidth: '100%' }} disabled={true}>
                                        <Select.Option value="jack">Alaska</Select.Option>
                                        <Select.Option value="lucy">Alaska</Select.Option>
                                        <Select.Option value="disabled" disabled>
                                            Alaska
                                        </Select.Option>
                                        <Select.Option value="Yiminghe">Alaska</Select.Option>
                                    </CustomSelect>
                                </div>

                                <div>
                                    <CustomSelect
                                        placeholder={'Выбор'}
                                        defaultValue="lucy"
                                        mode={'multiple'}
                                        style={{ minWidth: '100%' }}
                                        disabled={true}
                                    >
                                        <Select.Option value="jack">Alaska</Select.Option>
                                        <Select.Option value="lucy">Alaska</Select.Option>
                                        <Select.Option value="disabled" disabled>
                                            Alaska
                                        </Select.Option>
                                        <Select.Option value="Yiminghe">Alaska</Select.Option>
                                    </CustomSelect>
                                </div>

                                <div>
                                    <CustomSelect
                                        placeholder={'Выбор'}
                                        defaultValue="disabled"
                                        mode={'multiple'}
                                        style={{ minWidth: '100%' }}
                                        disabled={true}
                                    >
                                        <Select.Option value="jack">Alaska</Select.Option>
                                        <Select.Option value="lucy">Alaska</Select.Option>
                                        <Select.Option value="disabled" disabled>
                                            Alaska
                                        </Select.Option>
                                        <Select.Option value="Yiminghe">Alaska</Select.Option>
                                    </CustomSelect>
                                </div>
                            </div>
                        </Card>
                    </Col>

                    <Col xl={6} lg={8}>
                        <div className={'has-error'}>
                            <Card bordered={false} className={'inputs-example'} title={'Combobox'}>
                                <div>
                                    <CustomSelect
                                        placeholder={'Выбор'}
                                        style={{ minWidth: '100%' }}
                                        className={'rr-select-round rr-select-gray'}
                                    >
                                        <Select.Option value="jack">Jack</Select.Option>
                                        <Select.Option value="lucy">Lucy</Select.Option>
                                        <Select.Option value="disabled" disabled>
                                            Disabled
                                        </Select.Option>
                                        <Select.Option value="Yiminghe">yiminghe</Select.Option>
                                    </CustomSelect>
                                </div>

                                <div>
                                    <CustomSelect defaultValue="lucy" style={{ minWidth: '100%' }}>
                                        <Select.Option value="jack">Jack</Select.Option>
                                        <Select.Option value="lucy">Lucy</Select.Option>
                                        <Select.Option value="disabled" disabled>
                                            Disabled
                                        </Select.Option>
                                        <Select.Option value="Yiminghe">yiminghe</Select.Option>
                                    </CustomSelect>
                                </div>

                                <div>
                                    <CustomSelect defaultValue="disabled" style={{ minWidth: '100%' }}>
                                        <Select.Option value="jack">Jack</Select.Option>
                                        <Select.Option value="lucy">Lucy</Select.Option>
                                        <Select.Option value="disabled" disabled>
                                            Disabled
                                        </Select.Option>
                                        <Select.Option value="Yiminghe">yiminghe</Select.Option>
                                    </CustomSelect>
                                </div>

                                <div>
                                    <CustomSelect placeholder={'Выбор'} style={{ minWidth: '100%' }} disabled>
                                        <Select.Option value="jack">Jack</Select.Option>
                                        <Select.Option value="lucy">Lucy</Select.Option>
                                        <Select.Option value="disabled" disabled>
                                            Disabled
                                        </Select.Option>
                                        <Select.Option value="Yiminghe">yiminghe</Select.Option>
                                    </CustomSelect>
                                </div>

                                <div>
                                    <CustomSelect defaultValue="lucy" style={{ minWidth: '100%' }} disabled>
                                        <Select.Option value="jack">Jack</Select.Option>
                                        <Select.Option value="lucy">Lucy</Select.Option>
                                        <Select.Option value="disabled" disabled>
                                            Disabled
                                        </Select.Option>
                                        <Select.Option value="Yiminghe">yiminghe</Select.Option>
                                    </CustomSelect>
                                </div>

                                <div>
                                    <CustomSelect defaultValue="disabled" style={{ minWidth: '100%' }} disabled>
                                        <Select.Option value="jack">Jack</Select.Option>
                                        <Select.Option value="lucy">Lucy</Select.Option>
                                        <Select.Option value="disabled" disabled>
                                            Disabled
                                        </Select.Option>
                                        <Select.Option value="Yiminghe">yiminghe</Select.Option>
                                    </CustomSelect>
                                </div>
                            </Card>
                        </div>
                    </Col>

                    <Col xl={12} lg={24}>
                        <Card bordered={false} className={'inputs-example'} title={'Buttons'}>
                            <Button href={'123dd4'} disabled={!true} shape="round" size={'large'} className={'rr-btn-action-archive'}>
                                <Icon component={IconFileZipO} />
                                Архивировать
                            </Button>
                        </Card>
                    </Col>

                    <Col xl={12} lg={24}>
                        <Card bordered={false} className={'inputs-example'} title={'Switch'}>
                            <Switch defaultChecked={true} title={'1234'}>
                                12334
                            </Switch>
                            <Switch defaultChecked={false} />
                            <Switch defaultChecked={true} loading={true} />
                            <Switch defaultChecked={false} loading={true} />
                            <Switch disabled={true} defaultChecked={true} />
                            <Switch disabled={true} defaultChecked={false} />
                        </Card>
                    </Col>
                </Row>
                <Row gutter={32}>
                    <Col span={24}>
                        <Card bordered={false}>
                            {/*<Slider step={1} min={0} max={100000} defaultValue={this.state.sliderValues} />

                <Slider disabled={true} step={1} min={0} max={100000} defaultValue={this.state.sliderValues} />*/}

                            {/*<AutoComplete
                    dataSource={dataSource}
                    style={{ width: 200 }}
                    onSelect={onSelect}
                    onSearch={this.handleSearch}
                    placeholder="Поиск"
                />*/}

                            <div className={'inputs-example'}></div>
                        </Card>
                        <Card bordered={false}>
                            <div className={'testButtonsGroup'}>
                                <Select defaultValue="lucy" style={{ width: 120 }}>
                                    <Select.Option value="jack">Jack</Select.Option>
                                    <Select.Option value="lucy">Lucy</Select.Option>
                                    <Select.Option value="disabled" disabled>
                                        Disabled
                                    </Select.Option>
                                    <Select.Option value="Yiminghe">yiminghe</Select.Option>
                                </Select>

                                <Select disabled={true} defaultValue="lucy" style={{ width: 120 }}>
                                    <Select.Option value="jack">Jack</Select.Option>
                                    <Select.Option value="lucy">Lucy</Select.Option>
                                    <Select.Option value="disabled" disabled>
                                        Disabled
                                    </Select.Option>
                                    <Select.Option value="Yiminghe">yiminghe</Select.Option>
                                </Select>

                                <br />
                                <br />

                                <Button shape="round" size={'large'} className={'rr-btn-action-archive'}>
                                    Архивировать
                                </Button>
                                <Button shape="round" size={'large'} className={'rr-btn-action-prolong'}>
                                    Продлить
                                </Button>
                                <Button shape="round" size={'large'} className={'rr-btn-action-give'}>
                                    Выдать
                                </Button>
                                <Button shape="round" size={'large'} className={'rr-btn-action-cancel'}>
                                    Отменить
                                </Button>

                                <br />
                                <br />

                                <Button htmlType={'button'} icon={'close'}>
                                    Отменить
                                </Button>

                                <Button shape="round" size={'large'} className={'rr-btn-action-prolong'}>
                                    <Icon component={IconFileZipO} />
                                    Архивировать
                                </Button>
                                <Button shape="round" size={'large'}>
                                    <Icon type={'hand-stop'} />
                                    Выдать
                                </Button>
                                <Button shape="round" size={'large'} icon={'close'}>
                                    Отменить
                                </Button>
                                <br />
                                <Button disabled={true} shape="round" size={'large'} className={'rr-btn-action-archive'}>
                                    <Icon component={IconFileZipO} />
                                    Архивировать
                                </Button>
                                <Button disabled={true} shape="round" size={'large'}>
                                    <Icon type={'hand-stop'} />
                                    Выдать
                                </Button>
                                <Button disabled={true} shape="round" size={'large'} icon={'close'}>
                                    Отменить
                                </Button>
                            </div>
                        </Card>
                        <Card bordered={false}>
                            <Checkbox defaultChecked={true} />
                            <Checkbox checked={false} disabled={true} />
                            <Checkbox checked={true} disabled={true} />
                        </Card>
                        <Card bordered={false}>
                            <Checkbox defaultChecked={true}>Чекбокс</Checkbox>
                            <br />
                            <Checkbox checked={false} disabled={true}>
                                Чекбокс
                            </Checkbox>
                            <br />
                            <Checkbox checked={true} disabled={true}>
                                Чекбокс
                            </Checkbox>
                        </Card>
                        <Card bordered={false}>
                            <RadioGroup defaultValue={2}>
                                <Radio value={1}>A</Radio>
                                <Radio value={2}>B</Radio>
                                <Radio value={3}>C</Radio>
                                <Radio value={4}>D</Radio>
                            </RadioGroup>
                        </Card>
                        <Card bordered={false}>
                            <RadioGroup defaultValue={1}>
                                <Radio value={1} disabled={true}>
                                    A
                                </Radio>
                                <br />
                                <Radio value={2}>B</Radio>
                                <br />
                                <Radio value={3}>C</Radio>
                                <br />
                                <Radio value={4}>D</Radio>
                            </RadioGroup>
                        </Card>
                        <Card bordered={false}>
                            <Switch defaultChecked={true} />
                            <Switch defaultChecked={false} />
                            <Switch defaultChecked={true} loading={true} />
                            <Switch defaultChecked={false} loading={true} />
                            <Switch disabled={true} defaultChecked={true} />
                            <Switch disabled={true} defaultChecked={false} />
                        </Card>
                        <br />
                        <Card bordered={false}>
                            <Button htmlType={'button'}>Архивировать</Button>
                            <Button htmlType={'button'}>Продать</Button>
                            <Button htmlType={'button'}>Выдать</Button>
                            <Button htmlType={'button'}>Отменить</Button>
                        </Card>
                        <Card bordered={false}>
                            <DatePicker format={dateFormat} />
                            <br />
                            <DatePicker format={dateFormat} defaultValue={moment('01.01.2001', dateFormat)} />
                            <br />
                            <DatePicker disabled format={dateFormat} />
                        </Card>
                        <Card bordered={false}>
                            <Checkbox checked={false} />
                            <Checkbox checked={true} />
                            <Checkbox defaultChecked={true}>Бронирование</Checkbox>

                            <Checkbox className={'checkbox-custom'}>Бронирование</Checkbox>
                            <Checkbox className={'checkbox-custom checkbox-custom-green'}>В аренде</Checkbox>
                            <Checkbox className={'checkbox-custom checkbox-custom-yellow'}>В заявке</Checkbox>
                            <Checkbox className={'checkbox-custom checkbox-custom-gray'}>Возвращено</Checkbox>
                        </Card>
                        <Card bordered={false}>
                            <Checkbox disabled={true} checked={false} />
                            <Checkbox disabled={true} checked={true} />
                            <Checkbox disabled={true} defaultChecked={true}>
                                Бронирование
                            </Checkbox>

                            <Checkbox disabled={true} className={'checkbox-custom'}>
                                Бронирование
                            </Checkbox>
                            <Checkbox disabled={true} className={'checkbox-custom checkbox-custom-green'}>
                                В аренде
                            </Checkbox>
                            <Checkbox disabled={true} className={'checkbox-custom checkbox-custom-yellow'}>
                                В заявке
                            </Checkbox>
                            <Checkbox disabled={true} className={'checkbox-custom checkbox-custom-gray'}>
                                Возвращено
                            </Checkbox>
                        </Card>
                        <Card bordered={false}>
                            <hr />
                            <Radio.Group disabled={true} defaultValue="c" buttonStyle="solid">
                                <Radio.Button value="a">Описание</Radio.Button>
                                <Radio.Button value="b">Обязательства</Radio.Button>
                                <Radio.Button value="c">Статистика</Radio.Button>
                                <Radio.Button value="d">История</Radio.Button>
                            </Radio.Group>
                            <hr />
                            <Radio.Group disabled={false} defaultValue="c" buttonStyle="solid">
                                <Radio.Button value="a">Описание</Radio.Button>
                                <Radio.Button value="b">Обязательства</Radio.Button>
                                <Radio.Button value="c">Статистика</Radio.Button>
                                <Radio.Button value="d" disabled={true}>
                                    История
                                </Radio.Button>
                            </Radio.Group>
                        </Card>
                        <TimePicker
                            format={'HH:mm'}
                            disabledSeconds={() => new Array(60).fill(0).map((i, index) => index)}
                            hideDisabledOptions
                            minuteStep={10}
                            defaultValue={moment('13:30:56', 'HH:mm:ss')}
                        />
                        ;
                        <TimeRangePicker minutesStep={5} />
                    </Col>
                </Row>

                <>
                    <RoundButton colorScheme={'default'} />
                    <RoundButton colorScheme={'danger'} />
                    <RoundButton colorScheme={'success'} />
                    <RoundButton colorScheme={'success2'} />
                    <RoundButton colorScheme={'info'} />
                    <RoundButton colorScheme={'brandSecond'} />
                    <RoundButton colorScheme={'brandSecond2'} />
                    <RoundButton colorScheme={'TO_ARCHIVE'} />
                    <RoundButton colorScheme={'FROM_ARCHIVE'} />
                    <RoundButton colorScheme={'blocked'} />
                    <RoundButton colorScheme={'inRent'} />
                    <RoundButton colorScheme={'PROLONG'} />
                    <RoundButton colorScheme={'RETURN'} />
                    <RoundButton colorScheme={'RETURN_BROKEN'} />
                    <RoundButton colorScheme={'LOST_NO_RETURN'} />
                    <RoundButton colorScheme={'CORRECT'} />
                    <RoundButton colorScheme={'RENT'} />
                    <RoundButton colorScheme={'CANCEL'} />
                    <RoundButton colorScheme={'ORDER'} />
                    <RoundButton colorScheme={'BOOK'} />
                    <RoundButton colorScheme={'DRAFT'} />
                    <RoundButton colorScheme={'UNDER_MAINTENANCE'} />
                    <RoundButton colorScheme={'defaultSecondary'} />
                    <RoundButton colorScheme={'successSecondary'} />
                    <RoundButton colorScheme={'default3'} />
                    <RoundButton colorScheme={'DELETE'} />
                    <RoundButton colorScheme={'PLANNED'} />
                    <RoundButton colorScheme={'ACTION_ELEMENTS_SET_DATES'} />
                    <RoundButton colorScheme={'ACTION_ELEMENTS_SET_SHIFTS'} />
                    <RoundButton colorScheme={'ACTION_ELEMENTS_RECALCULATE_SHIFTS'} />
                    <RoundButton colorScheme={'ACTION_ELEMENTS_SET_PRICE'} />
                    <RoundButton colorScheme={'default4'} disabled={true}>123</RoundButton>
                </>

            </>
        );
    }
}

const mapStateToProps = (storeState: IRootState) => ({
    businessAccountId: storeState.system.businessAccountId,
});

const mapDispatchToProps = {};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ComponentsModule);
