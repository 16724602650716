import React from 'react';
import {StateBadgeData} from "../../../../../components/v2/stateBadge/stateBadge";
import {Color} from "../../../../../core/types/color";
import {ProjectStateCodeEnum} from "../../../../../server";
import {ActionButtonsData} from "../../../../../components/v2/actionButtons/actionButtonsGroup/actionButtonsGroup";
import {LocalizationEnum} from "../../../../../localization";
import {Icon} from "antd";
import {
    IconBorderStyleSolid,
    IconBriefcase,
    IconCheckSquare,
    IconEraser,
    IconFlagCheckered,
    IconHandStopO
} from "../../../../../components/icons";
import {BasicActionsData} from "../../../../../components/v2/actionButtons/actionButton/actionButton";

export type ProjectStateCode = ProjectStateCodeEnum.DRAFT | ProjectStateCodeEnum.RENTED | ProjectStateCodeEnum.BOOKED | ProjectStateCodeEnum.INPROGRESS | ProjectStateCodeEnum.FINISHED | ProjectStateCodeEnum.CANCELED;

export const projectEntityStateBadgeData: StateBadgeData<(ProjectStateCode)> = {
    [ProjectStateCodeEnum.INPROGRESS]: {
        color: Color.Orange1,
        name: 'В работе',
    },
    [ProjectStateCodeEnum.FINISHED]: {
        color: Color.Green,
        name: 'Завершен',
    },
    [ProjectStateCodeEnum.CANCELED]: {
        color: Color.Grey2,
        name: 'Отменен',
    },
    [ProjectStateCodeEnum.DRAFT]: {
        color: Color.WhiteGrey1,
        name: 'Черновик',
    },
    [ProjectStateCodeEnum.RENTED]: {
        color: Color.Orange1,
        name: 'В аренде',
    },
    [ProjectStateCodeEnum.BOOKED]: {
        color: Color.Purple3,
        name: 'Бронь',
    },
};

export enum SimpleOrderActionType {
    edit = 'edit',
    convertToProject = 'convertToProject',
    toArchive = 'toArchive',
    fromArchive = 'fromArchive',
    finish = 'finish',
    cancel = 'cancel',
    returnToDraft = 'returnToDraft',
    book = 'book',
    rent = 'rent'
}

export const simpleOrderActionButtons: ActionButtonsData<SimpleOrderActionType> = {
    [SimpleOrderActionType.edit]: BasicActionsData.editAction,
    [SimpleOrderActionType.convertToProject]: {
        name: 'Преобразовать в проект',
        icon: <Icon component={IconBriefcase} />,
        color: Color.Purple1
    },
    [SimpleOrderActionType.toArchive]: BasicActionsData.toArchiveAction,
    [SimpleOrderActionType.fromArchive]: BasicActionsData.fromArchiveAction,
    [SimpleOrderActionType.returnToDraft]: {
        name: 'В черновик',
        icon: <Icon component={IconBorderStyleSolid} />,
        color: Color.WhiteGrey1
    },
    [SimpleOrderActionType.book]: {
        localizationId: LocalizationEnum.ASPECT__RENT_OPERATION__ACTION__BOOK,
        icon: <Icon component={IconCheckSquare} />,
        color: Color.Purple3
    },
    [SimpleOrderActionType.rent]: {
        localizationId: LocalizationEnum.ASPECT__RENT_OPERATION__ACTION__GIVE_AWAY,
        icon: <Icon component={IconHandStopO} />,
        color: Color.Orange1
    },
    [SimpleOrderActionType.finish]: {
        name: 'Вернуть и завершить',
        icon: <Icon component={IconFlagCheckered} />,
        color: Color.Green
    },
    [SimpleOrderActionType.cancel]: {
        localizationId: LocalizationEnum.ASPECT__RENT_OPERATION__ACTION__CANCEL,
        icon: <Icon component={IconEraser} />,
        color: Color.Grey2
    }
};
