import React, {CSSProperties, ReactNode} from 'react';
import {Button, Icon, Popover, Tooltip} from 'antd';
import {clearElements} from '../../elements/reducers/elements.reducer';
import {
    createOperation as createEditOperation,
    createOrEditSimpleOrder,
    fullResetOperation,
    removeConcurrentOperation,
    resetOperation,
    setElementIsNowEditing,
    setElementsListParams,
    setOperationElementsFilters,
} from '../reducers/operationForm.reducer';
import {connect} from 'react-redux';
import {IRootState} from '../../../../shared/reducers';
import {push} from 'connected-react-router';
import RoundButton from '../../../../components/button/roundButton';
import {IconEdit,} from '../../../../components/icons';
import {Spin} from '../../../../components';
import {getPathFromState, getStateFromPath,} from '../../../../shared/util/utils';
import {OperationTypeCodeEnum, ProjectInfoRead, ProjectStateCodeEnum,} from '../../../../server';
import {Location} from 'history';
import {injectIntl, WrappedComponentProps} from 'react-intl';
import {Grid} from '../../../../components/grid/Grid';
import {canViewFinancialData} from '../../../../shared/util/permissionUtils';
import {GridName} from '../../../../components/grid/utils/types';
import {IDownloadDocumentParamsItem} from '../../../../components/exportPopovers/downloadDocument/DownloadDocumentPopover';
import {ChangeElementsPricePopover} from "../operationModule/elementsList/components/changeElementsPricePopover";
import {getOperationFormElementsListGridProperties} from "../operationModule/elementsList/OperationFormElementsList";
import {operationFormElementsListColumns} from "../operationModule/elementsList/OperationFormElementsListColumns";
import {ErrorItem, OperationProblemModal, OperationProblemModalType} from "../operationModule/OperationProblemModal";
import {WarningIcon} from "../operationModule/components/icons/WarningIcon";
import {ProblemIcon} from "../operationModule/components/icons/ProblemIcon";
import {getOperationProblems} from "../operationModule/OperationModule";
import {sortByForEditOperationElements, sortByForElements} from "../reducers/operationForm.reducer.utils";
import {AddNewElementsButton} from "./comonents/addNewElementsButton/addNewElementsButton";
import {SimpleOrderElementsNotFoundBlock} from "./comonents/simpleOrderElementsNotFoundBlock/simpleOrderElementsNotFoundBlock";
import {SelectedElementsActionButtonsBlock} from "../operationModule/components/selectedElementsActionButtonsBlock/selectedElementsActionButtonsBlock";
import {businessAccountIdSelector} from "../../../../shared/reducers/system.reducer";
import {PromptRedirect} from "../../elements/components/promptRedirect/promptRedirect";
import {OrderCancelButton} from "./comonents/orderCancelButton/orderCancelButton";
import {LocalizationEnum, localizeIntl} from "../../../../localization";
import {setSimpleOrderListParams} from "../../elements/reducers/simpleOrderElements.reducer";
import {getGridStorageData} from "../../../../components/grid/utils";

interface IState {
    loading: boolean;
    problemModalIsShowing: boolean;
    selectedIds: number[]
}

export interface ElementsListProps extends StateProps, DispatchProps, WrappedComponentProps {
    location: Location;
    parentId: number;
    parentType: 'project' | 'operation' | 'projectTemplate' | 'shipping';
    operationEntityType?: 'project' | 'projectTemplate' | 'shipping';
    visible?: boolean;
    gridName: GridName;
    excludeColumns?: string[];
    excludeFields?: string[];
    parentProjectEntity?: ProjectInfoRead;
    exportActOptions?: IDownloadDocumentParamsItem[];
    exportEstimateOptions?: IDownloadDocumentParamsItem[];

    addElements?: () => void;
    createOperation?: () => void;
}

class Component extends React.Component<ElementsListProps, IState> {
    private grid;

    constructor(props: ElementsListProps) {
        super(props);

        this.state = {
            loading: false,
            problemModalIsShowing: false,
            selectedIds: []
        };
    }

    componentDidMount = async () => {
        let gridData = getGridStorageData(this.props.gridName);
        let params = gridData.params;
        this.props.setElementsListParams({
            sortBy: sortByForEditOperationElements(params?.sortBy as any),
            sortOrder:  params?.sortOrder
        });
    }

    clearSelection = () => {
        if (this.grid) this.grid.clearSelection();
    };

    onPageSizeChanged = (size) => {
        this.props.push(getPathFromState(this.props.location.pathname, this.props.location.search, {limit: size, page: 1}));
        this.props.setElementsListParams({limit: size, page: 1});
        this.clearSelection();
    };

    onSortedChange = (id: string, desc: boolean) => {
        const sortBy = sortByForElements(id as any);
        const sortOrder = desc ? 'DESC' : 'ASC';
        // this.props.push(
        //     getPathFromState(this.props.location.pathname, this.props.location.search, {
        //         sortBy,
        //         sortOrder,
        //     })
        // );
        this.props.setSimpleOrderListParams({sortBy: id, sortOrder: desc ? 'DESC' : 'ASC'});
        this.props.setElementsListParams({
            sortBy: id,
            sortOrder
        });
        this.clearSelection();
    };

    onPageChanged = (page: number) => {
        this.props.push(getPathFromState(this.props.location.pathname, this.props.location.search, {page: page}));
        this.props.setElementsListParams({
            page: page
        });
        this.clearSelection();
    };

    onSelectionChanged = (value) => {
        if (value && value.length > 0) {
            this.props.setElementIsNowEditing(true);
        } else {
            this.props.setElementIsNowEditing(false);
        }
        this.setState({selectedIds: value.map(item => +item)});
    };

    // renderFiltersRightExtraBlock = (errType: OperationProblemModalType | undefined, problems: ErrorItem[], operationWarningMessage: ReactNode) => {
    //     const {finalTotalPrice, finalTotalPriceWithTaxes} = this.props;
    //     const totalPrice = isDefined(finalTotalPriceWithTaxes) ? finalTotalPriceWithTaxes : finalTotalPrice;
    //
    //     return (
    //         <>
    //             <div style={{display: 'flex', alignItems: "center", flexDirection: 'row', marginLeft: 44}}>
    //                 <div style={{marginRight: 20}}>
    //                     <div>
    //                         {localize(LocalizationEnum.ASPECT__GLOBAL__IN_TOTAL)}
    //                     </div>
    //                     <div className={'rr-value'}>
    //                         <FormValue value={formatMoney(totalPrice)} originalValue={totalPrice}/>
    //                     </div>
    //                 </div>
    //                 {
    //                     this.renderSaveOperationButton(errType, problems, operationWarningMessage)
    //                 }
    //             </div>
    //             {/*Тут предупреждения и цену*/}
    //         </>
    //     );
    // }

    renderSaveButton = (buttonDisabled: boolean) => {
        return (
            <Tooltip title={!this.props.renterId ? 'Арендатор не указан' : undefined}>
                <Button disabled={buttonDisabled} type={'primary'} style={{borderWidth: 1, marginLeft: 10}} onClick={() => {
                    void this.createOperation();
                }}>{this.props.projectId ? 'Сохранить' : 'Создать'}</Button>
            </Tooltip>
        );
    }

    createOperation = async () => {
        //this.setState({loading: true});
        // this.props.onLoadingChange?.(true);
        // await this.props.createSimpleOrder(this.props.parentProjectEntity);
        // this.props.onLoadingChange?.(false);
        //this.setState({loading: false});
        this.props.createOperation?.();
    }

    renderSaveOperationButton = (errType: OperationProblemModalType | undefined, problems: ErrorItem[], operationWarningMessage: ReactNode) => {

        const buttonDisabled = !Boolean(this.props.renterId) || Boolean(operationWarningMessage) || errType === OperationProblemModalType.ERROR;

        return (
            <div style={{display: 'inline-flex', gap: 10, alignItems: 'center'}}>

                {/* Предупреждение */}
                {errType === OperationProblemModalType.WARNING &&
                <WarningIcon onClick={() => this.setState({problemModalIsShowing: true})}/>}

                {/* Ошибка */}
                {errType === OperationProblemModalType.ERROR && problems.length > 0 &&
                <ProblemIcon onClick={() => this.setState({problemModalIsShowing: true})}/>}

                {operationWarningMessage ? (
                    <Popover
                        placement="bottomRight"
                        arrowPointAtCenter={true}
                        autoAdjustOverflow={true}
                        content={operationWarningMessage}
                        overlayStyle={{maxWidth: 400}}
                    >
                        {this.renderSaveButton(buttonDisabled)}
                    </Popover>
                ) : (
                    this.renderSaveButton(buttonDisabled)
                )}
                <OrderCancelButton style={{marginLeft: 8}} withoutBorder={true} creationMode={!Boolean(this.props.parentProjectEntity)} />
            </div>
        );
    };

    getDataForEditOperationElements = () => {
        const {sortedData, newEntities, excludeColumns, canAddElements} = getOperationFormElementsListGridProperties({
            ...this.props,
            listParams: {
                limit: this.props.operationFormElementsData.limit,
                page: this.props.operationFormElementsData.page,
                sortBy: this.props.operationFormElementsData.sortBy,
                sortOrder: this.props.operationFormElementsData.sortOrder
            }
        });

        const filtersExcludeFields = ['search', 'problem', !this.props.elementsFilters?.selectedIds ? 'selectedIds' : undefined].filter(item => item) as string[];

        return {
            excludeColumns: [...excludeColumns || [], 'id', 'leftoverInstanceCount', 'rentPeriodStartDate', 'rentPeriodEndDate', 'stateCodeNew'],
            filtersExcludeFields,
            data: sortedData,
            columns: operationFormElementsListColumns,
            canGenerateAct: false,
            canGenerateEstimate: false,
            filteredCount: newEntities ? newEntities.length : 0,
            filtersCurrentValues: {...this.props.operationFormElementsData.filters, selectedIds: this.props.elementsFilters?.selectedIds}
        };
    }

    operationProblemModalOnOk = () => {
        void this.createOperation();
        this.setState({problemModalIsShowing: false});
    };

    operationProblemModalOnCancel = () => {
        this.setState({problemModalIsShowing: false});
    };

    renderAddNewElementsButton = (style?:CSSProperties) => {
        const {addElements} = this.props;
        return this.props.parentProjectEntity?.stateCode !== ProjectStateCodeEnum.CANCELED && this.props.parentProjectEntity?.stateCode !== ProjectStateCodeEnum.FINISHED && this.props.parentProjectEntity?.stateCode !== ProjectStateCodeEnum.RENTED ? <AddNewElementsButton onClick={addElements} style={style}/> : null;
    };

    render() {
        const {addElements} = this.props;
        const data = this.getDataForEditOperationElements();
        const {errType, problems, operationWarningMessage} = getOperationProblems({
            elements: this.props.elements.entities,
            operationTypeCode: this.props.operationTypeCode,
            operationCorrectionStateCode: this.props.operationCorrectionStateCode,
            projectTemplate: this.props.projectTemplate,
            elementsCount: this.props.elementsCount,
            shiftCountRoundingType: this.props.shiftCountRoundingType
        });

        return (
            <Spin spinning={this.state.loading} delay={this.state.loading ? 0 : undefined}>
                <PromptRedirect
                    onRedirect={() => {
                        this.props.fullResetOperation();
                    }}
                    question={'Сбросить текущую операцию?'}/>
                <div className={'rr-operationForm-elementsList'}>
                    {
                        data.data.length > 0 ? (
                            <Grid
                                convertSortBy={(id) => {
                                    return sortByForElements(id as any);
                                }}
                                excludeColumns={data.excludeColumns}
                                ref={(ref) => {
                                    if (!this.grid) this.grid = ref;
                                }}
                                onSortedChange={this.onSortedChange}
                                onPageChanged={this.onPageChanged}
                                onPageSizeChanged={this.onPageSizeChanged}
                                onSelectionChanged={this.onSelectionChanged}
                                filtered={data.filteredCount}
                                pageSize={this.props.operationFormElementsData.limit}
                                currentPage={this.props.operationFormElementsData.page}
                                columns={data.columns}
                                data={data.data}
                                indexFieldName={'id'}
                                actionButtons={[
                                    <SelectedElementsActionButtonsBlock
                                        selectedIds={this.state.selectedIds}
                                        onApply={() => {
                                            this.clearSelection();
                                        }}
                                        hideDateButtons={true}
                                        hidePriceButtons={!this.props.canViewFinancialData}
                                    />
                                ]}
                                sortingDisabled={this.state.selectedIds && this.state.selectedIds.length > 0}
                                entityType={'element'}
                                rowPopoverComponent={undefined}
                                defaultSorted={this.props.operationFormElementsData.sortBy}
                                defaultSortDesc={this.props.operationFormElementsData.sortOrder === 'DESC'}
                                //hideArchive={this.props.pageParams.hideInactive}
                                gridName={this.props.gridName}
                                //onPageSelect={[5, 10, 20, 50, 100, 200, 500]}
                                bottomPaginationRightExtraContent={
                                    this.renderSaveOperationButton(errType, problems, operationWarningMessage)
                                }
                                bottomPaginationLeftExtraContent={
                                    this.renderAddNewElementsButton()
                                }
                                hidePageSizeSelect={true}
                            />
                        ) : (
                            <SimpleOrderElementsNotFoundBlock>
                                {
                                    this.renderAddNewElementsButton({marginTop: 10})
                                }
                            </SimpleOrderElementsNotFoundBlock>
                        )
                    }
                </div>

                <OperationProblemModal
                    errors={problems}
                    visible={this.state.problemModalIsShowing}
                    type={errType}
                    isCorrection={this.props.operationTypeCode === OperationTypeCodeEnum.CORRECT}
                    onOk={this.operationProblemModalOnOk}
                    onCancel={this.operationProblemModalOnCancel}
                />

            </Spin>
        )
    }
}

const mapStateToProps = (storeState: IRootState) => {
    let pageParams = getStateFromPath(storeState.router.location.search),
        categoryIds = pageParams['categoryIds'] || undefined;

    return {
        operation: storeState.operation.entity,
        locationSearchParams: storeState.router.location.search,
        locationPathname: storeState.router.location.pathname,
        categoryIds: categoryIds,
        operationTypeCode: storeState.operationForm.typeCode,
        projectStateCode:
            storeState.operation.entity?.projectStateCode ||
            storeState.operation.entity?.subrentStateCode ||
            storeState.project.entity?.stateCode ||
            storeState.subrent.entity?.stateCode,
        canViewFinancialData: canViewFinancialData(storeState.permissions.permissions),
        currentOperationUUID: storeState.operationForm.currentOperationUUID,

        finalTotalPrice: storeState.operationForm.finalTotalPrice,
        finalTotalPriceWithTaxes: storeState.operationForm.finalTotalPriceWithTaxes,
        elements: storeState.operationForm.elements,
        projectTemplate: storeState.operationForm.projectTemplate,
        elementIsNowEditing: storeState.operationForm.elementIsNowEditing,
        operationCorrectionStateCode: storeState.operationForm.targetStateCode,
        shiftCountRoundingType: storeState.businessAccountPreferences.preferences?.shiftCountRoundingType,
        elementsCount: storeState.operationForm.elementsCount,
        elementsFilters: storeState.operationForm.elements.filters,
        operationFormElementsData: storeState.operationForm.elements,
        elementsPageParams: storeState.elements.params,
        renterId: storeState.operationForm.renterId,
        projectId: storeState.operationForm.projectId,
        operationForm_goBackPath: storeState.operationForm.goBackPath,
        businessAccountId: businessAccountIdSelector(storeState),
        isSimpleOrder: storeState.operationForm.isSimpleOrder || false
    };
};

const mapDispatchToProps = {
    push,
    fullResetOperation,
    removeConcurrentOperation,
    createEditOperation,
    resetOperation,
    setOperationElementsFilters,
    setElementsListParams,
    clearElements,
    createSimpleOrder: createOrEditSimpleOrder,
    setElementIsNowEditing,
    setSimpleOrderListParams
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export const OrderElementsListEdit = connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true})(
    injectIntl(Component, {forwardRef: true})
);


