import React, {FC, memo, useEffect, useMemo, useRef, useState} from 'react';
import {Card} from 'antd';
import Spin from '../../../../../components/spin/spin';
import {CalendarFilters} from '../../components/CalendarFilters/CalendarFilters';
import {usePageURLParams} from '../../../../../core/hooks/urlParams/usePageURLParams';
import {summaryScheduleCalendarFilters, summaryScheduleCalendarFiltersDescription} from './filters/summaryScheduleCalendarFilters';
import {useFiltersChanges} from './hooks/useFiltersChanges';
import {useCalendarQueryParams} from '../../components/Calendar/hooks/useCalendarQueryParams';
import Timeline, {TimelineHeaders} from 'react-calendar-timeline';
import {CalendarHeader} from '../../components/CalendarHeader/CalendarHeader';
import {CalendarMarker} from '../../components/CalendarMarker/CalendarMarker';
import {useDateHeaderUnits} from '../../components/Calendar/hooks/useDateHeaderUnits';
import {useIntervalClick} from '../../components/Calendar/hooks/useIntervalClick';
import {
    CALENDAR_LINE_HEIGHT_RATIO,
    CALENDAR_SIDEBAR_WIDTH_MEDIUM,
    CalendarDisplayType,
    CalendarVisibleItemsLimit,
} from '../../components/Calendar/utils/data/calendarConstants';
import {CalendarLineUtils} from '../../components/CalendarItemRenderer/utils/calendarLineUtils';
import 'react-calendar-timeline/lib/Timeline.css';
import '../../components/Calendar/Calendar.less';
import '../../components/Calendar/CalendarLib.less';
import {CalendarItemRenderer} from '../../components/CalendarItemRenderer/CalendarItemRenderer';
import {SummaryScheduleCalendarItemRenderer} from './components/ItemRenderer/summaryScheduleCalendarItemRenderer';
import {BASE_ITEM_CLASS} from '../../components/Calendar/utils/data/calendarClasses';
import {TimeRangeContext} from '../../components/CalendarItemRenderer/context/TimeRangeContext';
import {WrappedFormUtils} from 'antd/es/form/Form';
import {useAppDispatch, useAppSelector} from '../../../../../store/hooks';
import {businessAccountIdSelector} from '../../../../../shared/reducers/system.reducer';
import {SummaryScheduleCalendarGroupRenderer} from './components/groupRenderer/summaryScheduleCalendarGroupRenderer';
import {useDisableMouseScrollEffect} from '../../components/Calendar/hooks/useDisableMouseScrollEffect';
import classNames from 'classnames';
import {useCalendarData} from './hooks/useCalendarData';
import {CalendarPageTabsEnum} from '../../../../../shared/constants/tabEnums';
import {SummaryScheduleCalendarHeader} from "./components/header/summaryScheduleCalendarHeader";
import {loadCategories} from "../../../../../shared/reducers/entities.reducer";
import {CategoryTypeCodeEnum} from "../../../../../server";
import {useListSummaryScheduleQuery} from "./api/summaryScheduleCalendarApi";
import {summaryScheduleCalendarSortData} from "./data/sortData";
import {getNomenclatureKey11} from "./data/processCalendarData";
import {defaultScreenLeft, defaultScreenRight} from "../../components/Calendar/utils/data/calendarBoundaries";
import './summaryScheduleCalendar.less';
import {SummaryScheduleCalendarFooter} from "./components/footer/summaryScheduleCalendarFooter";

export const summaryScheduleCalendarPageName = CalendarPageTabsEnum.SUMMARY_SCHEDULE;
const sidebarWidth = CALENDAR_SIDEBAR_WIDTH_MEDIUM;

export const SummaryScheduleCalendar: FC = memo(() => {
    const dispatch = useAppDispatch();
    const businessAccountId = useAppSelector(businessAccountIdSelector);
    const scrollRef = useRef<HTMLDivElement>();
    const filtersFormRef = useRef<WrappedFormUtils>();
    const [visibleItemsLimit, setVisibleItemsLimit] = useState<CalendarVisibleItemsLimit>(CalendarVisibleItemsLimit.FIRST_LIMIT);
    const [collapsedNomenclatures, setCollapsedNomenclatures] = useState<string[]>([]);

    useEffect(() => {
        dispatch(loadCategories(businessAccountId, CategoryTypeCodeEnum.PRODUCT));
    }, []);

    const pageParamsObject = usePageURLParams({
        paramsDescription: summaryScheduleCalendarFiltersDescription,
        pageName: summaryScheduleCalendarPageName,
    });
    const {pageParams, updatePageParams} = pageParamsObject;

    const queryParams = useCalendarQueryParams({
        ...pageParams,
    });

    useEffect(()=>{
        setVisibleItemsLimit(CalendarVisibleItemsLimit.FIRST_LIMIT);
    }, [pageParams]);

    const _screenLeft = pageParams.screenLeft || defaultScreenLeft();
    const _screenRight = pageParams.screenRight || defaultScreenRight();

    const {data: {entities, totalCount} = {}, isFetching} = useListSummaryScheduleQuery(
        {
            businessAccountId,
            params: queryParams,
            limit: visibleItemsLimit,
            from: _screenLeft,
            until: _screenRight
        },
        {
            refetchOnReconnect: true,
            refetchOnMountOrArgChange: true
        }
    );

    const [displayType, setDisplayType] = useState<CalendarDisplayType>(pageParams.displayType ?? CalendarDisplayType.NORMAL);

    const {deferredItems: d, visibleTimeStart, visibleTimeEnd} = useCalendarData(
        entities,
        _screenLeft,
        _screenRight,
        isFetching,
        displayType,
        pageParams.group,
        pageParams.includeRentElements,
        collapsedNomenclatures
    );

    const lineHeight = CalendarLineUtils.map.lineHeightFromDisplayType[displayType];
    const onIntervalClick = useIntervalClick(updatePageParams);
    const {primaryDateHeaderUnit, secondaryDateHeaderUnit} = useDateHeaderUnits(visibleTimeStart, visibleTimeEnd);

    const timeRangeContextValue = useMemo(
        () => ({
            visibleTimeStart,
            visibleTimeEnd,
        }),
        [visibleTimeEnd, visibleTimeStart]
    );

    useDisableMouseScrollEffect(scrollRef);

    const _totalCount = totalCount || 0;
    const _visibleCount = visibleItemsLimit > _totalCount ? _totalCount : visibleItemsLimit;

    useEffect(() => {
        if (!isFetching) {
            setTimeout(() => {
                window.dispatchEvent(new Event('resize'));
            }, 50);
        }
    }, [isFetching]);

    return (
        <Spin wrapperClassName={'clickable-spin'} spinning={isFetching} delay={0}>
            <Card>
                <div className={classNames('calendar-filters-container')}>
                    <CalendarFilters
                        pageName={summaryScheduleCalendarPageName}
                        pageParamsDescription={summaryScheduleCalendarFiltersDescription}
                        pageParamsObject={pageParamsObject}
                        filtersData={summaryScheduleCalendarFilters}
                        useFiltersChanges={useFiltersChanges}
                        filtersFormRef={filtersFormRef}
                        hiddenFieldsForCount={['includeRentElements']}
                        resetCallback={() => {
                        }}
                    />
                </div>
                <div
                    className={classNames('calendar-timeline', 'accessibility', {
                        'not-found': false,//!accessibilityEntitySelected,,
                        'compact' : displayType === CalendarDisplayType.COMPACT
                    })}
                >
                    <TimeRangeContext.Provider value={timeRangeContextValue}>
                        <SummaryScheduleCalendarHeader
                            isLoading={isFetching}
                            pageParamsObject={pageParamsObject as any}
                            sortData={summaryScheduleCalendarSortData}
                            sortByValue={pageParams.group}
                            displayType={displayType}
                            setDisplayType={setDisplayType}
                            visibleCount={_visibleCount}
                            totalCount={_totalCount}
                            sidebarWidth={sidebarWidth}
                        />
                        <Timeline
                            canMove={false}
                            canResize={false}
                            groups={d.groups}
                            itemHeightRatio={CALENDAR_LINE_HEIGHT_RATIO}
                            items={d.items}
                            lineHeight={lineHeight}
                            sidebarWidth={sidebarWidth}
                            horizontalLineClassNamesForGroup={() => [BASE_ITEM_CLASS]}
                            scrollRef={(el) => (scrollRef.current = el)}
                            visibleTimeStart={visibleTimeStart}
                            visibleTimeEnd={visibleTimeEnd}
                            groupRenderer={({group}) => {
                                return (
                                    <SummaryScheduleCalendarGroupRenderer
                                        screenLeft={visibleTimeStart}
                                        screenRight={visibleTimeEnd} group={group}
                                        onCollapse={(collapsed) => {
                                            const key = getNomenclatureKey11(group);
                                            if (!collapsed) {
                                                setCollapsedNomenclatures([...collapsedNomenclatures, key]);
                                            } else {
                                                setCollapsedNomenclatures(collapsedNomenclatures.filter((k)=>k!==key));
                                            }
                                        }}
                                    />
                                )
                            }}
                            itemRenderer={CalendarItemRenderer({
                                itemRenderer: SummaryScheduleCalendarItemRenderer,
                                displayType,
                                onPopoverVisibleChange: () => {
                                },
                                onItemClick: () => {
                                },
                                groupsData: {},
                                sortByValue: undefined,
                            })}
                        >
                            <TimelineHeaders>
                                <CalendarHeader.Primary
                                    unit={primaryDateHeaderUnit}
                                    onIntervalClick={onIntervalClick}
                                    allNothing={!visibleTimeEnd || !visibleTimeStart}
                                />
                                <CalendarHeader.Secondary
                                    primaryUnit={primaryDateHeaderUnit}
                                    unit={secondaryDateHeaderUnit}
                                    onIntervalClick={onIntervalClick}
                                    allNothing={!visibleTimeEnd || !visibleTimeStart}
                                    sidebarWidth={sidebarWidth}
                                />
                            </TimelineHeaders>
                            {d.items?.length && <CalendarMarker/>}
                        </Timeline>
                    </TimeRangeContext.Provider>
                    {
                        (_totalCount > 0 && entities && entities.length < _totalCount) && <SummaryScheduleCalendarFooter totalCount={_totalCount} onClick={()=>{
                            setVisibleItemsLimit(CalendarVisibleItemsLimit.SECOND_LIMIT);
                        }} isFetching={isFetching} />
                    }
                </div>
            </Card>
        </Spin>
    );
});
