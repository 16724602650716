import React from 'react';
import {FormFieldsGroup, FormItemType} from '../../../../../components/dynamicForm/DynamicForm';
import {getStoreState} from '../../../../../../index';
import {LocalizationEnum} from '../../../../../localization';
import {canUpdateFinancialData, canViewFinancialData} from '../../../../../shared/util/permissionUtils';
import {DefaultTaxContent} from '../../../settings/businessAccountPreferences/businessAccountPreferencesEditModalFieldsData';
import {defaultTaxCalculationTypeField} from '../../../settings/businessAccountPreferences/businessAccountPreferencesEditModalFields';
import debounce from "lodash/debounce";
import {WrappedFormUtils} from "antd/lib/form/Form";
import {validateField} from "../../../../../shared/util/validateField";
import {createHiddenField} from "../../../../../components/modalForm/utils";

export const simpleOrderFormFields: FormFieldsGroup[] = [
    {
        fields: [
            createHiddenField('id'),
            createHiddenField('renterId'),
            {
                label: LocalizationEnum.ASPECT__FIELDS__COMMON__SHORT_TITLE,
                id: 'shortName',
                type: FormItemType.String,
                required: true,
                maxLength: 50,
                validationFunction: debounce((fieldName: string, value: any, cb: Function, form: WrappedFormUtils) => {
                    const renterId = form.getFieldValue('renterId');

                    if (renterId) {
                        validateField(
                            'project',
                            fieldName,
                            value,
                            renterId,
                            form.getFieldValue('id'),
                            'Краткое наименование уже используется в текущем арендаторе',
                            cb
                        );
                    } else cb();
                }, 500),
            },
        ]
    },
    {
        helpMessage: DefaultTaxContent,
        fields: [
            {
                label: LocalizationEnum.PAGE__PROJECTS__FORM__FIELD__TAX_PERCENT,
                id: 'taxRate',
                type: FormItemType.Tax,
                defaultValue: 0,
                required: true,
                disabled: (getFieldValue) => {
                    const p = getStoreState().permissions.permissions;
                    return canViewFinancialData(p) && !canUpdateFinancialData(p);
                },
                visible: (getFieldValue) => {
                    const p = getStoreState().permissions.permissions;
                    return canViewFinancialData(p);
                },
            },
            defaultTaxCalculationTypeField as any

        ],
    },
    {
        fields: [
            {
                label: LocalizationEnum.ASPECT__FIELDS__COMMON__COMMENT,
                id: 'comment',
                type: FormItemType.Text,
                maxLength: 2000,
            },
        ],
    }
];
