import React from 'react';
import { Button, Col, Icon, Row } from 'antd';
import moment from 'moment';
import {addElement, editElement, OperationElement, updateAnyTimetables} from '../../reducers/operationForm.reducer';
import { RangePickerValue } from 'antd/lib/date-picker/interface';
import { IRootState } from '../../../../../shared/reducers';
import { connect } from 'react-redux';
import { IconPlus } from '../../../../../components/icons';
import {
    InstanceInfoRead,
    InstanceTrackingTypeCodeEnum, NomenclatureEntityTypeCodeEnum,
    NomenclatureRecord,
    OperationTypeCodeEnum,
    RentStateCodeEnum,
} from '../../../../../server';
import { CustomRangePicker } from '../../../../../components/datePicker/CustomRangePicker';
import { logStackMap, ProductUtils } from '../../../../../shared/util/productUtils';
import { isNumber } from '../../../../../shared/util/isNumber';
import { LocalizationEnum, localize } from '../../../../../localization';
import { SelectValue } from 'antd/lib/select';
import { InlineMoneyInput } from '../../../../../components/inlineInputs/InlineMoneyInput/InlineMoneyInput';
import {
    canAddInstancesToExistedElement,
    canAddNewProductsToOperation, canAddNewProductsToOperation2,
    canBeErrorWhenShiftCountMoreThanPeriod,
    canBeErrorWhenShortage,
    canCreateOperationWithAnonymousInstances,
    canEditOperationElementParams,
    findTimeTable,
    getKeepLeftoverByElementStateCode,
    getLeftoverInstances, is_OperationTimeError,
    isOrderOperation,
    isSubrentOperation,
    OperationErrorCodeEnum,
    operationWithShortage,
} from '../../utils/utils';
import { PopoverContent } from '../../../../../components/popover/PopoverContent';
import { cbOnValueDefined, getShiftCountFromDates, joinClasses } from '../../../../../shared/util/utils';
import { ProductInstancesSelect } from '../product-instances-select/ProductInstancesSelect';
import { RecalculateShiftsCountButton } from '../../RecalculateShiftsCountButton';
import { InstanceQuickCreateModal } from './instance-create-modal';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { RentElementsGridItem } from '../../../../../types';
import { OperationFormUtils } from '../../utils/operationFormUtils';
import { getStore } from '../../../../../../index';
import { OperationParamResetButton } from '../../components/operationParamResetButton/OperationParamResetButton';
import { PricingSchemePricePopover } from '../../../settings/pricingSchemes/pricingSchemePricePopover';
import { canViewFinancialData } from '../../../../../shared/util/permissionUtils';
import { ActionTypeEnum } from '../../../../../core/types/ActionTypeEnum';
import { GridRowActions1 } from '../../../../../components/v2/gridActions/gridActions';
import { NumberInput, NumberInputDiscount, NumberInputShiftCount } from '../../../../../components/numberInputs';
import { PricingSchemeMaps, PricingSchemeUtils } from '../../../../../core/utils/pricingSchemeUtils';
import { MoneyUtils } from '../../../../../core/utils/moneyUtils';
import classNames from "classnames";
import {showNotification} from "../../../../../components/notification/showNotification";
import {
    findTimeTableById,
    NomenclatureBaseObj,
    NomenclatureOnInterval,
    rentElementToNomenclatureBaseObj,
    loadNomenclaturesOnIntervalsIfNeed,
    getDateIntervalsForCalendar
} from "../../utils/nomenclaturesUtils";
import {getIsElementReducesAvailability} from "../../utils/operationUtils";
import {RangePicker} from "../../../../../components/v2/calendar/rangePicker/rangePicker";

interface IProps extends StateProps, DispatchProps, WrappedComponentProps {
    instanceCount: number;
    availableInstanceCount: number;
    elementRecord: RentElementsGridItem;
    disabled?: boolean;
    onCloseCB: () => void;
    onCalendarVisibleChangeCB?: (visible: boolean) => void;
    mode: 'create' | 'edit' | 'copy';
}

interface IState {
    instanceCount?: number;
    discount: number;
    shiftCount: number;
    startDate?: Date;
    endDate?: Date;

    pricePerShift: number;
    discountChanged: boolean;
    rentPeriodChanged: boolean;
    shiftCountChanged: boolean;

    selectedInstances?: number[];
    anonymousInstanceCount?: number;

    productId: number;
    variantId?: number;
    instanceAddModalVisible: boolean;

    productRecord?: NomenclatureRecord;
    loading: boolean;
    priceChanged: boolean;
    saving: boolean;
    visible: boolean;
}

class Component extends React.PureComponent<IProps, IState> {
    private select;
    private select0;

    shouldComponentUpdate(nextProps:IProps, nextState) {
        if(!nextState.visible) return false;
        return true;
    }

    constructor(props) {
        super(props);
        //
        let shiftCount = this.props.elementRecord ? this.props.elementRecord.rentTerms.shiftCount : this.props.defaultShiftCount;

        let startDate = new Date(this.props.elementRecord?.rentTerms.rentPeriodStartDate || new Date()); //this.props.elementRecord ? this.props.elementRecord.rentPeriodStartDate : this.props.defaultRentPeriodStartDate;
        let endDate = new Date(this.props.elementRecord?.rentTerms.rentPeriodEndDate || new Date()); //this.props.elementRecord ? this.props.elementRecord.rentPeriodEndDate : this.props.defaultRentPeriodEndDate;

        let shiftCountChanged = true; //this.props.elementRecord ? this.props.elementRecord.shiftCountChanged : false;
        let discountChanged = true; //this.props.elementRecord ? this.props.elementRecord.discountChanged : false;
        let rentPeriodChanged = true; //this.props.elementRecord ? this.props.elementRecord.rentPeriodChanged : false;

        this.state = {
            selectedInstances: [],

            pricePerShift: this.props.elementRecord ? this.props.elementRecord.basePriceAtTheTimeOfCreation : 0,

            discount: this.props.elementRecord ? this.props.elementRecord.rentTerms.discount : this.props.defaultDiscount,
            shiftCount: shiftCount || 0,

            startDate: startDate,
            endDate: endDate,

            shiftCountChanged: shiftCountChanged,
            discountChanged: discountChanged,
            rentPeriodChanged: rentPeriodChanged,

            productId: /*this.state.productRecord ? this.state.productRecord.productId :*/ this.props.elementRecord
                ? this.props.elementRecord.productId
                : 0,
            variantId: /*this.state.productRecord ? this.state.productRecord.variantId :*/ this.props.elementRecord
                ? this.props.elementRecord.variantId
                : undefined,
            instanceAddModalVisible: false,
            anonymousInstanceCount: this.props.elementRecord ? this.props.elementRecord.anonymousInstanceCount : undefined,

            loading: true,
            priceChanged: false,
            saving: false,
            visible: true
        };
    }

    async componentDidMount() {
        try {
            await this.updateNomenclatures();
            const n = this.getNomenclatureForElement(this.props.elementRecord);
            if (n) this.setState({ loading: false, productRecord: n });
            else throw new Error('Nomenclature not found');
        } catch (e) {
            showNotification('error', 'Ошибка', 'Номенклатура не загружена');
            console.error(e);
            this.setState({ loading: false });
        }
    }

    componentDidUpdate = async (prevProps: IProps, prevState: IState) => {
        if(this.state.startDate !== prevState.startDate || this.state.endDate !== prevState.endDate /*|| this.state.selectedInstances !== prevState.selectedInstances*/){
            await this.updateNomenclatures();
        }
    };

    getNomenclatureForElement = (el:RentElementsGridItem|undefined) => {
        let nomenclatureData:NomenclatureBaseObj|undefined;
        if (el) nomenclatureData = rentElementToNomenclatureBaseObj(el);
        if(nomenclatureData){
            const tt = findTimeTableById(nomenclatureData.id, nomenclatureData.type, getStore().getState().operationForm.timeTables);
            return tt?.nomenclature ? tt.nomenclature as NomenclatureRecord : undefined;
        }
    };

    updateNomenclatures = async () => {
        const rentPeriodStartDate = this.state.startDate;
        const rentPeriodEndDate = this.state.endDate;

        let nomenclatureData:NomenclatureBaseObj|undefined;
        if (this.props.elementRecord) nomenclatureData = rentElementToNomenclatureBaseObj(this.props.elementRecord);
        const [from, until] = getDateIntervalsForCalendar(rentPeriodStartDate, rentPeriodEndDate);

        const nomenclatures:NomenclatureOnInterval[] = [];
        if(!nomenclatureData) throw new Error('Error');
        nomenclatures.push({...nomenclatureData, from, until});
        if(this.state.selectedInstances){
            this.state.selectedInstances.forEach((id)=>{
                nomenclatures.push({id, type: NomenclatureEntityTypeCodeEnum.INSTANCE, from, until});
            });
        }

        if(this.props.elementRecord.parent && this.props.elementRecord.parent.kitId){
            nomenclatures.push({id: this.props.elementRecord.parent.kitId, type: NomenclatureEntityTypeCodeEnum.KIT, from, until});
        }

        const data = await loadNomenclaturesOnIntervalsIfNeed(nomenclatures);
        getStore().dispatch(updateAnyTimetables(data.timetables, data.elementsDelayedReturnDates));
    };

    onInstanceCountChangedAnonymous = (value: number | undefined) => {
        let instanceCount = value && isNumber(value) ? +value : 0;
        let anonymousInstanceCount = instanceCount - (this.state.selectedInstances?.length || 0);
        this.setState({
            instanceCount: value && isNumber(value) ? +value : 0,
            anonymousInstanceCount: anonymousInstanceCount,
        });
    };

    onInstanceCountChanged = (value: number | undefined) => {
        this.setState({
            instanceCount: value && isNumber(value) ? +value : 0,
        });
    };

    onRentPeriodChanged = (dates: RangePickerValue) => {
        let startDate = dates[0] ? dates[0].toDate() : undefined;
        let endDate = dates[1] ? dates[1].toDate() : undefined;

        let shiftLengthInMin = this.state.productRecord
            ? this.state.productRecord.shiftLengthInMinutes
            : undefined; /*this.props.elementRecord ? this.props.elementRecord.shiftLengthInMinutes : undefined*/
        let diff =
            startDate && endDate
                ? getShiftCountFromDates(moment(startDate), moment(endDate), shiftLengthInMin, this.props.shiftCountRoundingType)
                : 0;
        let rentPeriodChanged =
            startDate?.valueOf() !== this.props.defaultRentPeriodStartDate?.valueOf() ||
            endDate?.valueOf() !== this.props.defaultRentPeriodEndDate?.valueOf();
        this.setState({
            rentPeriodChanged,
            startDate: startDate,
            endDate: endDate,
            shiftCount: this.props.recalculateShiftCount ? diff : this.state.shiftCount,
            shiftCountChanged: this.props.recalculateShiftCount ? true : this.state.shiftCountChanged,
        });
    };

    onShiftCountChanged = (value: number | undefined) => {
        let shiftCountChanged = this.props.defaultShiftCount !== value;
        if (this.props.autoRecalculateShiftsCountMode) {
            if (this.props.defaultShiftCount === value) shiftCountChanged = true;
        }
        this.setState({ shiftCount: value || 0, shiftCountChanged });
    };

    onDiscountChanged = (value: number | undefined) => {
        if (value !== undefined && value > 100) value = 100;
        this.setState({ discount: value || 0, discountChanged: this.props.defaultDiscount !== value });
    };

    onCancelClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        this.props.onCloseCB();
    };

    onClose = () => {
        this.props.onCloseCB();
    };

    onAddButtonClick = async (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        // Собрать инфу со всех полей
        this.setState({saving: true});
        try{
            let instanceCount = this.state.instanceCount === undefined ? this.props.instanceCount : this.state.instanceCount,
                anonymousInstanceCount =
                    this.state.anonymousInstanceCount === undefined
                        ? /*this.props.elementRecord?.anonymousInstanceCount*/ 0
                        : this.state.anonymousInstanceCount,
                rentPeriodEndDate = this.state.endDate,
                rentPeriodStartDate = this.state.startDate,
                discount = this.state.discount,
                shiftCount = this.state.shiftCount,
                pricePerShift = this.state.pricePerShift,
                shiftCountChanged = this.state.shiftCountChanged,
                discountChanged = this.state.discountChanged,
                rentPeriodChanged = this.state.rentPeriodChanged;

            let instanceTracked =
                this.state.productRecord?.instanceTrackingTypeCode === InstanceTrackingTypeCodeEnum.VARIANTINSTANCETRACKED ||
                this.state.productRecord?.instanceTrackingTypeCode === InstanceTrackingTypeCodeEnum.INSTANCETRACKED ||
                this.props.elementRecord?.instanceTrackingTypeCode === InstanceTrackingTypeCodeEnum.VARIANTINSTANCETRACKED ||
                this.props.elementRecord?.instanceTrackingTypeCode === InstanceTrackingTypeCodeEnum.INSTANCETRACKED;

            if (instanceTracked && this.state.selectedInstances) {
                instanceCount = this.state.selectedInstances.length + (anonymousInstanceCount || 0);
            }

            // if(this.props.defaultShiftCount === undefined) {
            //     let shiftLengthInMin = this.props.productRecord ? this.props.productRecord.shiftLengthInMinutes : (this.props.elementRecord ? this.props.elementRecord.shiftLengthInMinutes : undefined);
            //     let newShiftCount = this.props.defaultRentPeriodStartDate && this.props.defaultRentPeriodEndDate ? getShiftCountFromDates(moment(this.props.defaultRentPeriodStartDate), moment(this.props.defaultRentPeriodEndDate), shiftLengthInMin, this.props.shiftCountRoundingType) : 0;
            //     if(newShiftCount === shiftCount) shiftCountChanged = false;
            // }

            // Скачать и сохранить нужные карты
            await this.updateNomenclatures();

            if (this.props.mode === 'create' && rentPeriodStartDate && rentPeriodEndDate && this.props.elementRecord) {
                let el: OperationElement = {
                    id: this.props.elementRecord.id,
                    numberInActivityFrame: this.props.elementRecord.numberInActivityFrame,
                    businessVersion: this.props.elementRecord.businessVersion,
                    productId: this.props.elementRecord.productId,

                    productShortName: this.props.elementRecord.productShortName,
                    productMainImage: this.props.elementRecord.image, // ? {...this.state.productRecord.mainImage} : undefined,
                    stateCode: this.props.elementRecord.stateCode,
                    rentPeriodStartDate: rentPeriodStartDate,
                    rentPeriodEndDate: rentPeriodEndDate,
                    shiftCount: shiftCount,
                    calendarShiftCount: shiftCount,
                    discount: discount,
                    // Доступно 100/1000
                    instanceCount: instanceCount,
                    instanceCountOriginal: this.props.elementRecord.instanceCount,
                    finalTotalPrice: 0,

                    effectivePrice: pricePerShift,

                    //stockInstanceCountX: 0, //this.state.productRecord.stockInstanceCount,
                    availableInstanceCount: 0, //this.state.productRecord.availableInstanceCount,

                    discountChanged: discountChanged,
                    rentPeriodChanged: rentPeriodChanged,
                    shiftCountChanged: shiftCountChanged,
                    disabled: false,
                    variantId: this.props.elementRecord.variantId,
                    instanceIds:
                        this.state.selectedInstances && this.state.selectedInstances.length > 0 ? this.state.selectedInstances : undefined,
                    instanceIdsOriginal: this.props.elementRecord.instanceIds,

                    variantName: this.props.elementRecord.variantName,
                    instanceTrackingTypeCode: this.props.elementRecord.instanceTrackingTypeCode,

                    rentPeriodStartDateOriginal: new Date(this.props.elementRecord.rentTerms.rentPeriodStartDate),
                    rentPeriodEndDateOriginal: new Date(this.props.elementRecord.rentTerms.rentPeriodEndDate),
                    mainImage: this.props.elementRecord.image, //this.state.productRecord.mainImage ? {...this.state.productRecord.mainImage} : undefined,

                    anonymousInstanceCount:
                        this.props.elementRecord.instanceTrackingTypeCode == InstanceTrackingTypeCodeEnum.INSTANCETRACKED ||
                        this.props.elementRecord.instanceTrackingTypeCode == InstanceTrackingTypeCodeEnum.VARIANTINSTANCETRACKED
                            ? anonymousInstanceCount || 0
                            : 0 /*instanceCount*/,
                    anonymousInstanceCountOriginal: this.props.elementRecord.anonymousInstanceCount, //this.props.elementRecord.instanceTrackingTypeCode == InstanceTrackingTypeCodeEnum.INSTANCETRACKED || this.props.elementRecord.instanceTrackingTypeCode == InstanceTrackingTypeCodeEnum.VARIANTINSTANCETRACKED ? anonymousInstanceCount || 0 : instanceCount,

                    leftoverInstanceCount: 0,
                    leftoverAnonymousInstanceCount: 0,
                    keepLeftover: getKeepLeftoverByElementStateCode(this.props.elementRecord.stateCode),
                    projectId: this.props.elementRecord.projectId,
                    templateId: this.props.elementRecord.templateId,
                    renterId: this.props.elementRecord.renterId,

                    kitId: undefined,
                    warnings: [],
                    problems: [],
                    partOfKit: false,

                    variantIdOriginal: this.props.elementRecord.variantId,
                    shiftLengthInMinutes: this.state.productRecord?.shiftLengthInMinutes || undefined, //this.props.elementRecord.shiftLengthInMinutes,
                    hasOwnShiftLength: this.state.productRecord?.productHasOwnShiftLength || undefined, //false,//this.props.elementRecord.productHasOwnShiftLength,
                    requiredTimeIndentBetweenElementsInMinutes:
                        this.state.productRecord?.requiredTimeIndentBetweenElementsInMinutes || undefined, //this.props.elementRecord.requiredTimeIndentBetweenElementsInMinutes,
                    productHasOwnRequiredTimeIndentBetweenElements:
                        this.state.productRecord?.productHasOwnRequiredTimeIndentBetweenElements || undefined, //false,//this.props.elementRecord.productHasOwnRequiredTimeIndentBetweenElements,
                    externalCode: this.props.elementRecord.externalCode,

                    availableTransitionCodes: this.props.elementRecord.availableTransitionCodes,

                    pricingSchemeId: !this.state.priceChanged ? this.props.elementRecord?.effectivePricingScheme : undefined,
                };

                await this.setState({visible: false});
                if (
                    this.props.elementRecord.parent &&
                    this.props.elements.find((el) => this.props.elementRecord.parent && el.id === this.props.elementRecord.parent.id)
                ) {
                    el.parentId = this.props.elementRecord.parent.id;
                    await getStore().dispatch(addElement(el));
                    this.props.onCloseCB();
                } else {
                    const kitN = this.getNomenclatureForElement(this.props.elementRecord.parent);
                    if (kitN && this.props.elementRecord.parent) {
                        let kitEl = OperationFormUtils.createOperationElementFromElement(this.props.elementRecord.parent, kitN);
                        el.parentId = this.props.elementRecord.parent.id;
                        this.props.addElement([kitEl, el]);
                    } else throw new Error('Nomenclature not found');
                    this.props.onCloseCB();
                }
            }
        }catch(error){
            showNotification('error', 'Ошибка');
            console.error(error);
        }
        this.setState({saving: false});
    };

    resetPeriod = (e) => {
        this.setState({
            rentPeriodChanged: false,
            startDate: this.props.defaultRentPeriodStartDate,
            endDate: this.props.defaultRentPeriodEndDate,
            shiftCount: this.props.defaultShiftCount || 0,
            shiftCountChanged: false,
        });
    };

    onSelectChange = (value: SelectValue, option: React.ReactElement<any> | React.ReactElement<any>[], metaKey: boolean) => {
        if (metaKey) {
            this.setState({
                selectedInstances: value as number[],
                instanceCount: ((value as number[]).length || 0) + (this.state.anonymousInstanceCount || 0),
            });
        } else {
            let anonymousInstanceCount = 0;
            let instanceCount = this.state.instanceCount === undefined ? this.props.instanceCount : this.state.instanceCount;
            const anonymous =
                this.state.anonymousInstanceCount !== undefined
                    ? this.state.anonymousInstanceCount
                    : this.props.elementRecord?.anonymousInstanceCount || 0;
            const diff = (value as number[]).length - (this.state.selectedInstances?.length || 0);
            if (diff > 0) {
                //добавили
                if (anonymous > 0) {
                    anonymousInstanceCount = anonymous - diff;
                    if (anonymousInstanceCount < 0) {
                        anonymousInstanceCount = 0;
                    }
                } else {
                    anonymousInstanceCount = 0;
                    instanceCount = instanceCount + diff;
                }
            } else if (diff < 0) {
                //убрали
                anonymousInstanceCount = anonymous + 1;
            }

            this.setState({
                selectedInstances: value as number[],
                anonymousInstanceCount: anonymousInstanceCount,
                instanceCount: instanceCount,
            });
        }
    };

    getRef = (ref) => {
        if (!this.select) this.select = ref;
    };

    onAddInstanceButtonClick = () => {
        this.setState({
            instanceAddModalVisible: true,
        });
    };

    render() {
        console.log('OperationElementCreatePopoverContent render()', this.props, this.state);

        let { canViewFinancialData } = this.props;

        let pricingScheme = this.props.elementRecord?.effectivePricingScheme;
        if (this.state.priceChanged) pricingScheme = undefined;

        let hasWarning = false;
        let hasProblem = false;
        let applyButtonStyles = '';

        const instanceTracked =
            this.state.productRecord?.instanceTrackingTypeCode === InstanceTrackingTypeCodeEnum.VARIANTINSTANCETRACKED ||
            this.state.productRecord?.instanceTrackingTypeCode === InstanceTrackingTypeCodeEnum.INSTANCETRACKED ||
            this.props.elementRecord?.instanceTrackingTypeCode === InstanceTrackingTypeCodeEnum.VARIANTINSTANCETRACKED ||
            this.props.elementRecord?.instanceTrackingTypeCode === InstanceTrackingTypeCodeEnum.INSTANCETRACKED;

        let rentPeriodStartDate = this.state.startDate,
            rentPeriodEndDate = this.state.endDate;

        const discount = this.state.discount;
        const shiftCount = this.state.shiftCount;

        const pricePerShiftOriginal = this.props.elementRecord ? this.props.elementRecord.basePriceAtTheTimeOfCreation : 0;
        const pricePerShift = pricingScheme
            ? PricingSchemeUtils.calculatePriceByPricingScheme({
                  shiftCount,
                  basePrice: pricePerShiftOriginal,
                  pricingScheme,
              })
            : this.state.pricePerShift;

        let shiftCountChanged = this.state.shiftCountChanged;
        let discountChanged = this.state.discountChanged;
        let rentPeriodChanged = this.state.rentPeriodChanged;

        let shiftLengthInMin = this.state.productRecord?.shiftLengthInMinutes;
        let requiredTimeIndentBetweenElementsInMinutes = this.state.productRecord?.requiredTimeIndentBetweenElementsInMinutes;
        let productHasOwnRequiredTimeIndentBetweenElements = this.state.productRecord?.productHasOwnRequiredTimeIndentBetweenElements;

        let intervals: [number, number][] = [];

        if (
            this.state.productRecord &&
            this.props.startDateFromList &&
            this.props.endDateFromList &&
            this.state.startDate === undefined &&
            this.state.endDate === undefined
        ) {
            let rentPeriodStartDateMoment = moment(this.props.startDateFromList);
            let rentPeriodEndDateMoment = moment(this.props.endDateFromList);
            if (
                this.props.defaultRentPeriodStartDate &&
                rentPeriodStartDateMoment.valueOf() !== this.props.defaultRentPeriodStartDate.getTime() &&
                this.props.defaultRentPeriodEndDate &&
                rentPeriodEndDateMoment.valueOf() !== this.props.defaultRentPeriodEndDate.getTime()
            )
                rentPeriodChanged = true;
            if (this.props.defaultShiftCount !== shiftCount) shiftCountChanged = true;

            rentPeriodStartDate = rentPeriodStartDateMoment.toDate();
            rentPeriodEndDate = rentPeriodEndDateMoment.toDate();
        }

        let includeInstanceIds =
            this.props.elementRecord?.instanceIds &&
            this.props.elementRecord?.id >= 0 &&
            !canAddInstancesToExistedElement(this.props.operationTypeCorrectionCode || this.props.operationTypeCode)
                ? this.props.elementRecord.instanceIds
                : undefined;

        //
        let shiftCountWarning = false;
        if (
            canBeErrorWhenShiftCountMoreThanPeriod(this.props.operationTypeCode, this.props.operationTypeCorrectionCode) &&
            shiftCount >
                getShiftCountFromDates(
                    moment(rentPeriodStartDate),
                    moment(rentPeriodEndDate),
                    shiftLengthInMin,
                    this.props.shiftCountRoundingType
                )
        )
            shiftCountWarning = true;

        // Можем ли создать/Изменить обязательство с нехваткой
        // Допустима_нехватка = тип_операции IN (корректировка, заявка, возвратЫ)
        const canSaveWithShortage = operationWithShortage(this.props.operationTypeCode);

        // Кол-во Анонимных
        const anonymousInstanceCount = this.state.anonymousInstanceCount === undefined ? 0 : this.state.anonymousInstanceCount;
        // Кол-во экземпляров
        let instanceCount = this.state.instanceCount === undefined ? this.props.instanceCount : this.state.instanceCount;
        if (instanceTracked) instanceCount = (this.state.selectedInstances?.length || 0) + (anonymousInstanceCount || 0);

        let availableInstanceCount = 0;
        let availableInstanceCountX: number | undefined;

        let timeTable = findTimeTable(this.props.timeTables, {
            productId: this.state.productId,
            variantId: this.state.variantId,
        });

        let availabilityFactor = this.props.isSubrentOperation ? -1 : 1;

        if (timeTable && rentPeriodStartDate && rentPeriodEndDate) {
            let map = isOrderOperation(this.props.operationTypeCode) ? timeTable.orderAvailable : timeTable.available1;

            logStackMap(
                {
                    productId: timeTable.productId,
                    kitId: timeTable.kitId,
                    variantId: timeTable.variantId,
                    map: map,
                    comment: 'Карта в попапе, начальное состояние',
                },
                true
            );

            intervals = map;

            const requiredIndent = (requiredTimeIndentBetweenElementsInMinutes || 0) * 60 * 1000;

            let rentPeriodStartDate1 = rentPeriodStartDate;
            let rentPeriodEndDate1 = rentPeriodEndDate;

            if (!this.props.elementRecord || (this.props.elementRecord && this.props.mode === 'copy')) {
                // Создание в продуктах или копирование в Инвентаре

                let availableIntervals = ProductUtils.getNewIntervals(intervals, []);

                availableInstanceCount = ProductUtils.getMinimumValueInInterval(
                    availableIntervals /*intervals*/,
                    rentPeriodStartDate1.getTime() - requiredIndent,
                    rentPeriodEndDate1.getTime() + requiredIndent
                );

                // Вычитаем столько сколько хотим добавить
                intervals = ProductUtils.getNewIntervals(intervals, [
                    [availabilityFactor * instanceCount, rentPeriodStartDate1.getTime(), rentPeriodEndDate1.getTime()],
                ]);
            } else if (this.props.elementRecord && this.props.elementRecord.id < 0) {
                // Для НОВОГО нужно компенсировать что вычли из карты
                intervals = ProductUtils.getNewIntervals(intervals, [
                    // Компенсируем что было в начале
                    [
                        availabilityFactor * -this.props.elementRecord.instanceCount,
                        new Date(this.props.elementRecord.rentTerms.rentPeriodStartDate).getTime(),
                        new Date(this.props.elementRecord.rentTerms.rentPeriodEndDate).getTime(),
                    ],
                ]);

                let availableIntervals = ProductUtils.getNewIntervals(intervals, []);

                // Редактирование нового
                availableInstanceCount = ProductUtils.getMinimumValueInInterval(
                    availableIntervals,
                    rentPeriodStartDate1.getTime() - requiredIndent,
                    rentPeriodEndDate1.getTime() + requiredIndent
                );

                // Вычитаем столько сколько хотим добавить
                intervals = ProductUtils.getNewIntervals(intervals, [
                    [availabilityFactor * instanceCount, rentPeriodStartDate1.getTime(), rentPeriodEndDate1.getTime()],
                ]);
            } else if (
                this.props.elementRecord &&
                this.props.elementRecord.rentTerms.rentPeriodStartDate &&
                this.props.elementRecord.rentTerms.rentPeriodEndDate
            ) {
                // Редактирование существующего
                // Для заявки и черновика нужно компенсировать что пришло с сервера

                let keepLeftover = getKeepLeftoverByElementStateCode(this.props.elementRecord.stateCode);

                let elementIntervals: [number, number, number, string][] = [];

                let isElementReducesAvailability = getIsElementReducesAvailability(this.props.elementRecord);
                let isCorrectCancelOperation =
                    this.props.operationTypeCode === OperationTypeCodeEnum.CORRECT &&
                    this.props.operationTypeCorrectionCode === RentStateCodeEnum.CANCELED;

                let leftoverInstances = getLeftoverInstances(this.props.elementRecord.instanceIds, this.props.elementRecord.instanceIds);
                elementIntervals.push([
                    availabilityFactor * (isCorrectCancelOperation ? 0 : 1) * -this.props.elementRecord.instanceCount,
                    new Date(this.props.elementRecord.rentTerms.rentPeriodStartDate).getTime(),
                    new Date(this.props.elementRecord.rentTerms.rentPeriodEndDate).getTime(),
                    'Компенсируем текущее значение',
                ]);
                if (keepLeftover)
                    elementIntervals.push([
                        availabilityFactor *
                            ((isElementReducesAvailability || this.props.elementRecord.stateCode === RentStateCodeEnum.ORDERED) &&
                            this.props.elementRecord.variantId === this.props.elementRecord.variantId
                                ? 1
                                : 0) *
                            -Math.max(
                                0,
                                this.props.elementRecord.instanceCount - this.props.elementRecord.instanceCount,
                                leftoverInstances.length
                            ),
                        new Date(this.props.elementRecord.rentTerms.rentPeriodStartDate).getTime(),
                        new Date(this.props.elementRecord.rentTerms.rentPeriodEndDate).getTime(),
                        'Компенсируем остаток' +
                            [availabilityFactor, isElementReducesAvailability, this.props.elementRecord.variantId === this.props.elementRecord.variantId ? 1 : 0],
                    ]);

                elementIntervals.forEach((item) => {
                    if (this.props.elementRecord) {
                        logStackMap({
                            productId: this.props.elementRecord.productId,
                            kitId: this.props.elementRecord.kitId,
                            variantId: this.props.elementRecord.variantId,
                            intervals: [[item[0], item[1], item[2]]],
                            comment: item[3],
                        });
                    }
                });

                intervals = ProductUtils.getNewIntervals(intervals, elementIntervals);

                availableInstanceCount = ProductUtils.getMinimumValueInInterval(
                    intervals,
                    rentPeriodStartDate1.getTime() - requiredIndent,
                    rentPeriodEndDate1.getTime() + requiredIndent
                );

                elementIntervals = [];
                elementIntervals.push([
                    availabilityFactor * (isCorrectCancelOperation ? 0 : 1) * instanceCount,
                    rentPeriodStartDate1.getTime(),
                    rentPeriodEndDate1.getTime(),
                    'Вычитаем текущее значение',
                ]);

                leftoverInstances = getLeftoverInstances(this.props.elementRecord.instanceIds, this.state.selectedInstances);
                if (keepLeftover)
                    elementIntervals.push([
                        availabilityFactor *
                            ((isElementReducesAvailability || this.props.elementRecord.stateCode === RentStateCodeEnum.ORDERED) &&
                            this.props.elementRecord.variantId === this.props.elementRecord.variantId
                                ? 1
                                : 0) *
                            Math.max(0, this.props.elementRecord.instanceCount - instanceCount, leftoverInstances.length),
                        new Date(this.props.elementRecord.rentTerms.rentPeriodStartDate).getTime(),
                        new Date(this.props.elementRecord.rentTerms.rentPeriodEndDate).getTime(),
                        'Вычитаем остаток' +
                            [
                                this.props.elementRecord.instanceCount - instanceCount,
                                leftoverInstances.length,
                                availabilityFactor,
                                isElementReducesAvailability,
                                this.props.elementRecord.stateCode,
                            ],
                    ]);

                elementIntervals.forEach((item) => {
                    if (this.props.elementRecord) {
                        logStackMap({
                            productId: this.props.elementRecord.productId,
                            kitId: this.props.elementRecord.kitId,
                            variantId: this.props.elementRecord.variantId,
                            intervals: [[item[0], item[1], item[2]]],
                            comment: item[3],
                        });
                    }
                });

                intervals = ProductUtils.getNewIntervals(intervals, elementIntervals);

                availableInstanceCountX = ProductUtils.getMinimumValueInInterval(
                    intervals,
                    rentPeriodStartDate1.getTime() - requiredIndent,
                    rentPeriodEndDate1.getTime() + requiredIndent
                );
            }

            logStackMap(
                {
                    productId: timeTable.productId,
                    kitId: timeTable.kitId,
                    variantId: timeTable.variantId,
                    map: intervals,
                    comment: 'Карта в попапе, конечное состояние',
                },
                true
            );
        }

        //
        let isCreatedOperation = this.props.elementRecord && this.props.elementRecord.id >= 0;

        // Если кол-во доступных < 0 и НЕ Допустима_нехватка, то кол-во ставим в 0
        //if (availableInstanceCount < 0 && !canSaveWithShortage && !instanceTracked) instanceCount = 0;

        //
        let instanceCountMin = instanceTracked ? this.state.selectedInstances?.length || 0 : 0;

        //
        let instanceCountMax =
            this.props.operationTypeCode === OperationTypeCodeEnum.DRAFT ||
            this.props.operationTypeCode === OperationTypeCodeEnum.ORDER ||
            this.props.operationTypeCode === OperationTypeCodeEnum.BOOK ||
            this.props.operationTypeCode === OperationTypeCodeEnum.RENT ||
            this.props.operationTypeCode === OperationTypeCodeEnum.CORRECT ||
            anonymousInstanceCount !== undefined
                ? undefined
                : availableInstanceCount <= 0
                ? 0
                : availableInstanceCount;
        if (
            this.props.operationTypeCode === OperationTypeCodeEnum.SUBRENTDRAFT ||
            this.props.operationTypeCode === OperationTypeCodeEnum.SUBRENTBOOKSHIPMENT ||
            this.props.operationTypeCode === OperationTypeCodeEnum.SUBRENTACCEPTSHIPMENT
        ) {
            instanceCountMax = undefined;
        }

        if (isCreatedOperation) {
            if (
                !canAddInstancesToExistedElement(this.props.operationTypeCorrectionCode || this.props.operationTypeCode) ||
                this.props.operationTypeCode === OperationTypeCodeEnum.SUBRENTRETURNTOSHIPPER ||
                this.props.operationTypeCode === OperationTypeCodeEnum.SUBRENTCANCEL ||
                this.props.operationTypeCode === OperationTypeCodeEnum.SUBRENTPROLONG
            ) {
                instanceCountMax = this.props.elementRecord?.instanceCount;
            }
        }

        // Есть нехватка
        let isShortage = !this.props.isSubrentOperation && instanceCount > availableInstanceCount;
        // Можем ли создать/Изменить обязательство
        // ЛОГИКА РАБОТЫ: сохранить можно == кол-во != 0 && (допустима нехватка || кол-во <= кол-во_доступных)
        let canSave = instanceCount > 0; // && (canSaveWithShortage || !isShortage);

        // Это #1025 и #1010 (3, 4)
        if (isCreatedOperation && instanceTracked && availableInstanceCountX !== undefined) {
            if (isOrderOperation(this.props.operationTypeCode)) {
                //isShortage = (instanceCount > availableInstanceCountX);
                isShortage = availableInstanceCountX < 0;
            } else {
                isShortage = availableInstanceCountX < 0;
            }
            canSave = instanceCount > 0; // && (canSaveWithShortage || !isShortage);
        }

        // Название класса, для подсветки поля ввода кол-ва экземпляров
        let headerClassName;
        const err = canBeErrorWhenShortage(this.props.operationTypeCode, this.props.operationTypeCorrectionCode, undefined); // TODO EDIT !!!
        if (err && err === OperationErrorCodeEnum.WARNING && isShortage) {
            headerClassName = 'has-warning'; // Когда Допустима_нехватка и есть нехватка
            hasWarning = true;
        } else if (err && err === OperationErrorCodeEnum.PROBLEM && (instanceCount <= 0 || isShortage)) {
            headerClassName = 'has-error'; // Когда кол-во меньше или 0 или Когда НЕ Допустима_нехватка и есть нехватка
            hasProblem = true;
        }

        let anonymousProblem = false;

        if (
            instanceTracked &&
            !canCreateOperationWithAnonymousInstances(this.props.operationTypeCorrectionCode || this.props.operationTypeCode)
        ) {
            if (anonymousInstanceCount !== undefined && anonymousInstanceCount > 0) {
                anonymousProblem = true;
            }
        }

        // Цена без скидки, цена смены, цена со скидкой
        let priceWithoutDiscount = MoneyUtils.calculateFinalTotalPrice(pricePerShift, instanceCount, shiftCount),
            shiftPrice = MoneyUtils.calculateFinalInstancePrice(pricePerShift, discount),
            finalTotalPrice = MoneyUtils.calculateFinalTotalPrice(shiftPrice, instanceCount, shiftCount);

        let dateIntervalsProblem = false;
        let calendarValue: any;
        if (rentPeriodEndDate && rentPeriodStartDate) {
            dateIntervalsProblem = is_OperationTimeError(rentPeriodStartDate.getTime(), rentPeriodEndDate.getTime());
            if (dateIntervalsProblem) {
                canSave = false;
            }
        }

        let disableAllElements = canEditOperationElementParams(this.props.operationTypeCode);

        if (this.props.operationTypeCode === OperationTypeCodeEnum.CORRECT) {
            instanceCountMax = undefined;
        }

        let isCancelOperation =
            this.props.operationTypeCode === OperationTypeCodeEnum.CANCEL ||
            this.props.operationTypeCorrectionCode === RentStateCodeEnum.CANCELED;

        let newShiftCount =
            this.state.startDate && this.state.endDate
                ? getShiftCountFromDates(
                      moment(this.state.startDate),
                      moment(this.state.endDate),
                      shiftLengthInMin,
                      this.props.shiftCountRoundingType
                  )
                : 0;

        let hasOwnShiftLength = this.state.productRecord
            ? this.state.productRecord.productHasOwnShiftLength
            : /*this.props.elementRecord?.hasOwnShiftLength*/ false;

        const productId = this.state.productRecord?.productId || this.props.elementRecord?.productId || 0;
        const variantId = this.state.productRecord?.variantId || this.props.elementRecord?.variantId;
        const productBusinessVersion = this.state.productRecord?.businessVersion || this.props.elementRecord?.businessVersion || 0;

        let elemRecord = {
            id: this.props.elementRecord.id,
            instanceIds: [...(this.props.elementRecord.instanceIds || [])],
            rentPeriodStartDate: new Date(this.props.elementRecord.rentTerms.rentPeriodStartDate),
            rentPeriodEndDate: new Date(this.props.elementRecord.rentTerms.rentPeriodEndDate),
            rentPeriodStartDateOriginal: new Date(this.props.elementRecord.rentTerms.rentPeriodStartDate),
            rentPeriodEndDateOriginal: new Date(this.props.elementRecord.rentTerms.rentPeriodEndDate),
            stateCode: this.props.elementRecord.stateCode,
            variantId: this.props.elementRecord.variantId,
            variantIdOriginal: this.props.elementRecord.variantId,
            instanceIdsOriginal: [...(this.props.elementRecord.instanceIds || [])],
            keepLeftover: getKeepLeftoverByElementStateCode(this.props.elementRecord.stateCode),
        } as OperationElement;

        if (shiftCountWarning && !this.props.projectTemplate) hasWarning = true;
        if (instanceTracked && anonymousInstanceCount !== undefined && anonymousInstanceCount > 0) {
            if (!canCreateOperationWithAnonymousInstances(this.props.operationTypeCorrectionCode || this.props.operationTypeCode)) {
                hasProblem = true;
            } else {
                //hasWarning = true;
            }
        }

        if (anonymousProblem && !hasProblem) hasWarning = true;

        if(!timeTable){
            hasWarning = false;
            hasProblem = false;
            canSave = false;
            headerClassName = undefined;
        }

        if (hasProblem) applyButtonStyles = 'rr-operationForm-element-create-popover_apply-button_with-problem';
        else if (hasWarning) applyButtonStyles = 'rr-operationForm-element-create-popover_apply-button_with-warning';
        if (!canSave) applyButtonStyles = '';

        if (this.props.defaultShiftCount === undefined || this.props.autoRecalculateShiftsCountMode) {
            if (newShiftCount === shiftCount) shiftCountChanged = false;
        }

        return (
            <PopoverContent
                spinning={this.state.loading}
                onClose={this.onClose}
                headerClassName={headerClassName}
                header={
                    <>
                        <span
                            style={{
                                marginRight: 17 + 18,
                                fontSize: 16,
                                fontWeight: 700,
                            }}
                        >
                            {this.props.mode !== 'copy' && this.props.elementRecord && this.props.elementRecord.id > -1
                                ? `Обязательство #${this.props.elementRecord?.numberInActivityFrame}`
                                : `Новое обязательство`}
                        </span>
                        {
                            <>
                                <NumberInput
                                    onChange={instanceTracked ? this.onInstanceCountChangedAnonymous : this.onInstanceCountChanged}
                                    parser={(v) => ('' + v).replace(/[^\d]/g, '')}
                                    min={instanceCountMin}
                                    max={instanceCountMax}
                                    value={instanceCount}
                                    className={'rr-input-number'}
                                    style={{ width: 92 }}
                                />
                                {!isCancelOperation && !this.props.isSubrentOperation && (
                                    <>
                                        <span
                                            style={{
                                                fontWeight: 400,
                                                marginLeft: 2,
                                                fontSize: 16,
                                            }}
                                        >
                                            {localize(LocalizationEnum.ASPECT__GLOBAL__SUBSET_TERM_OF)}
                                        </span>
                                        <span>&nbsp;</span>
                                        <span
                                            style={{
                                                fontWeight: 700,
                                                fontSize: 17,
                                            }}
                                        >
                                            {dateIntervalsProblem ? '?' : availableInstanceCount}
                                        </span>
                                        <span>&nbsp;</span>
                                        <span
                                            style={{
                                                fontWeight: 400,
                                                fontSize: 16,
                                            }}
                                        >
                                            {!this.props.projectTemplate ? `доступных` : `в инвентаре`}
                                        </span>
                                    </>
                                )}
                                {isCreatedOperation && !isCancelOperation && !this.props.isSubrentOperation ? (
                                    <span
                                        style={{
                                            fontWeight: 400,
                                            fontSize: 16,
                                        }}
                                    >
                                        &nbsp;{`(${this.props.elementRecord?.instanceCount} в обяз.)`}
                                    </span>
                                ) : null}
                                {(isCancelOperation || (this.props.isSubrentOperation && isCreatedOperation)) && (
                                    <>
                                        <span
                                            style={{
                                                fontWeight: 400,
                                                marginLeft: 2,
                                                fontSize: 16,
                                            }}
                                        >
                                            {localize(LocalizationEnum.ASPECT__GLOBAL__SUBSET_TERM_OF)}
                                        </span>
                                        <span>&nbsp;</span>
                                        <span
                                            style={{
                                                fontWeight: 700,
                                                fontSize: 17,
                                            }}
                                        >
                                            {this.props.elementRecord?.instanceCount}
                                        </span>
                                        <span>&nbsp;</span>
                                        <span style={{ fontWeight: 400, fontSize: 16 }}>{`в обязательстве`}</span>
                                    </>
                                )}
                            </>
                        }
                    </>
                }
                footer={
                    <>
                        <Button className={'rr-btn-default'} onClick={this.onCancelClick}>
                            {localize(LocalizationEnum.ASPECT__GLOBAL__CANCEL)}
                        </Button>
                        <Button loading={this.state.saving} disabled={!canSave} type={'primary'} onClick={this.onAddButtonClick} className={applyButtonStyles}>
                            {'Применить'}
                        </Button>
                    </>
                }
                content={
                    <>
                        <Row style={{ padding: 32 }}>
                            {instanceTracked ? (
                                <Col className="rr-form-item">
                                    <div className="rr-label" style={{ marginBottom: 10, height: 20 }}>
                                        <span style={{ display: 'inline-block', marginTop: 2 }}>{'Экземпляры'}</span>
                                        <GridRowActions1
                                            actions={[ActionTypeEnum.REPLACE_ANONYMOUS_INSTANCES_WITH_AVAILABLE__IN_RENT_ELEMENT]}
                                            onAction={(type) => {
                                                if (type === ActionTypeEnum.REPLACE_ANONYMOUS_INSTANCES_WITH_AVAILABLE__IN_RENT_ELEMENT) {
                                                    this.select0.replaceAnonymous(this.state.anonymousInstanceCount);
                                                } else {
                                                }
                                            }}
                                        >
                                            {anonymousInstanceCount !== undefined && anonymousInstanceCount > 0 ? (
                                                <span
                                                    style={{
                                                        color:
                                                            headerClassName === 'has-error' || anonymousProblem
                                                                ? '#f8516c'
                                                                : headerClassName === 'has-warning'
                                                                ? '#ff8d3a'
                                                                : '#525891',
                                                        marginLeft: 120,
                                                        textDecoration: 'underline',
                                                        cursor: 'pointer',
                                                    }}
                                                >
                                                    <span
                                                        style={{
                                                            fontWeight: 600,
                                                            fontSize: 16,
                                                        }}
                                                    >
                                                        {anonymousInstanceCount}?
                                                    </span>{' '}
                                                    {localize(LocalizationEnum.ASPECT__PLURAL__ANONYMOUS, undefined, {
                                                        value: anonymousInstanceCount,
                                                    })}
                                                </span>
                                            ) : null}
                                        </GridRowActions1>
                                    </div>
                                    <div className="rr-value">
                                        <div style={{ display: 'flex' }}>
                                            <div style={{ flexGrow: 1 }}>
                                                <ProductInstancesSelect
                                                    ref={(ref) => {
                                                        if (!this.select0) this.select0 = ref;
                                                    }}
                                                    ref1={this.getRef}
                                                    elementRecord={elemRecord}
                                                    variantId={this.state.productRecord?.variantId || this.props.elementRecord?.variantId}
                                                    productId={productId}
                                                    value={this.state.selectedInstances}
                                                    onChange={this.onSelectChange}
                                                    startDate={rentPeriodStartDate ? rentPeriodStartDate.getTime() : 0}
                                                    endDate={rentPeriodEndDate ? rentPeriodEndDate.getTime() : 0}
                                                    canUpdate={!this.state.saving}
                                                    includeIds={includeInstanceIds}
                                                    copyMode={this.props.mode === 'copy'}
                                                    requiredTimeIndentBetweenElementsInMinutes={requiredTimeIndentBetweenElementsInMinutes}
                                                />
                                            </div>
                                            <div>
                                                {this.props.isSubrentOperation &&
                                                    canAddNewProductsToOperation2(this.props.operationForm) && (
                                                        <Button
                                                            style={{
                                                                backgroundColor: '#21bfa4',
                                                                borderRadius: 4,
                                                                marginLeft: 10,
                                                                padding: '0 16px',
                                                            }}
                                                            onClick={this.onAddInstanceButtonClick}
                                                        >
                                                            <Icon style={{ color: '#ffffff' }} component={IconPlus} />
                                                        </Button>
                                                    )}
                                            </div>
                                        </div>
                                        {this.state.instanceAddModalVisible ? (
                                            <InstanceQuickCreateModal
                                                productBusinessVersion={productBusinessVersion}
                                                productId={productId}
                                                variantId={variantId}
                                                supplierId={this.props.renterId}
                                                onClose={() => {
                                                    this.setState({ instanceAddModalVisible: false });
                                                }}
                                                onInstanceCreated={async (instance: InstanceInfoRead) => {
                                                    await this.select0.loadData();
                                                    this.setState({
                                                        selectedInstances: [
                                                            ...(this.state.selectedInstances ? this.state.selectedInstances : []),
                                                            instance.id,
                                                        ],
                                                        anonymousInstanceCount:
                                                            this.state.anonymousInstanceCount && this.state.anonymousInstanceCount > 0
                                                                ? this.state.anonymousInstanceCount - 1
                                                                : this.state.anonymousInstanceCount,
                                                    });
                                                }}
                                                title={'Быстрое создание экземпляра'}
                                                intl={this.props.intl}
                                            />
                                        ) : null}
                                    </div>
                                </Col>
                            ) : null}

                            {!this.props.projectTemplate && (
                                <Col className="rr-form-item">
                                    <div className="rr-label" style={{ marginBottom: 10 }}>
                                        {localize(LocalizationEnum.ASPECT__RENT_BUSINESS__RENT_PERIOD)}
                                    </div>
                                    <div className="rr-value" style={{ display: 'flex', alignItems: 'center' }}>
                                        {/*<CustomRangePicker*/}
                                        {/*    //startDateLabel={isRentOperation(this.props.operationTypeCode) && 'Сейчас'}*/}
                                        {/*    //endDateLabel={isReturnOperation(this.props.operationTypeCode) && 'Сейчас'}*/}
                                        {/*    allowClear={false}*/}
                                        {/*    intervals={intervals}*/}
                                        {/*    showTime*/}
                                        {/*    disabled={disableAllElements}*/}
                                        {/*    className={classNames(*/}
                                        {/*        rentPeriodChanged*/}
                                        {/*            ? 'rr-operation-element-create-popover-param-changed'*/}
                                        {/*            : 'rr-operation-element-create-popover-param-not-changed',*/}
                                        {/*        dateIntervalsProblem ? 'rr-operation-element-create-popover-param-error' : undefined*/}
                                        {/*    )}*/}
                                        {/*    value={[moment(rentPeriodStartDate), moment(rentPeriodEndDate)]}*/}
                                        {/*    calendarValue={calendarValue}*/}
                                        {/*    onChange={this.onRentPeriodChanged}*/}
                                        {/*    onOpenChange={this.props.onCalendarVisibleChangeCB}*/}
                                        {/*    shiftLengthInMin={shiftLengthInMin}*/}
                                        {/*    hasOwnShiftLength={hasOwnShiftLength}*/}
                                        {/*    requiredTimeIndentBetweenElementsInMinutes={requiredTimeIndentBetweenElementsInMinutes}*/}
                                        {/*    productHasOwnRequiredTimeIndentBetweenElements={productHasOwnRequiredTimeIndentBetweenElements}*/}
                                        {/*    shiftCount={this.state.shiftCount}*/}
                                        {/*    align={{ points: ['bl', 't'], offset: [0, -6] }}*/}
                                        {/*/>*/}
                                        <RangePicker
                                            style={{width: 408}}
                                            //startDateLabel={isRentOperation(this.props.operationTypeCode) && 'Сейчас'}
                                            //endDateLabel={isReturnOperation(this.props.operationTypeCode) && 'Сейчас'}
                                            allowClear={false}
                                            intervals={intervals}
                                            showTime
                                            disabled={disableAllElements}
                                            className={classNames(
                                                rentPeriodChanged
                                                    ? 'rr-operation-element-create-popover-param-changed'
                                                    : 'rr-operation-element-create-popover-param-not-changed',
                                                dateIntervalsProblem ? 'rr-operation-element-create-popover-param-error' : undefined
                                            )}
                                            value={[moment(rentPeriodStartDate), moment(rentPeriodEndDate)]}
                                            //calendarValue={calendarValue}
                                            onChange={this.onRentPeriodChanged as any}
                                            //onOpenChange={this.props.onCalendarVisibleChangeCB}
                                            //shiftLengthInMin={shiftLengthInMin}
                                            //hasOwnShiftLength={hasOwnShiftLength}
                                            //requiredTimeIndentBetweenElementsInMinutes={requiredTimeIndentBetweenElementsInMinutes}
                                            //productHasOwnRequiredTimeIndentBetweenElements={productHasOwnRequiredTimeIndentBetweenElements}
                                            shiftCount={this.state.shiftCount}
                                            requiredTimeIndentProps={{
                                                minutes: requiredTimeIndentBetweenElementsInMinutes,
                                                hasOwnIndent: productHasOwnRequiredTimeIndentBetweenElements
                                            }}
                                            recalculateShiftCountBlockProps={{
                                                shiftLengthInMin: shiftLengthInMin,
                                                hasOwnShiftLength: hasOwnShiftLength,
                                                shiftLengthHint: undefined,
                                                fromShiftCount: this.state.shiftCount
                                            }}
                                            getTodayDate={()=>{
                                                return moment(this.props.operationStartTime)
                                            }}
                                        />
                                        <OperationParamResetButton
                                            changed={rentPeriodChanged}
                                            onChange={() => {
                                                this.resetPeriod(null);
                                            }}
                                        />
                                    </div>
                                </Col>
                            )}

                            {(
                                <Col span={12} className="rr-form-item" style={{ marginBottom: 0 }}>
                                    <div className="rr-label" style={{ marginBottom: 10 }}>
                                        {localize(LocalizationEnum.PAGE__OPERATIONS__CARD__SHIFT_COUNT)}
                                    </div>
                                    <div className={'rr-value'} style={{ display: 'flex', alignItems: 'center' }}>
                                        <NumberInputShiftCount
                                            className={
                                                (shiftCountChanged
                                                    ? ' rr-operation-element-create-popover-param-changed'
                                                    : ' rr-operation-element-create-popover-param-not-changed') +
                                                (shiftCountWarning && !this.props.projectTemplate
                                                    ? ' rr-operation-element-create-popover-param-warning'
                                                    : '')
                                            }
                                            value={shiftCount}
                                            onChange={this.onShiftCountChanged}
                                            disabled={disableAllElements}
                                        />
                                        {!disableAllElements && (
                                            <RecalculateShiftsCountButton
                                                shiftLengthInMinutes={shiftLengthInMin}
                                                hasOwnShiftLength={hasOwnShiftLength}
                                                style={{ marginLeft: 10 }}
                                                from={shiftCount}
                                                to={newShiftCount}
                                                onClick={() => {
                                                    this.setState({ shiftCount: newShiftCount, shiftCountChanged: true });
                                                }}
                                            />
                                        )}
                                        <OperationParamResetButton
                                            changed={shiftCountChanged}
                                            onChange={() => {
                                                let shiftCount = this.props.defaultShiftCount || 0;
                                                if (
                                                    this.props.defaultShiftCount === undefined ||
                                                    this.props.autoRecalculateShiftsCountMode
                                                ) {
                                                    shiftCount = newShiftCount;
                                                }

                                                this.setState({
                                                    shiftCountChanged: false,
                                                    shiftCount: shiftCount,
                                                });
                                            }}
                                        />
                                    </div>
                                </Col>
                            )}

                            {canViewFinancialData && (
                                <Col span={12} className="rr-form-item" style={{ marginBottom: 0 }}>
                                    <div className="rr-label" style={{ marginBottom: 10 }}>
                                        {localize(LocalizationEnum.ASPECT__FIELDS__COMMON__DISCOUNT)}
                                    </div>
                                    <div className={'rr-value'} style={{ display: 'flex', alignItems: 'center' }}>
                                        <NumberInputDiscount
                                            className={
                                                discountChanged
                                                    ? ' rr-operation-element-create-popover-param-changed'
                                                    : ' rr-operation-element-create-popover-param-not-changed'
                                            }
                                            value={discount}
                                            onChange={this.onDiscountChanged}
                                            disabled={disableAllElements}
                                        />
                                        <OperationParamResetButton
                                            changed={discountChanged}
                                            onChange={() => {
                                                this.setState({
                                                    discountChanged: false,
                                                    discount: this.props.defaultDiscount,
                                                });
                                            }}
                                        />
                                    </div>
                                </Col>
                            )}
                        </Row>

                        {canViewFinancialData && (
                            <Row type={'flex'} style={{ borderTop: '1px solid #e4e5ec', backgroundColor: '#525A95' }}>
                                <Col
                                    span={8}
                                    className="rr-form-item"
                                    style={{
                                        borderRight: 'none',
                                        padding: '12px 32px',
                                        marginBottom: 0,
                                        backgroundColor: '#FFF',
                                    }}
                                >
                                    <div className="rr-label">Цена экз./см.</div>
                                    <div className="rr-value">
                                        {pricingScheme ? (
                                            <PricingSchemePricePopover
                                                data={{
                                                    id: pricingScheme.id,
                                                    typeCode: pricingScheme?.typeCode,
                                                    label: pricingScheme.name || '',
                                                    price: pricePerShiftOriginal,
                                                    steps: pricingScheme?.steps || [],
                                                    shiftCount,
                                                }}
                                            >
                                                <Icon
                                                    style={{
                                                        fontSize: 22,
                                                        color: '#3d64d4',
                                                        marginRight: 4,
                                                        marginTop: -5,
                                                        verticalAlign: 'middle',
                                                    }}
                                                    component={PricingSchemeMaps.pricingSchemeTypeCodeToIconMap[pricingScheme.typeCode]}
                                                />
                                            </PricingSchemePricePopover>
                                        ) : null}
                                        <InlineMoneyInput min={0} //parser={inputNumberParser}
                                            canEdit={!disableAllElements}
                                            value={pricePerShift}
                                            onChange={(v) => {
                                                if (pricePerShift !== v)
                                                    this.setState({
                                                        pricePerShift: v,
                                                        priceChanged: true,
                                                    });
                                            }}
                                        />
                                    </div>
                                </Col>
                                <Col
                                    span={8}
                                    className="rr-form-item"
                                    style={{
                                        borderLeft: '1px solid #e4e5ec',
                                        padding: '12px 32px',
                                        marginBottom: 0,
                                        marginLeft: -16 - 1,
                                        backgroundColor: '#FFF',
                                    }}
                                >
                                    <div className="rr-label">Стоимость экз./см.</div>
                                    <div className={'rr-value'}>
                                        <InlineMoneyInput min={0} //parser={inputNumberParser}
                                            canEdit={!disableAllElements && pricePerShift !== 0}
                                            value={shiftPrice}
                                            onChange={(v) => {
                                                let discount = 100 - (v * 100) / pricePerShift;
                                                if (shiftPrice !== v)
                                                    this.setState({
                                                        discount: discount,
                                                        discountChanged: true,
                                                    });
                                            }}
                                        />
                                    </div>
                                </Col>
                                <Col
                                    span={8}
                                    className="rr-form-item"
                                    style={{
                                        borderLeft: '1px solid #525A95',
                                        padding: '12px 0px 12px 32px',
                                        backgroundColor: '#525A95',
                                        marginBottom: 0,
                                        marginLeft: -16 - 1,
                                    }}
                                >
                                    <div className="rr-label" style={{ color: '#FFFFFF' }}>
                                        {localize(LocalizationEnum.ASPECT__GRID__COLUMN__FINAL_TOTAL_PRICE)}
                                    </div>
                                    <div className={'rr-value'} style={{ color: '#FFFFFF' }}>
                                        <InlineMoneyInput min={0} //parser={inputNumberParser}
                                            canEdit={!disableAllElements && pricePerShift !== 0 && instanceCount > 0 && shiftCount > 0}
                                            darkBg={true}
                                            value={finalTotalPrice}
                                            onChange={(v) => {
                                                let discount =
                                                    v !== 0 && priceWithoutDiscount !== 0
                                                        ? ((priceWithoutDiscount - v) * 100) / priceWithoutDiscount
                                                        : 0;
                                                if (finalTotalPrice !== v)
                                                    this.setState({
                                                        discount: discount,
                                                        discountChanged: true,
                                                    });
                                            }}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        )}
                    </>
                }
            />
        );
    }
}

const mapStateToProps = (storeState: IRootState) => ({
    businessAccountId: storeState.system.businessAccountId,
    defaultShiftCount: storeState.operationForm.shiftCount,
    defaultDiscount: storeState.operationForm.discount,
    defaultRentPeriodEndDate: storeState.operationForm.rentPeriodEndDate,
    defaultRentPeriodStartDate: storeState.operationForm.rentPeriodStartDate,
    startDateFromList: storeState.operationForm.products.params.startDate,
    endDateFromList: storeState.operationForm.products.params.endDate,
    operationTypeCode: storeState.operationForm.typeCode,
    operationTypeCorrectionCode: storeState.operationForm.targetStateCode,
    elements: storeState.operationForm.elements.entities,
    //instanceStackMapList: storeState.operationForm.instanceStackMapList,
    projectTemplate: storeState.operationForm.projectTemplate,
    timeTables: storeState.operationForm.timeTables,
    recalculateShiftCount: storeState.userSettings.operationFormRecalculateShiftCount,
    shiftCountRoundingType: storeState.businessAccountPreferences.preferences?.shiftCountRoundingType,
    requiredTimeIndentBetweenElementsInMinutes:
        storeState.businessAccountPreferences.preferences?.requiredTimeIndentBetweenElementsInMinutes || 0,
    isSubrentOperation: storeState.operationForm.isSubrent,//isSubrentOperation(storeState.operationForm.typeCode, storeState.operationForm.targetStateCode),
    renterId: storeState.operationForm.renterId,
    operationStartTime: storeState.operationForm.operationStartTime,
    autoRecalculateShiftsCountMode: storeState.operationForm.autoRecalculateShiftsCountMode,
    canViewFinancialData: canViewFinancialData(storeState.permissions.permissions),
    operationForm: storeState.operationForm
});

const mapDispatchToProps = { addElement, editElement };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export const OperationElementEditPopoverContent = connect(mapStateToProps, mapDispatchToProps)(injectIntl(Component));
