import { FAILURE, REQUEST, SUCCESS } from '../../../../../shared/reducers/action-type.util';
import {
    CustomFieldBulkLinkObj,
    CustomFieldInfoUpdate,
    CustomFieldRecord,
    CustomFieldRecordList,
    EntityTypeCodeEnum,
    serverApi,
    ServerError,
} from '../../../../../server';
import { IRootState } from '../../../../../shared/reducers';
import { getServerError, getStateFromPath2 } from '../../../../../shared/util/utils';
import { LOCATION_CHANGE } from 'connected-react-router';
import { getAvailableGridSortById } from '../../../../../shared/util/getAvailableGridSortById';
import { columns } from '../customFieldsModuleListColums';
import { gridDataChangedSignal } from '../../../../../components/controllers/scroll/SelectScrollController/SelectScrollController';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { showNotification } from '../../../../../components/notification/showNotification';
import { businessAccountIdSelector } from '../../../../../shared/reducers/system.reducer';
import { StringUtils } from '../../../../../core/utils/stringUtils';

const ACTION_TYPES = {
    LOAD_ENTITIES: 'customFields/LOAD_ENTITIES',
    RESET: 'customFields/RESET',
    HIDE_SHOW: 'customFields/HIDE_SHOW',
    CHANGE_BINDINGS: 'customFields/CHANGE_BINDINGS',
};

export const initialParamsState = {
    search: undefined as string | undefined,
    sortBy: undefined as string | undefined,
    sortOrder: undefined as 'ASC' | 'DESC' | undefined,
    typeCode: undefined as string | undefined,
    processingType: undefined as
        | 'USER_LINK'
        | 'CONTACT_LINK'
        | 'SUBRENT_LINK'
        | 'PROJECT_LINK'
        | 'RENTER_LINK'
        | 'LONG_SUM'
        | 'MONEY_SUM'
        | undefined,
    page: 1,
    limit: 10,
    hideStandardFields: undefined as boolean | undefined,
    mode: undefined as string | undefined,
    id: undefined as number | undefined,
};

const initialState = {
    loading: false,
    loadingError: undefined as undefined | ServerError,
    entities: null as Array<CustomFieldRecord> | null,
    filteredCount: 0,
    params: {
        ...initialParamsState,
    },
};

export type CustomFieldsState = Readonly<typeof initialState>;

// Reducer

export default (state: CustomFieldsState = initialState, action): CustomFieldsState => {
    switch (action.type) {
        case LOCATION_CHANGE:
            let pathName =
                action.payload && action.payload.location && action.payload.location.pathname ? action.payload.location.pathname : '';
            let params = getStateFromPath2(action.payload.location.search);

            const regexp = /\/(\d+)\/settings\/customFields/;

            if (regexp.test(pathName)) {
                return {
                    ...state,
                    params: {
                        ...state.params,
                        search: params.search !== undefined ? '' + params.search : initialParamsState.search,
                        sortBy: params.sortBy !== undefined ? params.sortBy : initialParamsState.sortBy,
                        sortOrder: params.sortOrder !== undefined ? params.sortOrder : initialParamsState.sortOrder,
                        typeCode: params.typeCode !== undefined ? params.typeCode : initialParamsState.typeCode,
                        processingType: params.processingType !== undefined ? params.processingType : initialParamsState.processingType,
                        page: params.page !== undefined ? +params.page : initialParamsState.page,
                        limit: params.limit !== undefined ? +params.limit : initialParamsState.limit,
                        hideStandardFields:
                            params.hideStandardFields !== undefined ? params.hideStandardFields : initialParamsState.hideStandardFields,
                        mode: params.mode !== undefined ? params.mode : initialParamsState.mode,
                        id: params.id !== undefined ? +params.id : initialParamsState.id,
                    },
                };
            }
            return state;

        case REQUEST(ACTION_TYPES.LOAD_ENTITIES):
            return {
                ...state,
                loadingError: undefined,
                loading: true,
            };
        case FAILURE(ACTION_TYPES.LOAD_ENTITIES):
            console.dir(action.payload);
            return {
                ...state,
                loadingError: getServerError(action.payload),
                loading: false,
            };
        case SUCCESS(ACTION_TYPES.LOAD_ENTITIES):
            gridDataChangedSignal();

            return {
                ...state,
                loading: false,
                entities: (action.payload.data as CustomFieldRecordList).records,
                filteredCount: (action.payload.data as CustomFieldRecordList).listAttributes.filteredCount,
            };

        case REQUEST(ACTION_TYPES.HIDE_SHOW):
            return { ...state, loading: true };
        case FAILURE(ACTION_TYPES.HIDE_SHOW):
            return { ...state, loading: false };
        case SUCCESS(ACTION_TYPES.HIDE_SHOW):
            return { ...state, loading: false };

        case ACTION_TYPES.RESET:
            return {
                ...initialState,
            };
        default:
            return state;
    }
};

// Actions

export const loadEntities = (businessAccountId: number) => {
    return (dispatch, getState) => {
        let state: CustomFieldsState = (getState() as IRootState).customFields;
        let params = state.params;
        const sortBy = getAvailableGridSortById(params.sortBy, columns);

        return dispatch({
            type: ACTION_TYPES.LOAD_ENTITIES,
            payload: serverApi.listCustomFields(
                businessAccountId,
                params.search,
                params.limit,
                (params.page - 1) * params.limit,
                sortBy,
                params.sortOrder,
                params.typeCode as 'BOOLEAN' | 'LONG' | 'DOUBLE' | 'STRING' | 'DATE',
                params.hideStandardFields ? false : undefined,
                params.processingType
            ),
        });
    };
};

export const hideShow = (businessAccountId: number, id: number, customFieldInfoUpdate: CustomFieldInfoUpdate) => {
    return (dispatch, getState) => {
        return dispatch({
            type: ACTION_TYPES.HIDE_SHOW,
            payload: serverApi.updateCustomFieldById(businessAccountId, id, customFieldInfoUpdate),
        });
    };
};

export const reset = () => ({
    type: ACTION_TYPES.RESET,
});

export const changeCustomFieldsBindings = createAsyncThunk(
    ACTION_TYPES.CHANGE_BINDINGS,
    async (
        args: {
            createLinks: boolean;
            customFieldBulkLinkObj: Omit<CustomFieldBulkLinkObj, 'entityTypeCode'> & {
                entityTypeCode: EntityTypeCodeEnum.PRODUCT | EntityTypeCodeEnum.KIT;
            };
            successCallback: () => void;
        },
        { getState }
    ) => {
        const { createLinks, customFieldBulkLinkObj, successCallback } = args;
        const state = getState() as IRootState;
        const businessAccountId = businessAccountIdSelector(state);

        const notificationErrorMessage = `Не удалось ${createLinks ? 'назначить' : 'отвязать'} настраиваемые поля`;

        const { entityTypeCode } = customFieldBulkLinkObj;

        try {
            const successfulBindingCount = (
                await serverApi.linkCustomFieldsBulk(businessAccountId, createLinks, false, customFieldBulkLinkObj)
            ).data;

            const firstCaseWords = {
                [EntityTypeCodeEnum.PRODUCT]: ['продукт', 'продукта', 'продуктов'],
                [EntityTypeCodeEnum.KIT]: ['набор', 'набора', 'наборов'],
            };

            if (successfulBindingCount >= 0) {
                const correctForm = StringUtils.getWordFormFromCount(
                    successfulBindingCount,
                    createLinks,
                    entityTypeCode === EntityTypeCodeEnum.PRODUCT ? ['продукт', 'продукта', 'продуктов'] : ['набор', 'набора', 'наборов'],
                    ['продукта', 'продуктов', 'продуктов']
                );
                const sentenceStart = createLinks ? 'Настраиваемые поля назначены на' : 'Настраиваемые поля отвязаны от';

                successCallback?.();
                showNotification('success', `${sentenceStart} ${successfulBindingCount} ${correctForm}`);
            } else {
                throw new Error(notificationErrorMessage);
            }
        } catch (error: unknown) {
            showNotification('error', notificationErrorMessage);
            const errorMessage = error instanceof Error ? error.message : undefined;
            throw new Error(errorMessage);
        }
    }
);
