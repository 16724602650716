import React, {FC, memo, useEffect} from 'react';
import {EntityList} from '../../../../../../components/page/entityGrid/components/ui/EntityList/EntityList';
import {nomenclaturesListColumns} from './nomenclaturesListColumns';
import {useAppSelector} from '../../../../../../store/hooks';
import {businessAccountIdSelector} from '../../../../../../shared/reducers/system.reducer';
import {EntityGridRootPath, ListParams} from '../../../../../../components/page/entityGrid/types/params';
import {
    usePageURLParamsFromDescription,
    useQueryParamsWithoutModalParams,
} from '../../../../../../components/page/entityGrid/hooks/useParams';
import {productsFiltersDescription} from './productsFiltersDescription';
import {withEntityListProviders} from '../../../../../../components/page/entityGrid/components/decorators/withEntityListProviders';
import {useExcludedValues} from "./hooks/useExcludedValues";
import {useCategoriesProps} from './hooks/useCategoriesProps';
import {useLocation} from 'react-router-dom';
import {useCustomFieldsData} from '../../../../../../components/page/entityGrid/hooks/useCustomFieldsData';
import {useListNomenclaturesAvailableFiltersValuesQuery, useListNomenclaturesOnIntervalQuery} from "../../../api/operationForm.api";
import {nomenclaturesListFilters} from "./nomenclaturesListFilters";
import moment from "moment";
import {useNomenclaturesRecords} from "./hooks/useNomenclaturesRecords";
import {InstancePropertiesEntityRecordCustom} from "../../../reducers/operationForm.reducer";

interface ProductsListProps {
    rootPath?: EntityGridRootPath;
}

const initialValues: Partial<ListParams> = {
    sortBy: 'lastActivityDate',
    sortOrder: 'DESC',
};

const getUniqId = (data: InstancePropertiesEntityRecordCustom): string => {
    let id = '';
    if (data.productId) id += data.productId + '_';
    if (data.kitId) id += data.kitId + '_';
    if (data.variantId) id += data.variantId + '_';
    return id;
};

export const OperationFormNomenclaturesGridName = 'operation-nomenclatures';

const NomenclaturesListComponent: FC<ProductsListProps> = memo((props) => {
    const gridName = OperationFormNomenclaturesGridName;
    const location = useLocation();
    const businessAccountId = useAppSelector(businessAccountIdSelector);
    const entitiesLoading = useAppSelector((store) => store.products.loading);

    const rootPath = props.rootPath || `/${businessAccountId}/operation` satisfies EntityGridRootPath;

    const { data: { customFieldMarkers, availableFiltersValues } = {}, isLoading: isAvailableFilterLoading } = useListNomenclaturesAvailableFiltersValuesQuery(
        {
            search: location.search,
        }
    );

    const rentPeriodStartDate = useAppSelector(store => moment(store.operationForm.rentPeriodStartDate).startOf('month').subtract(1, 'month').valueOf());
    const rentPeriodEndDate = useAppSelector(store => moment(store.operationForm.rentPeriodEndDate).endOf('month').add(1, 'month').valueOf());

    const { customFiltersRequestFilters, actualPageParamsDescription } = useCustomFieldsData(
        customFieldMarkers,
        productsFiltersDescription
    );

    const pageParamsObject = usePageURLParamsFromDescription({
        pageParamsDescription: actualPageParamsDescription,
        gridName,
        initialValues,
        storageSuppressedKeys: ['tab']
    });
    const { pageParams } = pageParamsObject;

    const queryParams = useQueryParamsWithoutModalParams(pageParams);

    const queryData = useNomenclaturesRecords(useListNomenclaturesOnIntervalQuery(
        {
            params: {
                ...queryParams,
                from: rentPeriodStartDate,
                until: rentPeriodEndDate
            },
            customFiltersRequestFilters,
            availableFiltersValues,
            customFieldMarkers,
        },
        {
            skip: isAvailableFilterLoading,
            refetchOnReconnect: true,
            refetchOnMountOrArgChange: true,
        }
    ));

    const { excludeColumns, filtersExcludeFields } = useExcludedValues(pageParams);
    const categoriesProps = useCategoriesProps(pageParamsObject as any);

    useEffect(() => {

        const fn = ()=>{
            queryData.refetch();
        }

        window.addEventListener('refetchOperationFormProductsList', fn, true);
        return () => {
            window.removeEventListener('refetchOperationFormProductsList', fn, true);
        };
    }, [queryData.refetch]);

    return (
        <EntityList
            pageParamsObject={pageParamsObject}
            gridProps={{
                static: {
                    columns: nomenclaturesListColumns,
                    filtersData: nomenclaturesListFilters,
                    pageParamsDescription: actualPageParamsDescription,
                },
                metaData: {
                    rootPath,
                    entityType: 'product',
                    gridName,
                    customFieldsInsertIndex: 5
                },
                categoriesProps,
                queryData,
                entitiesLoading,
                filtersExcludeFields,
                excludeColumns,
                onPageSelect: [5, 10, 20, 50, 100],
                selectable: false,
                getUniqId
            }}
        />
    );
});

export const NomenclaturesList: FC<ProductsListProps> = withEntityListProviders(NomenclaturesListComponent);
