import {IconExclamationCircleSolid} from "../../../../../../components/icons";
import {Icon} from "antd";
import React from "react";

interface WarningIconProps {
    onClick: () => void;
}

export const WarningIcon = (props: WarningIconProps) => {

    return (
        <Icon
            onClick={props.onClick}
            component={IconExclamationCircleSolid}
            style={{
                marginRight: 4,
                fontSize: 32,
                color: '#ff9924',
                verticalAlign: 'middle',
            }}
        />
    );
};
