import React, {FC, ReactNode, useState} from 'react';
import {Prompt, useLocation} from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Location } from 'history';
import { Redirect } from 'react-router';
import {showConfirm} from "../../../../../components/confirm/showConfirm";

interface PromptRedirectProps {
    onRedirect?: ()=>void;
    question?: ReactNode;
}

export const PromptRedirect: FC<PromptRedirectProps> = ({onRedirect, question}) => {
    const intl = useIntl();
    const [redirectLocation, setRedirectLocation] = useState<Location<unknown> | undefined>(undefined);
    const location = useLocation();

    if (redirectLocation) {
        const redirectUrl = redirectLocation.pathname + redirectLocation.search;
        return <Redirect to={redirectUrl} push={true} />;
    }

    return (
        <Prompt
            message={(nextLocation) => {
                if(nextLocation.pathname !== location.pathname){
                    showConfirm(intl, question || 'Прервать редактирование?').then((answer) => {
                        if (answer) {
                            setRedirectLocation(nextLocation);
                            onRedirect?.();
                        }
                    });
                    return false;
                }
                return true;
            }}
        />
    );
};
