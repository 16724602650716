import React, {FC} from 'react';
import './selectedIdsFilter.less';
import {Icon} from "antd";
import {IconClose} from "../../../../../components/icons";

interface SelectedIdsFilterProps {
    value?: number[];
    onChange?: (value: number[]|undefined) => void;
}

export const SelectedIdsFilter: FC<SelectedIdsFilterProps> = (props) => {
    return props.value?.length ? (
        <div className={'rr-selectedIdsFilter'}>
            <div>{props.value?.length} Выбрано к правке</div>
            <Icon
                component={IconClose}
                className={'rr-selectedIdsFilter-clear-icon'}
                onClick={()=>{
                    props.onChange?.(undefined);
                }}
            />
        </div>
    ) : null;
};
